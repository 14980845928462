var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',[_c('b-row',{attrs:{"slot":"header","align-v":"center"},slot:"header"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-0"},[_vm._v("Component Items "),(_vm.selectedItemIsLoading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])])],1),_c('el-table',{staticClass:"table-responsive table-flush table-bordered",attrs:{"header-row-class-name":"thead-light","data":_vm.items}},[_c('el-table-column',{attrs:{"label":"IMG","min-width":"240px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":row.relationships.item['image_uri'] || '/img/default-image.png',"height":"40","alt":""}}),_vm._v(" "+_vm._s(row.relationships.item.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"SMALL","min-width":"140px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" SMALL ("+_vm._s(_vm.getSize(row.relationships.sizes, 'small').toFixed(2))+") ")]}}])}),_c('el-table-column',{attrs:{"label":"MEDIUM","min-width":"140px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" MEDIUM ("+_vm._s(_vm.getSize(row.relationships.sizes, 'medium').toFixed(2))+") ")]}}])}),_c('el-table-column',{attrs:{"label":"LARGE","min-width":"140px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" LARGE ("+_vm._s(_vm.getSize(row.relationships.sizes, 'large').toFixed(2))+") ")]}}])}),_c('el-table-column',{attrs:{"label":"ACTION","min-width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-button-group',[_c('base-button',{attrs:{"icon":"","type":"primary","size":"sm","disabled":row.relationships.item.enabled === 1 ? true : false}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-edit"})])]),_c('base-button',{staticStyle:{"position":"relative"},attrs:{"icon":"","type":"success","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.removeComboComponent($event, row.id)}}},[_c('i',{staticClass:"fas fa-eye"})])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }