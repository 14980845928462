


































import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option } from "element-ui";
import { notificationAlerts, translations } from "@/mixins";
import StoreTimes from "../components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CriteriaFields from "./CriteriaFields";
import Promotions from "../Promotions.vue";
import { Promotion } from "@/services/SOLO";

@Component({
  components: {
    CriteriaFields
  },
  mixins: [notificationAlerts, translations]
})
@Component({
  components: {
    CriteriaFields
  },
  mixins: [notificationAlerts, translations]
})
export default class SegmentsUpdate extends Vue {
  isLoading: boolean = false;
  isDeleteLoading: boolean = false;
  segment: any = {
    name: null,
    criteria: []
  };

  created() {
    const id = this.$route.params.id;

    Promotion.getSegment(id).then(res => {
      const criteria = res.data.data.criteria.map((criterion: any) => {
        console.log('criterion: ', criterion);
        return {
          id: uuidv4(),
          provider: `\\${criterion.handler}`,
          condition: criterion.choice,
          value: [...criterion.value],
        };
      });
      console.log('criteria: ', criteria);
      this.segment = {
        name: res.data.data.name,
        condition: res.data.data.condition,
        criteria: criteria,
      };
    });
  }
  submitForm(e: any) {
    e.preventDefault();
    const criteria = this.segment.criteria.map((criterion: any) => {
      return {
        provider: criterion.provider,
        condition: criterion.condition,
        value: criterion.value,
      };
    });
    const body = { ...this.segment };
    body.criteria = criteria;
    this.isLoading = true;
    // axios
    //   .put(this.action, body)
    //   .then(res => {
    //     window.location = "/segments";
    //   })
    //   .catch(e => {
    //     this.isLoading = false;
    //     swal({
    //       type: "error",
    //       title: "Error saving segment",
    //       text: "Unable to save segment",
    //       toast: true,
    //       position: "top",
    //       showConfirmButton: false,
    //       timer: 5000
    //     });
    //   });
  }
  deleteSegment() {
    let proceed = confirm("Are you sure you want to delete this segment?");
    if (proceed) {
      this.isDeleteLoading = true;

      // axios
      //   .delete(this.action)
      //   .then(res => {
      //     window.location = "/segments";
      //   })
      //   .catch(e => {
      //     this.isDeleteLoading = false;
      //     swal({
      //       type: "error",
      //       title: "Error saving segment",
      //       text: "Unable to save segment",
      //       toast: true,
      //       position: "top",
      //       showConfirmButton: false,
      //       timer: 5000
      //     });
      //   });
    }
  }
}
