














































import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Applications} from '@/services/SOLO'
import {translations} from '@/mixins'

@Component({
  mixins: [translations]
})
export default class AppItems extends Vue {
  applications: Array<Object> = []
  translate!: Function
  mounted() {
    this.getAppItems()
  }

  getAppItems() {
    Applications.getAllApplications()
        .then((response: any) => {
          this.applications = response.data.data
        })
  }
}
