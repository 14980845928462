var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt--5"},[_c('translations',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]}),_c('base-header',{staticClass:"pb-6"},[_c('dashboard-figures',{attrs:{"title":_vm.translate('Sales Summary'),"revpct":_vm.todaysRevenuePCT,"avgpct":_vm.todaysAveragePCT,"orderspct":_vm.todaysOrderPCT,"sub-title":_vm.translate('today'),"revenue":_vm.todaysRevenue,"average":_vm.todaysAverage,"orders":_vm.todaysOrder,"disable-icon":false}}),_c('dashboard-figures',{attrs:{"title":_vm.translate('Sales Summary'),"revpct":_vm.yesterdaysRevenuePCT,"avgpct":_vm.yesterdaysAveragePCT,"orderspct":_vm.yesterdaysOrderPCT,"sub-title":_vm.translate('yesterday'),"revenue":_vm.yesterdaysRevenue,"average":_vm.yesterdaysAverage,"orders":_vm.yesterdaysOrder,"disable-icon":false}}),_c('dashboard-figures',{attrs:{"title":_vm.translate('Sales Summary'),"revpct":0,"avgpct":0,"orderspct":0,"sub-title":_vm.translate('previous day'),"revenue":_vm.otherDayRevenue,"average":_vm.otherDayAverage,"orders":_vm.otherDaysOrder,"disable-icon":true}})],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[_c('card',{attrs:{"header-classes":"bg-transparent"}},[_c('h5',{staticClass:"h3 mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.translate('Latest Orders')))]),_c('el-table',{staticClass:"table-responsive table-flush table-bordered",attrs:{"header-row-class-name":"thead-light","data":_vm.orders}},[_c('el-table-column',{attrs:{"label":"#","min-width":"90px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"text-default",attrs:{"to":{name: 'order-details', params: { id: row.id }}}},[_vm._v(" "+_vm._s(row.attributes.code ? row.attributes.code : row.id)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Source'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"height":"35","src":_vm.iconSrc(row.attributes.source),"alt":""}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Type'),"min-width":"100px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"height":"35","src":_vm.iconSrc(row.attributes.type),"alt":""}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Payment'),"min-width":"110px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"height":"35","src":_vm.iconSrc(row.attributes['payment-method']),"alt":""}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Ordered'),"min-width":"140px","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm._f("datetime")(row.attributes['created-at']))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.translate('Status'),"prop":"attributes.current-status.description","min-width":"140px","sortable":""}}),_c('el-table-column',{attrs:{"label":_vm.translate('Amount'),"prop":"attributes.total","min-width":"140px","sortable":""}})],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"xl":"12"}},[_c('card',{attrs:{"header-classes":"bg-transparent"}},[_c('h5',{staticClass:"h3 mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v(_vm._s(_vm.translate('Online Sales')))]),(_vm.loadChart)?_c('line-chart',{attrs:{"height":350,"chart-data":_vm.bigLineChart.chartData,"extra-options":_vm.bigLineChart.extraOptions}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }