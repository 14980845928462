
























import LoyaltyCollapse from "./components/LoyaltyCollapse.vue"
import LoyaltyList from "./components/LoyaltyList.vue"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {
    RouteBreadCrumb,
    LoyaltyList,
    LoyaltyCollapse
  }
})

export default class Feedback extends Vue {}
