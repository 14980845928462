















import moment from 'moment'
import { Customers as CustomerService } from "@/models"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue'
import {Component, Vue} from "vue-property-decorator"
import CustomerCollapse from "./CustomerCollapse.vue"
import CustomerList from "./CustomerList.vue"
import { Customer } from '@/services/SOLO'

@Component({
  components: {
    RouteBreadCrumb,
    CustomerList,
    CustomerCollapse
  }
})
export default class CustomerItem extends Vue {
  customers: Array<CustomerService> = []
  customerMeta: any = {}
  targetKeyword: string = ''
  isLoaded: boolean = false
  page: number = 1
  loading: boolean = false

  mounted() {
    this.getCustomers(this.page);
  }

  async getCustomers(page: number) {
    this.loading = true
    await Customer.all(page)
      .then((response: any) => {
        this.customers = response.data.data
        this.customerMeta = response.data.meta.pagination
        console.log(response.data.data);
        this.loading = false
      })
  }

  async searchCustomers(name: string, email: string, mobile: string) {
    this.loading = true
    await Customer.search(name, email, mobile).then((response: any) => {
      this.customers = response.data.data;
      console.log(this.customers); 
      this.loading = false
    });

  }





}
