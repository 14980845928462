import BaseService from '../base.service'
export default class PromotionService extends BaseService {

  constructor() {
    super();
  }

  fetchPromotions() {
    const url = `${this.baseURL}promotions`;
    return super.get(url);
  }

  savePromotion(payload: any) {
    const url = `${this.baseURL}promotions`;

    return super.post(url, payload);
  }

  getPromotion(id: string) {
    const url = `${this.baseURL}promotions/${id}`;

    return super.get(url);
  }

  fetchSegments() {
    const url = `${this.baseURL}segments`;
    return super.get(url);
  }
  fetchSegmentsCriterias() {
    const url = `${this.baseURL}segments/criteria`;
    return super.get(url);
  }
  saveSegment(payload: any) {
    let url = this.baseURL + 'segments';

    return super.post(url, payload);
  }
  updateSegment(segmentId: string, params: any) {

    let url = this.baseURL + 'segments/' + segmentId;

    return super.patch(url, params);
  }
  getSegment(segmentId: string) {
    let url = this.baseURL + `segments/${segmentId}.json`;

    return super.get(url)
  }
  
}
