import BaseService from '../base.service'

let authService = class AuthService extends BaseService {

  constructor() {
    super();
    
  }

  getHostname() {    
    if(process.env.NODE_ENV === 'development') {
      return 'staging.getsolo.io'
    } else {
      return window.location.hostname
    }
  }

  getClient() {  
    let url = this.baseURL + `clients?filter[url]=${this.getHostname()}`;
    return super.get(url);
  }

  login(params: Object = {}) {
    let url = this.baseURL + 'login';
    return super.post(url, params);
  }

  logout() {
    let url = this.baseURL + 'logout';

    return super.post(url, {});
  }

  facebookLogin(params: {}) {
    let url = this.baseURL + 'auth/facebook';

    return super.post(url, params);
  }

  register(params: Object = {}) {
    let url = this.baseURL + 'register'
    return super.post(url, params);
  }

  getFoodicsToken(code: string) {
    let url = this.baseURL + 'foodics-f5/token'
    return super.post(url, {code});
  }
};

export default authService
