

























import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Settings } from '@/services/SOLO';
import { Ingredient, ModifierGroupItem } from '@/models'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModal extends Vue {
  open: boolean = false
  selectedModifierGroup: string = ''
  $notify: any
  @Prop() modifierGroups!: Array<ModifierGroupItem>
  @Prop() modifierGroupItems!: Array<ModifierGroupItem>

  btnLoading!: Function
  btnRestore!: Function
  translate!: Function
  get selected(): Array<String> {
    return this.modifierGroupItems.map(item => { return item.id })
  }

  get filteredModifierGroups(): Array<ModifierGroupItem> {
    return this.modifierGroups.filter(option => {
      return !this.selected.includes(option.id)
    })
  }
    
  get payload(): Object {
    return { 'modifier-group': this.selectedModifierGroup }
  }

  addModGroup(e: any) {    
    const defaultText = e.target.innerHTML
    this.btnLoading(e, '<i class="fas fa-spinner fa-spin"></i> Saving data...')
    Settings.addItemModifiers(this.payload, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId)
      .then((response: any) => {
        this.$emit('modgroup:added')
        this.selectedModifierGroup = ''
        this.btnRestore(e, defaultText)
        this.$notify({
          title: "DATA SAVED",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Modifier has been added!",
          type: "success",
          icon: "fas fa-check",
        })
      })
      .catch((err: any) => {
        this.btnRestore(e, defaultText)
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}
