import BaseService from '../base.service'
import store from '@/store'

let DashboarService = class dashboarService extends BaseService {
    url!: string
    constructor(){
        super()
    }

    dailySummary() {
        this.url = this.baseURL + 'reports/daily-summary'
        return super.get(this.url)
    }

    dailySales() {
        this.url = this.baseURL + `reports/daily-sales?days=30`
        return super.get(this.url)
    }

    latestOrders() {
        this.url = this.baseURL + `orders?page=1&size=10`
        return super.get(this.url)
    }

}

export default DashboarService
