






































































































































































































































































































































































import * as Ably from 'ably';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Select, Option} from 'element-ui'
import DriversModal from './components/DriversModal.vue'
import LogisticsModal from './components/LogisticsModal.vue'
import OrderStatus from './components/OrderStatus.vue'
import buttonAssignLogistics from './components/buttonAssignLogistics.vue'
import buttonAssignDriver from './components/buttonAssignDriver.vue'
import { Order as OrderApi, Integrations, Order, Location as LocationApi } from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ValidationObserver, configure } from 'vee-validate'
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { Driver, DriverAttributes, Location, Logistics } from '@/models'
import moment from "moment";
import "moment/locale/pt-br";
import { eventHandler } from '@/mixins'
import { mapGetters } from 'vuex';
import sound from '../../../../../public/sound/default_notif.wav'
import { BButton } from 'bootstrap-vue';
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'

interface Filter {
  order: string
  from: string
  to: string
  customer: string
  email: string
  mobile: string
  location: Array<string>
  status: string
  today: Boolean
  'curbside-status': string
}
@Component({
  components: {
    RouteBreadCrumb,
    buttonAssignDriver,
    DriversModal,
    buttonAssignLogistics,
    LogisticsModal,
    OrderStatus,
    [Select.name]: Select,
    [Option.name]: Option,
    flatPicker
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      getUserRole: 'account/getUserRole'
    })
  },
  filters: {
    datetime(value: any, format: string = "YYYY-MM-DD HH:mm") {
      return moment.utc(value).locale("en-us").fromNow();
    },
    circleColor(finishedTime: any, promisedTime: any) {
      let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
      let end = moment.utc(promisedTime);
      let diff = moment.duration(start.diff(end)).asMinutes();
      if (diff > 2) {
        return 'text-danger'
      } else if (diff > 0) {
        return 'text-warning';
      } else {
        return 'text-success';
      }
    },
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY hh:mm");
    }
  },
  mixins: [eventHandler,translations]
})
export default class Curbside extends Vue {
  private activeConcept: any
  private driverOpen: Boolean = false
  private logisticsOpen: Boolean = false
  private page: number = 1;
  private size: number = 20;
  private source: string = "Source"
  private posTooltip: string = ""
  private paymentMethod: string = "Payment"
  private type: string = "Type"
  private orders: Array<Object> = [];
  private driverData: Object = {}
  private logisticData: Object = {}
  private logistics: Array<Logistics> = []
  private statuses: Array<Object> = []
  private storeLocations: Array<Object> = []
  private isLoading: Boolean = false
  private filter: Filter = {
    order: '',
    from: '',
    to: '',
    customer: '',
    email: '',
    mobile: '',
    location: [],
    status: '',
    today: true,
    'curbside-status': 'customer-arrived,employee-acknowledged'
  }
  private curbsideStatus: Array<Object> = [
    {
      value: 'customer-arrived,employee-acknowledged',
      text: 'All'
    },
    {
      value: 'customer-arrived',
      text: 'Customer Arrived'
    },
    {
      value: 'employee-acknowledged',
      text: 'Employee Acknowledged'
    }
  ]
  rows: number = 0
  perPage: number = 50
  currentPage: number = 1

  private getUserRole: any
  private confirm!: Function
  private broadcasted!: Function
  public $notify: any
  private notif!: Function
  public translate!: Function
  $refs!: {
    sound: HTMLAudioElement
  }

  mounted() {
    this.getOrders();
    this.getLogisticPartners()
    this.getOrderStatuses()
    this.pushedNotification()
    this.getStoreLocations()
    console.log(this.getUserRole)
  }

  posColor(code: number, posResponse: any) {
    if(code && posResponse) {
      this.posTooltip = "Order successfully sent to POS"
      return 'success'
    } else if(!code && posResponse) {
      this.posTooltip = "Order failed in POS"
      return 'danger'
    } else {
      return 'secondary'
    }
  }

    circleTooltip(finishedTime: any, promisedTime: any) {
      let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
      let end = moment.utc(promisedTime);
      let diff = moment.duration(start.diff(end)).asMinutes();
      if (diff > 2) {
        return 'Past the promised time'
      } else if (diff > 0) {
        return 'Slightly past the promised time';
      } else {
          if (!finishedTime) {
              return 'Not finished';
          } else {
              return 'Finished on time';
          }
      }
    }

  paginate(page: number) {
    this.page = page
    this.getOrders()
  }

  resetForm(reset: any) {

    this.filter.order = ''
    this.filter.from = ''
    this.filter.to = ''
    this.filter.customer = ''
    this.filter.email = ''
    this.filter.mobile = ''
    this.filter.location = []
    this.filter.status = ''
    this.filter.today = true
    this.filter['curbside-status']
  }

  pushedNotification() {

    this.broadcasted(this.activeConcept.id).subscribe((payload: any) => {
        switch (payload.name) {
          case 'Solo\\Order':
            this.findOrder(payload.data.order.id)
            break;
          case 'Solo\\Order\\Status':
            this.findOrder(payload.data.order.id)
            break;
        }
    });
  }

  DriverModalOpenState(open: Boolean = true) {
    this.driverOpen = open
  }

  LogisticsModalOpenState(open: Boolean = true) {
    this.logisticsOpen = open
  }

  showDriver(data: Object) {
    this.driverData = data
    this.DriverModalOpenState(true)
  }

  showLogistics(data: Object) {
    this.logisticData = data
    this.LogisticsModalOpenState(true)
  }

  getOrders() {
    this.orders = []
    this.isLoading = true
    OrderApi.all(this.page, this.size, this.filter).then((response) => {
      this.isLoading = false
      this.orders = response.data.data;
      this.rows = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.currentPage = response.data.meta.pagination.current_page
      console.log(this.orders)
    });
  }

  getStoreLocations() {
    LocationApi.fetchLocations()
    .then((response: any) => {
      this.storeLocations = response.data.data
    })
  }

  findOrder(orderId: string) {
    // setTimeout(()=> {
    //   this.$refs.sound.play()
    // }, 100)

    OrderApi.find(orderId)
      .then((response: any) => {
        this.udpdateOrders(response.data.data)
      })
  }

  udpdateOrders(data: any) {
    let orderIndex = null
    let orders = this.orders.map((order: any, index: any) => {
      if(order.id === data.id) {
        orderIndex = index
      }
    })

    if(orderIndex) {
      this.orders[orderIndex] = data
    }else {
      this.orders.unshift(data)
    }

  }

  updateOrderIndex(data: any) {
    this.orders[data.index] = data.data
  }

  iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`
  }

  getLogisticPartners() {
    Integrations.logisticsPartners('food-logistics')
      .then(response => {
        this.logistics = response.data.data
      })
  }

  getOrderStatuses() {
    OrderApi.statusList()
      .then((response: any) => {
        this.statuses = response.data.data
        console.log(this.statuses);
      })
  }


  exportOrder(orderId: string) {

        if(true) {
          this.$notify({
            title: "PLEASE WAIT!",
            horizontalAlign: "right",
            message: 'Please wait, the file will be downloaded in few seconds.',
            type: "success",
            icon: "fas fa-spinner fa-spin",
          })
          OrderApi.exportOrder(orderId)
            .then(response => {
              window.open(response.data.data.attributes['csv-uri'], '_blank');
            })
        }

  }

  exportAllOrder() {

        if(true) {
          this.$notify({
            title: "PLEASE WAIT!",
            horizontalAlign: "right",
            message: 'Please wait, the file will be downloaded in few seconds.',
            type: "success",
            icon: "fas fa-spinner fa-spin",
          })
          OrderApi.exportAllOrder()
            .then(response => {
              window.open(response.data.data.attributes['csv-uri'], '_blank');
            })
        }

  }

}
