









































































































































































































































































































































































































































import * as Ably from 'ably';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Select, Button, Option} from 'element-ui'
import DriversModal from './components/DriversModal.vue'
import LogisticsModal from './components/LogisticsModal.vue'
import OrderStatus from './components/OrderStatus.vue'
import buttonAssignLogistics from './components/buttonAssignLogistics.vue'
import buttonAssignDriver from './components/buttonAssignDriver.vue'
import {Order as OrderApi, Integrations, Order, Location as LocationApi} from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {ValidationObserver, configure} from 'vee-validate'
import {Component, Prop, Vue, Watch, Emit, Ref} from "vue-property-decorator";
import {Driver, DriverAttributes, Location, Logistics} from '@/models'
import moment from "moment";
import "moment/locale/pt-br";
import {eventHandler} from '@/mixins'
import {mapGetters} from 'vuex';
import sound from '../../../../../public/sound/default_notif.wav'
import {BButton} from 'bootstrap-vue';
import {translations} from '@/mixins'
import Translations from './components/Translations.vue'

interface Filter {
  order: string,
  from: string,
  to: string,
  customer: string,
  email: string,
  mobile: string,
  location: Array<string>,
  status: string,
  today: Boolean,
  type: string
}

@Component({
  components: {
    RouteBreadCrumb,
    buttonAssignDriver,
    DriversModal,
    buttonAssignLogistics,
    LogisticsModal,
    OrderStatus,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    flatPicker
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept'
    })
  },
  filters: {
    datetime(value: any, format: string = "YYYY-MM-DD HH:mm") {
      return moment.utc(value).locale("en-us").fromNow();
    },
    circleColor(finishedTime: any, promisedTime: any) {
      let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
      let end = moment.utc(promisedTime);
      let diff = moment.duration(start.diff(end)).asMinutes();
      if (diff > 2) {
        return 'text-danger'
      } else if (diff > 0) {
        return 'text-warning';
      } else {
        return 'text-success';
      }
    },
    formatDate(date: string) {
      return moment
          .utc(date, "YYYY-MM-D hh:mm:ss")
          .locale("en-us")
          .local()
          .format("MMM D YYYY HH:mm");
    }
  },
  mixins: [eventHandler, translations]
})
export default class Orders extends Vue {
  activeConcept: any
  canPlayNotificationSound: Boolean = false
  driverOpen: Boolean = false
  logisticsOpen: Boolean = false
  page: number = 1;
  size: number = 20;
  source: string = "Source"
  posTooltip: string = ""
  paymentMethod: string = "Payment"
  type: string = "Type"
  orders: any = [];
  driverData: Object = {}
  logisticData: Object = {}
  logistics: Array<Logistics> = []
  statuses: Array<Object> = []
  storeLocations: Array<Object> = []
  isLoading: Boolean = false
  visible: Boolean = false
  filter: Filter = {
    order: '',
    from: '',
    to: '',
    customer: '',
    email: '',
    mobile: '',
    location: [],
    status: '',
    today: true,
    type: ''
  }
  flag: Boolean = true;
  rows: number = 0
  perPage: number = 50
  currentPage: number = 1

  confirm!: Function
  broadcasted!: Function
  $notify: any
  notif!: Function
  public translate!: Function
  $refs!: {
    sound: HTMLAudioElement,
    playNewOrderAudio: any
  }
  isVisible: boolean = false

  @Watch("canPlayNotificationSound", {immediate: true, deep: true})
  onCanPlaySoundChanged(val: any) {
    if (val) {
      setTimeout(() => {
        this.$refs.sound.play()
      }, 100)
    }
  }

  mounted() {
    this.getOrders();
    this.getLogisticPartners()
    this.getOrderStatuses()
    this.pushedNotification()
    this.getStoreLocations()
  }

  closeOrder() {
    this.flag = true;
    this.visible = true;
  }

  openOrder() {
    this.flag = true;
  }

  circleTooltip(finishedTime: any, promisedTime: any) {
    let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
    let end = moment.utc(promisedTime);
    let diff = moment.duration(start.diff(end)).asMinutes();
    if (diff > 2) {
      return 'Past the promised time'
    } else if (diff > 0) {
      return 'Slightly past the promised time';
    } else {
      if (!finishedTime) {
        return 'Not finished';
      } else {
        return 'Finished on time';
      }
    }
  }

  posColor(code: number, posResponse: any) {
    if (code && posResponse) {
      this.posTooltip = "Order successfully sent to POS";
      return 'success'
    } else if (!code && posResponse) {
      this.posTooltip = "Order failed in POS";
      return 'danger'
    } else {
      this.posTooltip = "";
      return 'secondary'
    }
  }

  tooltip(code: number, posResponse: any) {
    if (code && posResponse) {
      return 'Order successfully sent to POS'
    } else if (!code && posResponse) {
      return 'Order failed in POS'
    } else {
      return ''
    }
  }

  paginate(page: number) {
    this.page = page
    this.getOrders()
  }

  resetForm(reset: any) {
    this.filter.order = ''
    this.filter.from = ''
    this.filter.to = ''
    this.filter.customer = ''
    this.filter.email = ''
    this.filter.mobile = ''
    this.filter.location = []
    this.filter.status = ''
    this.filter.today = true
    this.filter.type = ''
  }

  async pushedNotification() {
    await this.broadcasted(this.activeConcept.id).subscribe((payload: any) => {
      switch (payload.name) {
        case 'Solo\\Order':
          this.findOrder(payload.data.order.id)
          this.playNotificationSound();
          break;
        case 'Solo\\Order\\Status':
          console.log('y')
          this.findOrder(payload.data.order.id)
          break;
      }
    });
  }

  DriverModalOpenState(open: Boolean = true) {
    this.driverOpen = open
  }

  LogisticsModalOpenState(open: Boolean = true) {
    this.logisticsOpen = open
  }

  showDriver(data: Object) {
    this.driverData = data
    this.DriverModalOpenState(true)
  }

  showLogistics(data: Object) {
    this.logisticData = data
    this.LogisticsModalOpenState(true)
  }

  getOrders() {
    this.orders = []
    this.isLoading = true
    OrderApi.all(this.page, this.size, this.filter).then((response) => {
      this.isLoading = false
      this.orders = response.data.data;
      this.rows = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.currentPage = response.data.meta.pagination.current_page
      this.getOrderStatuses()
    });
    this.isVisible = false
  }

  getStoreLocations() {
    LocationApi.fetchLocations()
        .then((response: any) => {
          this.storeLocations = response.data.data
        })
  }

  playNotificationSound() {
    if (this.canPlayNotificationSound) {
      setTimeout(() => {
        this.$refs.sound.play()
      }, 100)
    }
  }

  async findOrder(orderId: string) {
    await OrderApi.find(orderId)
        .then((response: any) => {
          this.updateOrders(response.data.data)
        })
  }

async refreshStatus(data: any) {
    await OrderApi.getOrderStatusSync(data)
      .then((response: any) => {
        let item = response.data.data
        console.log(item)
        this.orders.some((v: any, i: any) => {
          if (item.id === v.id) {
            this.orders[i].attributes['current-status'].description = item.attributes.code
            return true
          }
        })
      })
  }

  updateOrders(data: any) {
    // data getting is duplicated
    this.orders.some((v: any, i: any) => {
      if (data.id === v.id) {
        this.orders.shift(data)
        this.orders[i].attributes['current-status'].description = data.attributes['current-status'].description
        return true
      }
    })
    this.orders.unshift(data)
  }

  updateOrderIndex(data: any) {
    this.orders[data.index] = data.data
  }

  iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`
  }

  getLogisticPartners() {
    Integrations.logisticsPartners('food-logistics')
        .then(response => {
          this.logistics = response.data.data
        })
  }

  getOrderStatuses() {
    OrderApi.statusList()
      .then((response: any) => {
        this.statuses = response.data.data
      })
  }


  exportOrder(orderId: string) {

    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be downloaded in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportOrder(orderId)
          .then(response => {
            window.open(response.data.data.attributes['csv-uri'], '_blank');
          })
    }

  }

  exportAllOrder() {

    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be downloaded in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportAllOrder()
          .then(response => {
            window.open(response.data.data.attributes['csv-uri'], '_blank');
          })
    }

  }

}
