






























































































































































































































import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { translations } from '@/mixins'
import AddCustomFieldModal from './AddCustomFieldModal.vue'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import { Menu as MenuApi, Settings, Item } from '@/services/SOLO';
import { Ingredient, ModifierGroupItem } from '@/models'

interface nameObj {
  [x: string]: any
}

interface ModgroupItem {
  name: nameObj
  'display-order': string
  label: string
  code: string
  type: string
  minimum: string
  maximum: string
  enabled: number
  'target-type': string
  'target-id': string
  'target-is': string
  'image-uri': string
  'initial-value': any
}

@Component({
  components: {
    AddCustomFieldModal,
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationObserver,
    ValidationProvider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModal extends Vue {

  private openCustomField: Boolean = false
  
  private visibleWhen: number = 0
  private arrayLang: Array<string> = ["en-us", "ar-sa"];
  private imageType: number = 0;
  private modifiers: Array<any> = []
  private globalCustomFields: Array<any> = []
  private customFields: Array<any> = []
  private id: string = ''
  private selectedFields: Array<any> = []
  private imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ]
  private itemId: string = ''
  private customFieldId: string = ''
  private customFieldsValue: string = ''
  private item: ModgroupItem = {
    name: {},    
    'display-order': '',
    label: '',
    code: '',
    type: '',
    minimum: '',
    maximum: '',
    enabled: 1,
    'image-uri': '',
    'target-type': '',
    'target-id': '',
    'target-is': '',
    'initial-value': ''
  }

  $refs!: {
    file: any
  }
  
  @Prop() private modifierGroupItems!: Array<Object>  
  @Prop() private open!: Boolean;

  constructor() {
    super();
    for (let i in this.arrayLang) {
      this.item.name[this.arrayLang[i]] = "";      
    }    
  }

  mounted() {
    console.log(this.$route)
    this.id = this.$route.params.modifierId
    this.arrayLang.map((lang: string, index: number) => {
      this.getItemModifierGroup(lang, index) 
    })      
  }

  get isEnabled() {
    return this.item.enabled === 1 ? true : false
  }

  set isEnabled(newValue: Boolean) {
    this.item.enabled = newValue ? 1 : 0
  }

  get defaultVisibility() {
    return this.item['initial-value'] === 'hidden' ? false : true
  }

  set defaultVisibility(newValue: Boolean) {
    this.item['initial-value'] = !newValue ? 'hidden' : ''
  }

  get isOpen(): Boolean {
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
    if(!newVal) {
      this.item.name = {}
      this.item['display-order'] = ''
      this.item.label = ''
      this.item.code = ''
      this.item.type = ''
      this.item.minimum = ''
      this.item.maximum = ''
      this.item.enabled = 1
      this.item['initial-value'] = ''
      this.item['target-type'] = ''
      this.item['target-id'] = ''
      this.item['target-is'] = ''
    }
  }

  @Watch('isOpen', { immediate: true, deep: true })
  isModalOpen() {
    if(this.isOpen) {      
      
    }
  }

  private updateCustomFieldModal(open: Boolean) {
    this.openCustomField = open
  }  

  private getItemModifierGroup(lang: string, index: number) {
    MenuApi.getItemModifierGroup(this.$route.params.itemId, this.id, lang).then(response => {
      let data: any = response.data.data
      this.item.name[lang] = data.attributes.name
      if(response.data.included) {
        this.modifiers = response.data.included.filter((include: any) => { return include.type === 'modifier' })
      }      
      if(!index) {        
        if(data.attributes['custom-fields']) {
          this.customFields = data.attributes['custom-fields']
        }
        this.customFields = data.attributes['custom-fields']
        this.item['display-order'] = data.attributes['display-order']
        this.item['image-uri'] = data.attributes['image-uri']
        this.item.label = data.attributes.label
        this.item.code = data.attributes.code
        this.item.type = data.attributes.type
        this.item.minimum = data.attributes.minimum
        this.item.maximum = data.attributes.maximum
        this.item.enabled = data.attributes.enabled
        if(data.attributes['visibility-config']) {
          this.item['initial-value'] = data.attributes['visibility-config']['initial-value']
          this.item['target-type'] = data.attributes['visibility-config'].rules[0]['target-type']
          this.item['target-id'] = data.attributes['visibility-config'].rules[0]['target-id']
          this.item['target-is'] = data.attributes['visibility-config'].rules[0]['target-is']
        }        
      }      
    })
  }

  private refreshSelectedCustomFields() {
    MenuApi.getItemModifierGroup(this.$route.params.itemId, this.id).then(response => {
      let data: any = response.data.data
      if(data.attributes['custom-fields']) {
        this.customFields = data.attributes['custom-fields']
      }else {
        this.customFields = []
      }
    })
  }

  private updateCustomField(customFieldId: string, value: string) {
    Item.getCustomFields()
      .then((response: any) => {        
        this.globalCustomFields = response.data.data
        this.itemId = this.id
        this.selectedFields = this.customFields
        this.customFieldId = customFieldId
        this.customFieldsValue = value
        this.updateCustomFieldModal(true)        
      })
  }

  private removeCustomField(customFieldId: string)  {
    let payload: Object = {
      entity: "modifier-group",
      'entity-id': this.id,      
    }
    Item.deleteCustomFields(payload, customFieldId)
      .then((response: any) => {
        this.refreshSelectedCustomFields()
      })
  }

  private getCustomFields() {
    Item.getCustomFields()
      .then((response: any) => {        
        this.globalCustomFields = response.data.data
        this.selectedFields = this.customFields
        this.itemId = this.id
        this.customFieldId = ''
        this.customFieldsValue = ''
        this.updateCustomFieldModal(true)        
      })
  }

  private updateData() {   
    
    let form = new FormData();    
    Object.keys(this.item.name).map((key: any) => {      
      form.append(`name[${key}]`, this.item.name[key]);    
    })    
    
    form.append(`_method`, 'PATCH');    
    form.append(`display-order`, this.item['display-order']);
    form.append(`label`, this.item.label);
    form.append(`code`, this.item.code);
    form.append(`type`, this.item.type);
    form.append(`minimum`, this.item.minimum);
    form.append(`maximum`, this.item.maximum);
    form.append(`enabled`, this.item.enabled.toString());
    form.append(`visibility-config-rules[0][target-type]`, this.item['target-type']);
    form.append(`visibility-config-rules[0][target-id]`, this.item['target-id']);
    form.append(`visibility-config-rules[0][target-is]`, this.item['target-is']);
    form.append(`visibility-config-initial-value`, !this.defaultVisibility ? 'hidden' : '');
    if (!this.imageType) {
        form.append(`image-uri`, this.item['image-uri']);
    } else if (this.imageType) {
        form.append(`image`, this.$refs.file.files[0]);
    }    

    this.updateItem(form)
  }  

  private updateItem(payload: FormData) {
    MenuApi.updateItemModifierGroup(payload, this.$route.params.itemId, this.id).then((response: any) => {
      console.log(response.data.data)
      this.$emit('modifier:update')
      this.isOpen = false
    })
  }

  
}
