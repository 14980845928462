import BaseService from '../base.service'
import store from '@/store'
export default class CategoryService extends BaseService {

    constructor() {
        super();
        this.baseURL = this.baseURL + 'menus/';
    }

    index(menuId: string | number) {
        let url = this.baseURL + menuId + '/categories';
        return super.get(url);
    }

    all(menuId: string | number, page = 1) {
        let url = this.baseURL + menuId + '/categories?filter[enabled]=all&page='+ page;
        return super.get(url, {
            page: page
        });
    }

    items(menuId: string | number, categoryId: string | number, page = 1) {
        let url = this.baseURL + menuId + `/categories/${categoryId}/items?filter[enabled]=all&page=`+ page
        return super.get(url);
    }

    createItem(payload: Object = {}, menuId: string | number, categoryId: string | number, content: string = 'application/json') {

        this.headers['Content-Type'] = content

        let url = this.baseURL + menuId + `/categories/${categoryId}/items`

        return super.post(url, payload);
    }

    enableCategoryItem(payload: Object = {}, menuId: string, categoryId: string, itemId: string) {
        let url = this.baseURL + `${menuId}/categories/${categoryId}/items/${itemId}`
        return super.post(url, payload)
    }

    removeCategoryItem(menuId: string, categoryId: string, itemId: string) {
        let url = this.baseURL + `${menuId}/categories/${categoryId}/items/${itemId}`
        return super.remove(url, {})
    }

    find(menuId: string | number, categoryId: string | number) {
        let url = this.baseURL + menuId + '/categories/' + categoryId;

        return super.get(url)
    }

    removeCategory(menuId: string, categoryId: string) {
        let url = this.baseURL + `${menuId}/categories/${categoryId}`
        return super.remove(url, {})
    }

    enableCategory(payload: Object = {}, menuId: string, categoryId: string) {
        let url = this.baseURL + `${menuId}/categories/${categoryId}`
        return super.post(url, payload)
    }

    createCategory(payload = {}, menuId: string, lang: string = 'en-us') {
        let url = this.baseURL + `${menuId}/categories`

        return super.post(url, payload);
    }

    createCategoryForm(payload: FormData, menuId: string, lang: string = 'en-us') {
        this.headers['Content-Type'] = 'multipart/form-data'
        let url = this.baseURL + `${menuId}/categories`

        return super.post(url, payload);
    }

    integrationCategories() {
        this.baseURL = process.env.VUE_APP_API_DOMAIN
        let url = this.baseURL + 'categories';
        return super.get(url);
    }
}
