



















import { translations, eventHandler } from "@/mixins";
import Translations from "./Translations.vue";
import { FadeTransition, ScaleTransition } from "vue2-transitions";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import moment from "moment";
import { LoadingPanel } from "@/components";
import { Location } from "@/models";

interface ojbKey {
  [x: string]: string;
}

interface locationObject {
  id: string;
  name: ojbKey;
  status: string;
  telephone: string;
  pos: string;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": Boolean;
  "pickup-enabled": Boolean;
  "curbside-enabled": Boolean;
  "delivery-charge": string;
  "delivery-integration-id": string;
  line1: ojbKey;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
}

let defaultLocationObject = {
  id: "",
  name: {},
  status: "active",
  telephone: "",
  pos: "",
  email: "",
  country: "",
  lat: "",
  long: "",
  "delivery-integration-id": "",
  "delivery-enabled": true,
  "pickup-enabled": true,
  "curbside-enabled": true,
  "delivery-charge": "",
  line1: {},
  "promised-time-delta": {
    delivery: "",
    pickup: "",
  },
  "opening-hours": [],
};

@Component({
  components: {
    FadeTransition,
    ScaleTransition,
    Translations,
    LocationInfoForm: () => import("./LocationInfoForm"),
    LocationUpdate: () => import("../LocationUpdate"),
  },
  mixins: [translations, eventHandler],
})
export default class LocationModal extends Vue {
  @Prop() location!: any;
  private locationLocal: locationObject = defaultLocationObject;

  public open: boolean = false;

  $notify: any;
  confirm!: Function;
  translate!: Function;

  private arrayLang: Array<string> = ["en-us", "ar-sa"];
  private gmapModal: Boolean = false;
  private saving: Boolean = false;

  private cancel() {
    this.open = false;
    this.$emit("close");
  }

  private onLocationCreated(location: Location) {
    this.$emit("location:created", location);
  }

  private onLocationFailed(modal: any) {
    this.open = modal
  }

  private onLocationUpdated(location: Location) {
    this.$emit("location:updated", location);
  }

  private onLocationDeleted(location: Location) {
    this.$emit("location:deleted", location);
  }
}
