













































































































































import { translations } from "@/mixins";
import VueTimepicker from "vue2-timepicker";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
@Component({
  components: {
    VueTimepicker,
  },
  mixins: [translations],
})
export default class StoreTimes extends Vue {
  @Prop() openingHours!: Array<any>;

  private timeValidationRules() {
    return { required: true, regex: /^[0-9][0-9]:[0-9][0-9] [AaPp][Mm]/ };
  }
}
