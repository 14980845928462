


















































import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator"
import { mapGetters } from "vuex"
import { Menu as MenuApi } from '@/services/SOLO'
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui"
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
})
export default class SelectedComponentItems extends Vue {

  @Prop() componentId!: string
  @Prop() items!: Array<Object>;
  @Prop() selectedItemIsLoading!: Boolean
  
  getSize(sizes: any, strSize: string) {
    let filtered = sizes.filter((size: any) => {      
      return size.item_modifier
    })

    filtered = filtered.filter((size: any) => {
      return size.item_modifier.name.toLowerCase() === strSize
    })
    
    if(!filtered.length) {
      return 0
    }

    return Number(filtered[0].price)
    
  }

  removeComboComponent(e: any, componentItemId: string) {
    let target = e.target
    let defaultText = target.innerHTML
    console.log(e)
    target.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'  
    target.disabled = true
    MenuApi.removeComboComponent(this.componentId, componentItemId)
      .then(response => {
        console.log(response.data.data)
        this.$emit('item:unlinked', this.componentId)
        target.innerHTML = defaultText
        target.disabled = false
      })
      .catch(err => {
        target.innerHTML = defaultText
        target.disabled = false
      })
  }
}
