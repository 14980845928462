


































































































































import Modal from '@/components/Modal.vue'
import selectedComponentItems from './SelectedComponentItems.vue'
import AvailableComponentItems from './AvailableComponentItems.vue'
import { Select, Option } from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import { Menu as MenuApi, Settings } from '@/services/SOLO';
import { GlobalIng, GlobalAllergens as GlobalAllergensModel, ModifierGroupItem, ComboComponent, ComponentName } from '@/models'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    selectedComponentItems,
    ValidationProvider,
    AvailableComponentItems
  },
  mixins: [eventHandler]
})
export default class ComboMealsModal extends Vue {        
  imageType: number = 0
  arrayLang: Array<string> = ["en-us", "ar-sa"]
  selectedComponentItems: Array<Object> = []
  availableComponentItems: Array<Object> = []
  selectedItemIsLoading: Boolean = false
  availableItemIsLoading: Boolean = false
  componentId: string = ''
  component: ComboComponent = {
    label: '',
    name: {},
    display_order: 1,
    code: '',
    minimum: 0,
    maximum: 1,
    'image-uri': ''
  }
  componentName: ComponentName = {}
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ]

  $refs!: {  
    file: any
  }

  constructor() {
    super()
    for(let i in this.arrayLang)
      this.componentName[this.arrayLang[i]] = ''
  }

  @Prop() open!: Boolean
  @Prop() updateData!: any
  @Prop() isUpdate!: Boolean
  
  @Watch('updateData', {immediate: true, deep: true})
  onUpdateDate(newVal: any) {    
    if(newVal.id) { 
      this.componentId = newVal.id               
      this.component.label= newVal.attributes.label,
      this.component.code= newVal.attributes.code
      this.component['display_order']= newVal.attributes['display-order']
      this.component['image-uri']= newVal.attributes['image-uri']
      this.component.label= newVal.attributes.label
      this.component.maximum= newVal.attributes.maximum
      this.component.minimum= newVal.attributes.maximum
      this.getComponentItems(newVal.id)
      this.getAvailableComponentItems()
      for(let i in this.arrayLang) {
        this.getComboItem(newVal.id, this.arrayLang[i])
      }
    }    
  }

  get componentData(): ComboComponent {
    this.component.name = this.componentName
    return this.component
  }

  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
    if(!newVal) {
      this.component = {
        label: '',
        name: {},
        display_order: 1,
        code: '',
        minimum: 0,
        maximum: 1,
        'image-uri': ''
      }
      this.componentId = ''
      this.availableComponentItems = []
      this.selectedComponentItems = []
      this.selectedItemIsLoading = false
      this.availableItemIsLoading = false
      for(let i in this.arrayLang)
        this.componentName[this.arrayLang[i]] = ''
    }
  }

  getComponentItems(id: string) {    
    this.selectedItemIsLoading = true
    MenuApi.getComboComponentItems(id)
      .then(response => {        
        this.selectedComponentItems = response.data.data
        this.selectedItemIsLoading = false      
      })
  }

  getAvailableComponentItems() {
    this.availableItemIsLoading = true
    MenuApi.searchComboItems(this.$route.params.id)
      .then(response => {
        this.availableComponentItems = response.data.data
        this.availableItemIsLoading = false        
      })
  }
  
  getComboItem(id: string, lang: string) {
    MenuApi.getComboItem(this.$route.params.id, this.$route.params.itemId, id, lang)
      .then(response => {        
        this.componentName[lang] = response.data.data.attributes.name
      })
  }  

  saveComponent(e: any, reset: any) {
    let target = e.target
    let defaultText = target.innerHTML
    target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving component'  
    target.disabled = true
    if(!this.isUpdate) {      
      MenuApi.createComboComponent(this.componentData, this.$route.params.itemId)
        .then(response => {
          this.$emit('component:added', response.data.data)
          target.innerHTML = defaultText
          target.disabled = false
          this.$emit('modal:update', false)          
        })
    }else {
      MenuApi.updateComboComponent(this.componentData, this.$route.params.itemId, this.componentId)
        .then(response => {
          this.$emit('component:added', response.data.data)
          target.innerHTML = defaultText
          target.disabled = false
          this.$emit('modal:update', false)          
        })
    }    
  }
  
}
