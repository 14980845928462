


































































































































import { RegisterUser } from '@/interfaces/Landing'
import { Auth, FoodicsAuth } from '@/services/core'
import { Concept as ConceptApi, Menu } from '@/services/SOLO'
import { ValidationObserver, ValidationProvider, configure, extend } from 'vee-validate'
import { mapGetters, mapMutations } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Component, Prop, Vue, Watch, Ref, Mixins } from 'vue-property-decorator'


extend('password', {
  params: ['target'],
  validate(value, target: any) {
    return value === target.target;
  },
  message: 'Password confirmation does not match'
});

@Component({
  components: {
    ValidationProvider
  },
  computed: {
    ...mapGetters({
      getUserClient: 'account/getUserClient',
      activeConcept: 'account/activeConcept',
    })
  },
  methods: {
    ...mapMutations({
      setUser: 'account/setUser',
      setUserRole: 'account/setUserRole',
      setAppSubscription: 'app/setAppSubscription',
      setSubscription: 'account/setSubscription',
      setUserConcept: 'account/setUserConcept',
      setConceptSettings: 'account/setConceptSettings',
      setMenus: 'menu/setMenus',
    })
  },
  mixins: [eventHandler, translations],
})
export default class Home extends Vue {
  private getUserClient: any
  private activeConcept: any
  private model: RegisterUser = {
    'first-name': '',
    'last-name': '',
    rememberMe: false,
    'email': '',
    password: '',
    'password_confirmation': '',
    'business-name': '',
    'mobile': '',
    'country-code': 'sa',
  }

  private setAppSubscription!: Function
  private setMenus!: Function

  mounted() {
    this.setAppSubscription(this.$route)
  }

  private get hasFoodicsRegister(): Boolean {
    if(this.getUserClient) {
      if(this.getUserClient.attributes.auth.split(',').includes('foodics')) {
        return true
      }
      return false
    }
    return false
  }

  get regexMobile() {
    if(this.model['country-code'] === 'sa') {
      return /^(?:\+966|966|0)5(?:[503649187])\d{7}$/
    } else if(this.model['country-code'] === 'ae') {
      return /^(?:\+971|971|0)\d{9}$/
    } else if(this.model['country-code'] === 'eg') {
      return /^(?:\+20|20|0)\d{10}$/
    } else {
      return /^(?:\+212|212|0)\d{9}$/
    }
  }

  private setUser!: Function
  private setUserRole!: Function
  private setSubscription!: Function
  private setConceptSettings!: Function
  private setUserConcept!: Function

  private registerUser() {
    Auth.register(this.model).then(response => {
      if (response.data.data.attributes.roles[0] === 'administrator' && Number(this.$route.params.planId) != 1) {
        this.setUser(response.data.data)
        this.setUserRole(response.data.data.attributes.roles)
        this.setUserConcept(response.data.data.attributes.concepts[0])
        setTimeout(() => {
          document.body.classList.remove('bg-default');
          document.body.classList.remove('bg-solo');
          this.$router.push({name:'customer-subscription'});
        }, 1000)
      } else {
        this.setUser(response.data.data)
        this.setUserRole(response.data.data.attributes.roles)
        this.setUserConcept(response.data.data.attributes.concepts[0])
        this.conceptUpdate(response.data.data.attributes.concepts[0].id)

          .then(res => {
            this.getAccountConcept(response.data.data, response.data.data.attributes.roles, response.data.data.attributes.concepts[0])
              .then(res => {
                this.setConceptSettings(res.data.data)
                this.getApps()
                setTimeout(() => {
                  document.body.classList.remove('bg-default');
                  document.body.classList.remove('bg-solo');
                  this.$router.push({ path:'/apps/create' });
                }, 1000)
              })
          })
      }
    })
  }


  private async conceptUpdate(conceptId: any) {
    let payload = { "is-free-package": true }
    return await ConceptApi.updateConcept(payload, conceptId)
  }

  private async getAccountConcept(data: any, roles: any, subscription: any) {
    return await ConceptApi.get(this.activeConcept.id)
  }

  getApps () {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  private redirectToMarketing() {
    window.location.replace('http://www.getsolo.io')
  }

  private signInWithFoodics() {
    FoodicsAuth.redirect();
  }
}
