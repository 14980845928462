



































































































































import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { translations } from '@/mixins'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import { Menu as MenuApi, Settings, Item } from '@/services/SOLO';
import { Ingredient, ModifierGroupItem } from '@/models'

interface nameObj {
  [x: string]: any
}

interface ModgroupItem {
  name: nameObj
  'calorie-count': any
  code: string  
  'display-order': any
  enabled: number
  'image-uri': string
  maximum: any
  minimum: any
  price: 0 
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationObserver,
    ValidationProvider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModifierItem extends Vue {  
  private arrayLang: Array<string> = ["en-us", "ar-sa"];
  private imageType: number = 0;  
  private imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ]
  private item: ModgroupItem = {
    name: {},    
    'display-order': '',
    'calorie-count': '',
    code: '',    
    enabled: 1,
    'image-uri': '',
    maximum: '',
    minimum: '',
    price: 0 
  }

  $refs!: {
    file: any
  }
  
  @Prop() private modifierGroupItems!: Array<Object>    

  constructor() {
    super();
    for (let i in this.arrayLang) {      
      this.item.name[this.arrayLang[i]] = "";      
    }    
  }

  mounted() {
    if(this.$route.params.modifierGroupId != 'create') {
      for (let i in this.arrayLang) {
        this.getModifierItemDetails(i)
      }    
    }    
  }

  get isEnabled() {
    return this.item.enabled === 1 ? true : false
  }

  set isEnabled(newValue: Boolean) {
    this.item.enabled = newValue ? 1 : 0
  }
  
  private getModifierItemDetails(i: any) {    
    MenuApi.getModifierItemDetails(this.$route.params.modifierId, this.$route.params.modifierGroupId, this.arrayLang[i])
      .then((response: any) => {
        let data = response.data.data
        this.item.name[this.arrayLang[i]] = data.attributes.name
        if(!Number(i)) {
          this.item['display-order'] = data.attributes['display-order']
          this.item['calorie-count'] = data.attributes['calorie-count']
          this.item.code = data.attributes.code
          this.item.enabled = data.attributes.enabled
          this.item['image-uri'] = data.attributes['image-uri']
          this.item.maximum = data.attributes.maximum
          this.item.minimum = data.attributes.minimum
          this.item.price = data.attributes.price
        }        
      })
  }
  
  private updateData(e: any) {       
    let form = new FormData();    
    Object.keys(this.item.name).map((key: any) => {      
      form.append(`name[${key}]`, this.item.name[key]);    
    })    
        
    form.append(`display-order`, this.item['display-order']);    
    form.append(`calorie-count`, this.item['calorie-count']);
    form.append(`code`, this.item.code);    
    form.append(`minimum`, this.item.minimum);
    form.append(`maximum`, this.item.maximum);
    form.append(`price`, this.item.price.toString());
    form.append(`enabled`, this.item.enabled.toString());    
    if (!this.imageType) {
        form.append(`image-uri`, this.item['image-uri']);
    } else if (this.imageType) {
        form.append(`image`, this.$refs.file.files[0]);
    }    
    if(this.$route.params.modifierGroupId != 'create') {
      this.updateModifierItem(e, form, this.$route.params.modifierId, this.$route.params.modifierGroupId)
    } else {
      this.storeModifierItem(e, form, this.$route.params.modifierId)
    }
  }

  private storeModifierItem(e: any, payload: FormData, modifierId: string) {
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> saving ...'
    e.target.disabled = true
    MenuApi.storeModifierItem(payload, modifierId)
      .then((response: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$router.push({name: 'modifier'})
      })
  }

  private updateModifierItem(e: any, payload: FormData, modifierId: string, modifierGroupId: string) {
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> saving ...'
    e.target.disabled = true
    MenuApi.updateModifierItem(payload, modifierId, modifierGroupId)
      .then((response: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$router.push({name: 'modifier'})
      })
  }
}
