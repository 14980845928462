
































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters } from "vuex";
import {Order as OrderApi} from "@/services/SOLO";
@Component({
  computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
    }),
  },
})
export default class LocationItems extends Vue {

  orders: any = [];
  @Prop() locations!: Array<Object>;
  private getLocale!: Function;

  private arrayLang: Array<string> = ["en-us", "ar-sa"];

  private enable(status: string, id: string) {
    let payload = {
      status: status === "active" ? "inactive" : "active",
    };
    this.$emit("enable", payload, id);
  }

  public remove(id: string) {
    this.$emit("remove", id);
  }

  private pan(lat: number, long: number) {
    let payload = {
      lat: lat,
      long: long,
    };
    this.$emit("pan", payload, 16);
  }


    iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`
  }
}
