




































import { eventHandler } from "@/mixins"
import { Select, Option } from "element-ui"
import { Menu as MenuApi } from '@/services/SOLO'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [eventHandler],
})
export default class AvailableComponentItems extends Vue {
  id: string = ''
  newComponentItems: Array<string> = []
  formatSelected: Array<string> = []
  @Prop() componentId!: string
  @Prop() availableItemIsLoading!: Boolean
  @Prop() selectedComponentItems!: Array<Object>
  @Prop() availableComponentItems!: Array<Object>

  @Watch('componentId', {immediate: true, deep: true})
  onComponentIdchange(newVal: string) {    
    this.id = newVal
  }

  @Watch('selectedComponentItems', {immediate: true, deep: true})
  onSelectedComponentsChange(newVal: any) {    
    this.formatSelected = newVal.map((item: any) => {
      return item.id
    })   
  }

  linkItems(e: any) {        
    let target = e.target
    let defaultText = target.innerHTML
    target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving component'  
    target.disabled = true
    let payload = {items: this.newComponentItems}
    MenuApi.linkComponentItems(payload, this.id)
      .then(response => {        
        this.newComponentItems = []
        this.$emit('item:linked', this.id)
        target.innerHTML = defaultText
        target.disabled = true
      })
      .catch(err => {
        console.log(err)
        target.innerHTML = defaultText
        target.disabled = true
      })
  }
}
