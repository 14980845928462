



























import { Table, TableColumn, Button } from "element-ui"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import {Component, Vue} from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'
@Component({
  components: {
    RouteBreadCrumb,
    [Button.name]: Button,
  },
  mixins: [translations]
})
export default class Blacklists extends Vue {
public translate!: Function
}
