import BaseService from '../base.service'
export default class MessagingService extends BaseService {

  constructor() {
    super();
  }

  fetchPushNotifications(status: string = '') {
    const url = `${this.baseURL}push-notifications${!!status ? `?filter[status]=${status}` : ''}`;
    return super.get(url);
  }

  fetchDevicesCount(conceptId: string | number) {
    const url = `${this.baseURL}devices?concept_id=${conceptId}`;
    return super.get(url);
  }

  fetchSegments() {
    const url = `${this.baseURL}segments/all`;
    return super.get(url);
  }

  sendPushNotification(params: {}) {
    // this.headers['Accept-Language'] = lang
    // this.headers['Content-Type'] = 'multipart/form-data'

    let url = `${this.baseURL}push-notifications`;
    return super.post(url, params);
  }
}
