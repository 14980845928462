





































































































































































































































































import Translations from "./components/Translations.vue";
import {Application, QueueBuster as Queue} from "@/models";
import {
  Form,
  Alert,
  Input,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Button,
} from "element-ui";
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator";
import { Location, Concept, Applications, Coupon } from "@/services/SOLO";
import VueQrious from "vue-qrious";
import VueHtml2pdf from "vue-html2pdf";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import {notificationAlerts, translations} from "@/mixins";

interface ApplicationAttributesObject {
  "application-type": string;
  "custom-domain": string;
  label: string;
  hosts: string;
}

interface ApplicationObject {
  id: string;
  attributes: ApplicationAttributesObject;
}

interface FormObject {
  branch: string;
  branchCode: string;
  branchLogo: string;
  qr_type: string;
  qrCodeCount: number;
  printLogo: boolean;
  printTable: boolean;
  printBranch: boolean;
  to: number;
  from: number;
  "web-uri": string;
}

@Component({
  components: {
    Translations,
    [Col.name]: Col,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    VueQrious,
    VueHtml2pdf,
    RouteBreadCrumb,
    QueueBusterQrCode: () => import("./components/QueueBusterQrCode.vue"),
  },
  mixins: [translations, notificationAlerts],
})
export default class QueueBuster extends Vue {
  queueBuster?: Queue;
  $refs!: any;
  $notify!: any;
  form: FormObject = {
    branch: "",
    branchCode: "",
    branchLogo: "",
    qr_type: "",
    qrCodeCount: 1,
    printLogo: false,
    printTable: false,
    printBranch: false,
    to: 1,
    from: 1,
    "web-uri": "",
  };
  radio: number = 1;
  branches: Array<Object> = [];
  applications: Array<ApplicationObject> = [];
  selectedApplication: ApplicationObject = {
    id: "",
    attributes: {
      "application-type": "",
      "custom-domain": "",
      label: "",
      hosts: "",
    },
  };
  qr_type: string = "";
  qr_options: any = [
    {text: "Eat-in", value: "eat-in"},
    {text: "To-go", value: "to-go"},
    {text: "Delivery", value: "deliver"},
  ];
  dynamicTable?: any = {
    table: [1],
  };
  selectedTable: any = 1;
  currentConcept: any = "";
  isQRTriggered: boolean = false;
  translate!: Function;
  coupons: any = [];
  couponSelected: any = null;

  mounted() {
    this.getApplications();
    this.getAllLocations();
    this.getCurrentConcept();
    this.getCoupons();
  }

  async getCoupons() {
    const response = await Coupon.all();
    this.coupons = response.data.data;
  }

  async getApplications() {
    const response = await Applications.getAllApplications();
    const applications = response.data.data;
    this.applications = applications.filter((item: ApplicationObject) => item.attributes["application-type"] === "social-ordering");
    this.selectedApplication = this.applications[0];
    let attributes = this.selectedApplication.attributes;
    this.form["web-uri"] = attributes["custom-domain"]
        ? attributes["custom-domain"]
        : attributes.hosts;
  }

  getAllLocations() {
    Location.getAllLocations().then((response: any) => {
      this.branches = response.data.data;
    });
  }

  onApplicationChange() {
    let attributes = this.applications.filter((data: any) => data.id === this.selectedApplication);

    this.form["web-uri"] = attributes[0].attributes["custom-domain"]
        ? attributes[0].attributes["custom-domain"]
        : attributes[0].attributes.hosts;
  }

  addTable(tableNumber: number) {
    // if (this.dynamicTable.table.length === 9) {
    //   return false
    // }
    this.dynamicTable.table.push(tableNumber);
  }

  tableType(data: any) {
    this.selectedTable = data;
    if (+data === 1) {
      let tableCount = this.dynamicTable.table.length;

      this.form.from = 1;
      this.form.to = tableCount;
      this.dynamicTable.table = [];
      for (let i = 1; i <= tableCount; i++) {
        this.dynamicTable.table.push(i);
      }
    }
  }

  selectSequence() {
    if (this.form.to < this.form.from) {
      this.$notify({
        title: this.translate("TABLE NUMBERS INCORRECT"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate(
            "Table FROM number must not be greater than Table number TO."
        ),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }

    this.dynamicTable.table = [];
    for (let i = this.form.from; i <= this.form.to; i++) {
      if (this.form.to > 20) {
        this.$notify({
          title: this.translate("QR Code Limit"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate(
              "You can only generate up to 20 QR Codes"
          ),
          type: "danger",
          icon: "fas fa-bomb",
        })
        return false
      }
      this.addTable(i);
    }
  }

  selectQRType() {
    // this.isQRTriggered = true;
    this.form.qrCodeCount = 1;
    if (this.form.qr_type != "eat-in") {
      this.dynamicTable.table = [1];
      this.form.from = 1;
      this.form.to = 1;
    }

    if (this.form.qr_type === "deliver") {
      this.form.branch = '';
      this.form.printBranch = false;
    }

    this.showBranchDetails(this.branches[0])

  }

  showBranchDetails(data: any) {
    let findBranch: any = this.branches.filter(
        (branch: any) => branch.id == data ? data : data.id
    )[0];
    if (data && data.id) {
      this.form.branch = data.id
    }
    this.form.branchCode = findBranch.attributes.name;
    this.isQRTriggered = true;
  }

  async getCurrentConcept() {
    await Concept.default().then((response: any) => {
      console.log('getCurrentConcept: ', response.data.data);
      this.currentConcept = response.data.data.attributes.label;
      this.form.branchLogo = response.data.data.attributes["logo-uri"]
          ? response.data.data.attributes["logo-uri"]
          : null;
    });
  }

  removeTable(item: any) {
    let index = this.dynamicTable.table.indexOf(item);
    if (index !== -1) {
      this.dynamicTable.table.splice(index, 1);
    }
  }

  async generateReport() {
    await this.$refs.html2Pdf.generatePdf();
  }

  getCode(couponSelected: string) {
    let code = null;

    if (this.coupons && Array.isArray(this.coupons)) {
      const coupon = this.coupons.find(c => c.id === couponSelected);
      if (coupon) {
        return coupon.attributes.code;
      }
    }

    return code;
  }
}
