import BaseService from '../base.service'
import store from '@/store'
export default class BannerService extends BaseService {
  url!: string
  constructor() {
    super();
    this.baseURL = this.baseURL + 'cms/';
    this.baseURL1 = this.baseURL1;
  }

  // all(menuId: string | number, page = 1) {
  //   let url = this.baseURL + menuId + '/categories?filter[enabled]=all&page=' + page;

  //   return super.get(url, {
  //     page: page
  //   });
  // }


  banners() {
    this.url = this.baseURL + 'sliders?filter[enabled]=all&include=slides';
    return super.get(this.url);
  }


  menus() {
    this.url = this.baseURL1 + 'menus';
    return super.get(this.url);
  }
  


  categorySearch(menuID: number) {
    this.url = this.baseURL1 + `menus/${menuID}/categories`;
    return super.get(this.url);
  }

  createBannerLabelForm(payload: {}) {

    let url = this.baseURL + 'sliders';

    return super.post(url, payload);
  }

  createBannerSlideForm(payload: FormData, bannerID: Number) {

    this.headers['Content-Type'] = 'multipart/form-data'

    let url = this.baseURL + `sliders/${bannerID}/slides`;

    return super.post(url, payload);
  }

  removeslide(bannerID: string | Number, slideID: string | Number) {
    let url = this.baseURL + `sliders/${bannerID}/slides/${slideID}`
    return super.remove(url, {})
  }

  findSlide(bannerID: string | Number, slidesID: string | Number, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    let url = this.baseURL + 'sliders/' + bannerID + '/slides/' + slidesID;

    return super.get(url)
  }

  update(payload = {}, bannerID: string | Number, slidesID: string | Number, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    let url = this.baseURL + `sliders/${bannerID}/slides/${slidesID}`

    return super.post(url, payload);
  }

  updateFormdata(payload = {}, bannerID: string | Number, slidesID: string | Number, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.headers['Content-Type'] = 'multipart/form-data'
    let url = this.baseURL + `sliders/${bannerID}/slides/${slidesID}`

    return super.post(url, payload);
  }

  createBannerSlide(payload: {}, bannerID: Number) {

    let url = this.baseURL + `sliders/${bannerID}/slides`;

    return super.post(url, payload);
  }

  removeBannerSet(bannerId: Number) {

    let url = this.baseURL + `sliders/${bannerId}`

    return super.remove(url, {});

  }



}
