









import Modal from '@/components/Modal.vue'
import EventCalendar from '@/views/Admin/global-component/EventCalendar.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { TimeEvent } from '@/models';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { Table, TableColumn } from "element-ui"    
@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    VueTimepicker,
    EventCalendar
  },
  mixins: [eventHandler, translations]
})
export default class TimeEventModal extends Vue {

  days: Array<String> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  @Prop() open!: Boolean
  @Prop() timeEvent!: TimeEvent  
  translate!: Function
  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
  }
  
  submit(event: TimeEvent) {
    this.$emit('submit:event', event)
  }
}
