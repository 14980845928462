









































































import { Settings } from '@/services/SOLO'
import { Component, Vue } from "vue-property-decorator"
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

  interface modifierGroupName {
    [x: string]: string
  }

  interface modifierGroupIcon {
    [x: string]: any
  }

  interface modifierGroupIconName {
    [x: string]: string
  }

  interface modifierGroup {
    name: modifierGroupName
    icon: modifierGroupIcon
  }

  @Component({
    components: {
      Translations,
    },
    mixins: [translations],
  })
  export default class CreateAllergen extends Vue {
    translate!: Function;
    open: boolean = false
    openDefault: boolean = false
    isFileActive: boolean = false
    name: modifierGroupName = {}
    icon: modifierGroupIcon = {}
    iconName: modifierGroupIconName = {}
    arrayLang: Array<string> = ["ar-sa", "en-us"];
    $notify: any
    $refs!: {
      fileIcon: any
      form: any
      formValidator: any
      inputName: any
    }
    modifierGroup: modifierGroup = {
      name: {},
      icon: {}
    }
    constructor() {
      super()
      for(let i in this.arrayLang) {
        this.name[this.arrayLang[i]] = ''
        this.iconName[this.arrayLang[i]] = ''
      }
    }

    initCreate() {
      this.open = true
    }

    initCreateDefaults() {
      this.openDefault = true
    }

    filesUpload(event: any, lang: string) {
      this.iconName[lang] = event.target.files[0].name
      this.icon[lang] = event.target.files[0]
      this.isFileActive = true
    }

    get modifierGroupData() : modifierGroup {
      this.modifierGroup.icon = this.iconName
      this.modifierGroup.name = this.name
      return this.modifierGroup
    }

    isFormData(name: any, icon: any) {
      const allergenFormData = new FormData()

      allergenFormData.append('_method', 'POST')

      Object.entries(icon).map((value: any | Blob) => {
        allergenFormData.append(`icon[${value[0]}]`, value[1])
      })

      Object.entries(name).map((value: any ) => {
        allergenFormData.append(`name[${value[0]}]`, value[1])
      })

      return allergenFormData
    }

    async create(e: any) {

      const payload = this.isFileActive ? this.isFormData(this.name, this.icon) : this.modifierGroupData;

      await Settings.createAllergen(payload).then((response: any) => {
        this.$emit('addAllergen', response.data.data);
        this.open = false
        this.resetForm()

      }).catch((err: any) => {
        this.open = false
        this.resetForm()

      })
    }

    async createDefaultAllergen() {
      await Settings.createDefaulAllergen({}).then((response: any) => {
        this.openDefault = false
      })
    }

    closeMe() {
      this.open = false
    }

    resetForm() {
      this.name = {}
      this.icon = {}
      return
    }


  }
  