































































import itemCreateModal from './itemCreateModal.vue'
import { notificationAlerts, translations } from '@/mixins'
import { Category as CategoryApi } from "@/services/SOLO";
import { Category as CategoryModel } from "@/models";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from '@/mixins'
@Component({
  components: {
    itemCreateModal
  },
  mixins: [eventHandler, translations, notificationAlerts]
})
export default class ItemList extends Vue {
  @Prop() menuId!: string;
  @Prop() categoryId!: string;
  @Prop() active!: Boolean
  open: Boolean = false
  items: Array<any> = []

  confirm!: Function
  translate!: Function
  successNotification!: Function

  get deleteMsgBoxText(): String {
    return this.translate('Are you sure you want to delete this Category Item?')
  }

  mounted() {
    this.getCategoryItems()
  }

  modalOpenState(open: Boolean = true) {
    this.open = open
  }

  removeCategoryItem(itemId: string) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
          if (value) {
              CategoryApi.removeCategoryItem(this.$route.params.id, this.$route.params.catId, itemId)
              .then(response => {
                this.successNotification('CATEGORY DELETED!', 'Category successfully deleted!');
                this.getCategoryItems()
              })
          }
      })
  }

  enableDisableCategoryItem(enabled: Boolean, itemId: string) {
    let payload = {
      enabled: !enabled
    }
    console.log(payload)
    CategoryApi.enableCategoryItem(payload, this.$route.params.id, this.$route.params.catId, itemId)
      .then(response => {
        if (payload.enabled) {
          this.successNotification('ITEM UPDATED!', 'Item status successfully enabled!');
        } else {
          this.successNotification('ITEM UPDATED!', 'Item status successfully disabled!');
        }
        this.getCategoryItems()
      })
  }

  getCategoryItems() {
    CategoryApi.items(this.$route.params.id, this.$route.params.catId).then((response: any) => {
      this.items = response.data.data;
    })
  }

  onAddItem() {
    this.$router.push({
      name: "itemCreate"
    })
  }
}
