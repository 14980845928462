































































































import { Loyalty as LoyaltyModel } from "@/models";
import { Pagination } from "element-ui";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Loyalty as LoyaltyService} from "@/services/SOLO";
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
@Component({
  components: {
    [Pagination.name]: Pagination,
    flatPicker
  },
mixins: [translations],
})
export default class LoyaltyCollapse extends Vue {
  @Prop() loyaltyMeta!: Array<LoyaltyModel>;
  public translate!: Function
  name: string = "";
  type: string = "";
  from: string = "";
  to: string = "";
  nextPage: number = 0;
  hasMore: boolean = false;
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  totalPage: number = 0;
  isLoaded: boolean = false;
  options: Array<Object> = [
    {
      value: "",
      text: "Select Type",
    },
    {
      value: "redeem",
      text: "Redeem",
    },
    {
      value: "collect",
      text: "Collect",
    },
    {
      value: "cancel",
      text: "Cancel",
    },
  ];

  @Watch("loyaltyMeta", { deep: true })
  onLoyaltyMetaChanged(newVal: any) {
    this.isLoaded = true;
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }

  pageChange(data: any) {
    this.$emit("getLoyalties", data);
  }

  searchLoyalty(name: string, type: string, from: string, to: string) {
    this.$emit("searchLoyalties", name, type, from, to);
  }

  resetForm(data: any) {
      this.name = "";
      this.type = "";
      this.from = "";
      this.to = "";
      this.$emit("getLoyalties", data);
  }

}
