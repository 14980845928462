













import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import { translations } from '@/mixins'
@Component({
  mixins: [translations]
})
export default class NewAppHeader extends Vue {
  translate!: Function
}
