import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import translations from '@/locales/translations.json'

interface TlInterface {
  [x: string]: any
}

@Component({
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  }
})
export default class Translations extends Vue {
  public getLocale: any
  public translationsData: TlInterface = {}
  constructor() {
    super()
    this.translationsData = translations
  }

  private getText(key: string) {
    if(this.translationsData[this.getLocale]) {
      if(this.translationsData[this.getLocale][key]) {
        return this.translationsData[this.getLocale][key]
      }
    }    
    return key
  }
  public translate(key: string, strReplace: any = {}) {
    let text = this.getText(key)
    Object.keys(strReplace).forEach(str => {
      text = text.replace(str, strReplace[str])
    })
    // return text.charAt(0).toUpperCase() + text.slice(1)
    return !!text ? text.charAt(0).toUpperCase() + text.slice(1) : ''
  }

  public translateNumber(num: any): number {
    if(!num) {
        num = 0;
    }
    return num.toLocaleString(this.getLocale === 'en_US' ? 'en' : 'ar');
}
}
