












































































































































































































import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option, Form, FormItem } from "element-ui";
import { translations, eventHandler } from "@/mixins";
import StoreTimes from "@/views/Admin/pages/Locations/components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {
  Subscription as SubscriptionApi,
  Menu,
  Category,
  Promotion
} from "@/services/SOLO";
import { mixins } from "vue-class-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    Translations,
    StoreTimes,
    ValidationProvider
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY");
    }
  },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
      getLocale: "app/getLocale",
      getSubscription: "account/getSubscription"
    })
  },
  mixins: [translations, eventHandler]
})
export default class StarRatings extends Vue {}
