






























































































import Translations from './Translations.vue'
import { translations } from '@/mixins'
import MenuCategoryModal from "./MenuCategoryModal";
import { Category, Integrations, Menu as MenuApi } from "@/services/SOLO";
import { Category as CategoryModel } from "@/models";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import VueRouter from "vue-router";
import { eventHandler } from "@/mixins";

@Component({
  components: {
    RouteBreadCrumb,
    MenuCategoryModal,
    Translations
  },
  mixins: [eventHandler, translations],
})
export default class Menu extends Vue {
  open: Boolean = false;
  categories: Array<CategoryModel> = [];
  $notify: any;

  public translate!: Function;

  @Watch("menuId", { immediate: true, deep: true })
  onMenuIDchange(newVal: string, oldVal: string) {
    this.getCategory();
  }

  confirm!: Function;

  mounted() {
    this.getCategory();
  }

  get menuId(): string {
    return this.$route.params.id;
  }

  @Emit()
  @Watch("categories", { immediate: true, deep: true })
  onCategoriesChanged(newVal: Array<CategoryModel>) {
    return newVal;
  }

  get deleteMsgBoxText(): String {
    return "Are you sure you want to delete this Category?";
  }

  modalOpenState(open: Boolean = true) {
    this.open = open
  }

  removeCategory(categoryId: string) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
            Category.removeCategory(this.menuId, categoryId)
            .then(response => {
              this.getCategory()
            })
        }
      })
  }

  enableCategory(enabled: Boolean, categoryId: string) {
    let payload = {
      enabled: enabled,
    };
    MenuApi.update(payload, this.menuId, categoryId).then((response) => {
      this.getCategory();
    });
  }

  getCategory() {
    Category.all(this.menuId).then((response: any) => {
      this.categories = response.data.data;
    }).catch((err: any) => {
      console.log(err.response)
    });
  }

  async syncPos() {
    this.confirm(
      this.$bvModal,
      "You are going to synchronize with POS. Do you want to proceed?",
      {
        title: 'Attention!',
        okTitle: 'YES',
        cancelTitle: 'NO',
      },
    ).then(async (value: boolean) => {
      if (value) {
        try {
          const response = await Integrations.sync('pos', this.menuId);
          this.$notify({
            title: this.translate("MENU SYNCED!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate("Menu successfully synced!"),
            type: "success",
            icon: "fas fa-check",
          });
        } catch (error) {
          this.$notify({
            title: this.translate("SYSTEM ERROR!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate("Something went wrong, please try again!"),
            type: "danger",
            icon: "fas fa-bomb",
          });
        }

      }
    });
  }

  redirectToUpsells() {
    this.$router.push({
      name: "MenuUpsells"
    })
  }
}
