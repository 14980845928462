











































































import {translations} from '@/mixins'
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from '@/components';
import { mapGetters, mapMutations } from 'vuex'
import { Concept } from '@/interfaces/SOLO'
import { Sidebar } from '@/interfaces/Landing'
import { Concept as ConceptApi, Menu, Subscription } from '@/services/SOLO'
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Menus } from '@/models'
@Component({
  components: {
    CollapseTransition,
    BaseNav,
    Modal
  },
  computed: {
    ...mapGetters({
      getConcepts: 'account/getConcepts',
      activeConcept: 'account/activeConcept',
      getMenus: 'menu/getMenus',
      getUser: 'account/getUser',
      getLocale: 'app/getLocale',
      getLocales: 'app/getLocales',
      isLoggedIn: 'account/isLoggedIn',
      getConceptSettings: 'account/getConceptSettings',
      getUserRole: 'account/getUserRole',
      subscription: 'account/getSubscription',      
      paymentId: 'account/getSubscriptionPaymentId'
    })
  },
  methods: {
    ...mapMutations({
      setUserConcept: 'account/setUserConcept',
      setMenus: 'menu/setMenus',
      logoutUser: 'account/logoutUser',
      setLocale: 'app/setLocale',
      setConceptSettings: 'account/setConceptSettings',
      setSubscription: 'account/setSubscription',      
    })
  },
  mixins: [translations]
})
export default class DashboardNavbar extends Vue {
  activeNotifications: Boolean = false
  showMenu: Boolean = false
  searchModalVisible: Boolean = false
  searchQuery: string = ''
  isDisplay: Boolean = false;
  getUser: any
  getConcepts!: Array<Concept>
  getMenus!: Array<Menus>
  conceptIsVisible: boolean = false
  activeConcept!: Concept
  filterText: string = ''
  menuRoutes: Array<any> = ['menus', 'menu', 'updatecategory', 'items', 'itemlist', 'itemUpdate', 'itemModifier']

  @Prop() type!: string
  setUserConcept!: Function
  setMenus!: Function
  logoutUser!: Function
  setLocale!: Function
  setConceptSettings!: Function
  setSubscription!: Function  
  
  isLoggedIn: any
  getConceptSettings: any
  getUserRole: any
  subscription: any
  paymentId: any

  $sidebar!: Sidebar
  $root!: any

  parent!: any

  constructor() {
    super()
  }

  mounted () {
    this.parent = this.$parent.$parent    
    this.showLogout();
  
  }

  showLogout() {
    if (!this.isMobile()) {
      this.isDisplay = true;
    } else {
      this.isDisplay = false;
    }
  }


  get filtered(): Array<Concept> {
    if(this.filterText === '')
      return this.getConcepts
    else
      return this.getConcepts.filter((concept: Concept) => {
        return concept.label.toLowerCase().includes(this.filterText.toLowerCase())
      })
  }


  switchLang(locale: string) {
    this.setLocale(locale)

    window.location.reload()
  }

  showConcepts (allow: boolean) {
    this.$root.conceptIsVisible = allow
  }

  setDefaultConcept () {
    if(this.getConcepts.length && !this.activeConcept) {
      this.setUserConcept(this.getConcepts[0])
      this.getSubscription()
    }
  }

  setConcept (concept: Concept) {
    this.setUserConcept(concept)
    this.showConcepts(false)
    this.getSubscription()
  }

  private getSubscription() {
    Subscription.getSubscription().then(response => {      
      this.getApps(response.data.data)
    }).catch(err => {      
      this.getApps(null)
    })
  }

  private getAccountConcept(data: any) {
    ConceptApi.get(this.activeConcept.id).then(response => {
      this.setSubscription(data)
      this.setConceptSettings(response.data.data)
      window.location.reload()
    })
  }

  getApps (data: any) {
    if(this.activeConcept) {
      this.$root.isLoading = true
      Menu.all().then((response: any) => {
        this.setMenus(response.data.data)
        this.$root.isLoading = false
        if(this.menuRoutes.includes(this.$route.name) && this.$route.path != this.parent.$refs.menus[0].link.path) {
          this.$router.push({ path: this.parent.$refs.menus[0].link.path })
        }
        this.getAccountConcept(data)    
      }).catch((err: any) => {
        console.log(err)
      })
    }
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  toggleNotificationDropDown() {
    this.activeNotifications = !this.activeNotifications;
  }
  closeDropDown() {
    this.activeNotifications = false;
  }
  toggleSidebar() {
    this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
  }
  hideSidebar() {
    this.$sidebar.displaySidebar(false);
  }

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.logoutUser()
    this.$router.go(0)
  }
}
