
















































import moment from 'moment'
import BlacklistSingleModal from './BlacklistSingleModal'
import { Customers } from "@/models"
import { Table, TableColumn, Button } from "element-ui"
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Customer} from "@/services/SOLO";
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    BlacklistSingleModal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  filters: {
      standardDateFormat(value: any)  {
        if (! value) return '---';
        return moment(value).format('DD/MM/YYYY hh:mm:ss');
      }
  },
mixins: [translations],
})

export default class BlacklistList extends Vue {
  id: any
  blacklists: any = []
  color: string = 'white'
  page: number = 1
  loading: boolean = false
  openEdit: boolean = false
  singleBlacklist: any = {}
  blacklistWrapper: any = ''
  public translate!: Function
  mounted() {
    this.getBlacklists(this.page);
  }

  getBlacklists(page: number) {
    this.loading = true
    Customer.getBlacklists(page).then((response: any) => {
      this.blacklists =response.data.data
      this.loading = false
    })
  }

  handleEdit(id: any) {
    this.id = id
    Customer.showBlacklist(this.id)
        .then((response: any) => {
          this.singleBlacklist = response.data.data
        })
  }

  handleClose(value: any) {
    this.openEdit = value
  }
}
