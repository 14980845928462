import * as Ably from 'ably';
import {Howl, Howler} from 'howler';

let eventHandler = {  
  methods: {
    waiting(e: any, loadingString: string) {
      e.target.innerHTML = loadingString
      e.target.disabled = true      
    },
    btnLoading(e: any, loadingString: string) {
      console.log(e.target)
      e.target.innerHTML = loadingString
      e.target.disabled = true
    },
    restore(e: any, loadingString: string) {
      e.target.innerHTML = loadingString
      e.target.disabled = false
    },
    btnRestore(e: any, loadingString: string) {
      e.target.innerHTML = loadingString
      e.target.disabled = false
    },
    confirm(modal: any, msg: string, config: Object = {}) {
      return modal.msgBoxConfirm(msg, {...this.modalConfig(), ...config})      
    },
    modalConfig() {
      return {
        centered: true,
        title: 'Please Confirm'      
      }
    },
    broadcasted(conceptId: string) {
      let options : Ably.Types.ClientOptions = { key: 'c8GwjQ.bc7afQ:8y41_MzTKIM5CpDa' };
      let client = new Ably.Realtime(options); 
      return client.channels.get('solo-' + conceptId)
    },
    notif(sound: string) {
      var audio = new Audio();
      audio.src = sound
      return audio
    },
  }
}

export default eventHandler

