import BaseService from '../base.service'

export default class ApplicationsService extends BaseService{
  url!: string
  constructor(){
    super();
  }

  getAllApplications() {
      this.url = this.baseURL + 'applications'
      return super.get(this.url)
  }

  getApplication(id: number) {
      this.url = this.baseURL + 'applications/'+id
      return super.get(this.url)
  }

  createApplication(payload: Object = {}) {
      this.url = this.baseURL + 'applications/'
      return super.post(this.url, payload)
  }

  updateApplication(id: number, payload: Object = {}) {
      this.url = this.baseURL + 'applications/'+ id
      return super.put(this.url, payload)
  }
  updateApplicationTheme(id: number, payload: Object = {}) {
      this.url = this.baseURL + 'applications/'+ id +'/themes'
      return super.put(this.url, payload)
  }

  getClient(url: string) {
      this.url = this.baseURL + 'clients?filter[url]='+ url
      return super.get(this.url)
  }

  validateHost(url: string) {
      this.url = this.baseURL + 'application-validation/'+ url
      return super.get(this.url)
  }

}
