



















































































































































































import AppConceptLogo from './components/AppConceptLogo'
import {Select, Option} from "element-ui";
import {Component, Vue} from "vue-property-decorator";
import {SettingsConcepts, Applications, Menu, Slider, Integrations} from "@/services/SOLO";
import {translations, notificationAlerts} from "@/mixins";
import {mapGetters} from 'vuex'

interface AppTheme {
  primary_color?: any
  secondary_color?: any
}
interface CreateAppForm {
  application_type?: string
  label?: string
  default_menu_id?: number | null
  hosts?: string
  slider_id?: number | null
  'menu-only'?: number | boolean
  'payment-gateway'?: string | any
  'allowed-order-types'?: string | any
  'allowed-payments-pickup'?: string | any
  'allowed-payments-delivery'?: string | any
  theme?: AppTheme
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    AppConceptLogo
  },
  computed: {
    ...mapGetters({
      getConceptSettings: "account/getConceptSettings",
    }),
  },
  mixins: [translations, notificationAlerts],
})
export default class CreateApp extends Vue {
  getConceptSettings!: Array<typeof mapGetters>
  menus: any = [];
  sliders: any = [];
  loadingMenu: Boolean = false
  form: CreateAppForm = {
    application_type: "",
    label: "",
    default_menu_id: null,
    hosts: "",
    slider_id: null,
    'menu-only': 0 || false,
    'payment-gateway': '',
    'allowed-order-types': ['deliver'],
    'allowed-payments-pickup': ['cash'],
    'allowed-payments-delivery': ['cash'],
    theme: {
      primary_color: '#000000',
      secondary_color: '#FFFFFF'
    }
  }
  payment_gateway: any = []
  $notify: any;
  selectedMenu: any = '';
  selectedSlider: any = '';

  saving: boolean = false;
  currentType: string = '';
  reseller_app_type: any= {}

  freeMenuOption: any = [
    {text: 'solo.menu', value: 'solo-menu'}
  ]

  translate!: Function;
  successNotification!: Function;
  systemErrorNotification!: Function;
  getPackageType: any = {}
  isFreePackage: any = {}

  mounted() {
    this.getPackageType = this.getConceptSettings
    this.isFreePackage = this.getPackageType.attributes['is-free-package']
    this.form.application_type = this.isFreePackage ? 'solo-menu' : ''
    this.form['menu-only'] = !!this.isFreePackage
    this.getIntegratedPayments()
    this.getMenus()
    this.getSliders()
    this.getAppTypes()
  }

  async getIntegratedPayments() {
    await Integrations.getAllIntegrations()
        .then((response: any) => {
          this.payment_gateway = response.data.data.filter((payment: any) => payment.attributes.type === 'payment')
          this.form['payment-gateway'] = this.payment_gateway[0].attributes.provider
        }).catch((err: any) => {})
  }

  validateDomain(data: any) {
    let domain = data.target.value
    if (domain.length >= 3) {
      domain = domain + '.ordernosh.com'
      Applications.validateHost(domain).then((response: any) => {
        if (Object.keys(response.data).length !== 0) {
          this.$notify({
            title: "System Error",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: 'Your desired domain name is already in use.',
            type: "danger",
            icon: "fas fa-bomb",
          })
        }
      })
    }
  }

  getAppTypes() {
    // Applications.getClient(window.location.host).then((response: any) => {
    Applications.getClient('app.getsolo.io').then((response: any) => {
      this.reseller_app_type = Object.values(response.data.data.attributes).filter((k:any) => {
        return typeof k === 'object' && k !== null
      })
      this.reseller_app_type.shift() // remove first value of array
    }).catch((err: any) => {})
  }

  updateSelectedMenu(e: any) {
    this.form.default_menu_id = Number(e);
  }

  updateSelectedSlider(e: any) {
    this.form.slider_id = Number(e);
  }

  async getMenus() {
    this.loadingMenu = true
    await Menu.all().then((response: any) => {
      this.menus = response.data.data;
      this.loadingMenu = false
    });
    this.selectedMenu = this.menus[0]
    this.form.default_menu_id = this.selectedMenu.id
  }

  getSliders() {
    Slider.all().then((response: any) => {
      this.sliders = response.data.data;
    });
  }

  selectedAppType(e: any) {
    this.form.application_type = e;
  }

  async createApp() {

    if (this.saving) {
      return
    }

    this.saving = true

    let host = this.form.hosts
    if (this.form.application_type === "solo.web") {
      host = this.form.hosts + '.ordernosh.com'
      this.form.hosts = host
      this.form.application_type = 'social-ordering'
    }

    if (this.form.application_type === 'solo.app') {
      this.form.application_type = 'mobile'
    }

    await Applications.createApplication(this.form).then((response: any) => {

      this.$notify({
        title: "RECORDS SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "New application created successfully.",
        type: "success",
        icon: "fas fa-check",
      })

      this.saving = false
      this.$router.back()

    }).catch((err: any) => {
      let errMsg: any = {}
      for (let error in err.response.data.errors) {
        errMsg = err.response.data.errors[error]
        this.$notify({
          title: "System Error",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: errMsg[0],
          type: "danger",
          icon: "fas fa-bomb",
        })
      }
      this.saving = false
      this.form.application_type = 'solo.web'
      this.form["hosts"] = ''
    })

  }

  uploadLogoConcept(data: any) {
    let formData = new FormData()
    formData.append("logo-image", data)
    SettingsConcepts.update(this.getPackageType.id, formData)
  }

  resetForm() {
    this.form = {};
    this.selectedMenu = "";
    this.selectedSlider = "";
    return;
  }
}
