


















  import { BaseNav } from '@/components';
  import { ZoomCenterTransition } from 'vue2-transitions';

  import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
  import { mapGetters, mapMutations } from 'vuex';
import { Auth } from '@/services/core';

  @Component({
    components: {
      BaseNav,
      ZoomCenterTransition
    },
    computed: {
      ...mapGetters({
        getUser: 'account/getUser',
        isLoggedIn: 'account/isLoggedIn',
        getUserClient: 'account/getUserClient'
      })
    },
    methods: {
      ...mapMutations({
        setUserClient: 'account/setUserClient'
      })
    }
  })
  export default class Home extends Vue {
    private getUser: any
    private isLoggedIn: any
    @Prop({ default: 'black' }) readonly backgroundColor!: String
    private showMenu: boolean = false
    private menuTransitionDuration: number = 250
    private pageTransitionDuration: number = 200
    private year: number = new Date().getFullYear()
    private pageClass: string = 'login-page'
    private setUserClient!: Function
    // @Watch('isLoggedIn', { immediate: true, deep: true })
    // onGetUserChange() {
    //   if(this.isLoggedIn) {
    //     if(this.$route.name === 'register') {
    //       this.$router.push({name: 'subscription'})
    //     }else {
    //       this.$router.push({name: 'dashboard'})
    //     }
    //   }
    // }
    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(newVal: any, oldVal: any) {
      this.updateBackground()
    }

    mounted() {
      this.getClient()
      window.onpopstate = (event: any) => {
        this.updateBackground()
      }
    }

    get title(): string {
      return `${this.$route.name} Page`;
    }
    
    getClient() {
      Auth.getClient()
        .then((response) => {
          this.setUserClient(response.data.data)          
        })
    }
    
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    }
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    }
    setBackgroundColor() {
      document.body.classList.add('bg-default');
    }
    removeBackgroundColor() {
      document.body.classList.remove('bg-default');
    }
    updateBackground() {
      console.log('this.$route.meta.noBodyBackground: ', this.$route.meta);
      if (!this.$route.meta.noBodyBackground) {
        if (this.$route.meta.hideFooter && this.$route.meta.middleware) {
          this.removeBackgroundColor()
          document.body.classList.remove('bg-solo');
        } else {
          this.setBackgroundColor();
          document.body.classList.remove('bg-solo');
        }
      } else {
        this.removeBackgroundColor()
        if (this.$route.meta.isLoginPage) {
          document.body.classList.add('bg-solo');
        }
      }
    }

    beforeDestroy() {
      this.removeBackgroundColor();
    }

    beforeRouteUpdate(to: any, from: any, next: () => void) {
      // Close the mobile menu first then transition to next page
      if (this.showMenu) {
        this.closeMenu();
        setTimeout(() => {
          next();
        }, this.menuTransitionDuration);
      } else {
        next();
      }
    }
  }
