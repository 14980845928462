import BaseService from '../base.service'

export default class LoyaltyService extends BaseService {
  url!: string
  constructor() {
    super();
  }


  search(name: string, type: string, from: string, to: string) {
    this.url = this.baseURL + `loyalty-points?filter[customer]=${name}&filter[type]=${type}&filter[from]=${from}&filter[to]=${to}`;  
    return super.get(this.url);
  }


  all(page = 1) {
    let url = this.baseURL + 'loyalty-points';

    return super.get(url, {
      page: page
    });
  }

}
