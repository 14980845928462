














































import AddCustomFieldModal from './AddCustomFieldModal.vue'
import EditModgroupItem from './EditModgroupItem.vue'
import { Settings } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler } from '@/mixins'
import VueRouter from 'vue-router';
@Component({
  components: {
    EditModgroupItem,
    AddCustomFieldModal
  },
  mixins: [eventHandler]
})
export default class ModGroup extends Vue {

  private open: Boolean = false
  private openCustomField: Boolean = false
  private modifierGroupId: string = ''
  private customFields: Array<any> = []
  private selectedFields: Array<any> = []
  private itemId: string = ''
  private customFieldId: string = ''
  private customFieldsValue: string = ''
  @Prop() items!: Array<Object>

  btnLoading!: Function
  btnRestore!: Function
  confirm!: Function
  $notify: any
  $refs!: {
    itemModal: any
  }

  enableMsgBoxText(enabled: number) {
    return enabled ? 'Proceed enabling this item?' : 'Proceed disabling this item?'
  }

  get deleteMsgBoxText(): String {
    return 'Are you sure you want to delete this item?'
  }

  private refreshCustomFields() {
    this.$refs.itemModal.refreshSelectedCustomFields()
  }

  private showCustomFieldModal(customFields: any, selectedFields: any, itemId: string, customFieldId: string = '', value: string = '') {
    this.customFields = customFields
    this.selectedFields = selectedFields
    this.itemId = itemId
    this.customFieldId = customFieldId
    this.customFieldsValue = value
    this.updateCustomFieldModal(true)
  }

  private updateCustomFieldModal(open: Boolean) {
    this.openCustomField = open
  }

  updateModgroupItem(open: Boolean = true, id: string = '') {
    this.open = open
    this.modifierGroupId = id
  }

  remove(id: string, enabled: number) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
          Settings.deleteItemModifier(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
          .then((response: any) => {
            this.$emit('modifier:update')
            this.$notify({
              title: "DATA DELETED",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: 'Modifier successfully deleted',
              type: "success",
              icon: "fas fa-trash",
            })
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          })
        }
      })
  }

  enable(id: string, enabled: number) {

    Settings.enableItemModifier({enabled: enabled ? 1 : 0}, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
    .then((response: any) => {
      this.$emit('modifier:update')
      this.$notify({
        title: "DATA UPDATED",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: enabled ? 'Modifier Group Enabled!' : 'Modifier Group Disabled',
        type: "success",
        icon: "fas fa-check",
      })
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    })
    // this.confirm(this.$bvModal, this.enableMsgBoxText(enabled))
    //   .then((value: boolean) => {
    //     if(value) {
    //       Settings.enableItemModifier({enabled: enabled ? 1 : 0}, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
    //       .then((response: any) => {
    //         this.$emit('modifier:update')
    //         this.$notify({
    //           title: "DATA SAVED",
    //           verticalAlign: "bottom",
    //           horizontalAlign: "left",
    //           message: enabled ? 'Modifier enabled!' : 'Modifier disabled',
    //           type: "success",
    //           icon: "fas fa-check",
    //         })
    //       })
    //       .catch((err: any) => {
    //         this.$notify({
    //           title: "SYSTEM ERROR!",
    //           verticalAlign: "bottom",
    //           horizontalAlign: "left",
    //           message: "Something went wrong, please try again!",
    //           type: "danger",
    //           icon: "fas fa-bomb",
    //         });
    //       })
    //     }
    //   })

  }
}
