















































import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler, translations } from '@/mixins'
import { Menu as MenuApi } from '@/services/SOLO'
@Component({
  components: {
    
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModifiers extends Vue {
  private items: Array<any> = []

  mounted() {
    this.getModifierItem()
  }

  private enableDisable(id: string, enabled: any) {
    let payload = {
      enabled: enabled
    }
    MenuApi.updateModifierItem(payload, this.$route.params.modifierId, id)
      .then((response: any) => {
        this.getModifierItem()
      })
  }

  private exclude(id: string, isExclude: Boolean) {
    let payload: Object = {
      'modifier-id': id
    }
    if(!isExclude) {
      MenuApi.exludeModifierItem(payload, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, this.$route.params.modifierId)
        .then((response: any) => {
          this.getModifierItem()
        })
    }else {
      MenuApi.includeModifierItem(payload, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, this.$route.params.modifierId, id)
        .then((response: any) => {
          this.getModifierItem()
        })
    }    
  }

  private remove(id: string) {
    MenuApi.removeModifierItem(id)
      .then((response: any) => {
        this.getModifierItem()
      })
  }

  private getModifierItem() {
    MenuApi.getModifierItem(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, this.$route.params.modifierId)
      .then((response: any) => {
        this.items = response.data.data
      })
  }
}
