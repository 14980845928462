











import moment from 'moment'
import { Loyalty as LoyaltyModel } from "@/models"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue'
import {Component, Vue} from "vue-property-decorator"
import LoyaltyCollapse from "./LoyaltyCollapse.vue"
import LoyaltyList from "./LoyaltyList.vue"
import { Loyalty as LoyaltyService } from '@/services/SOLO'

@Component({
  components: {
    RouteBreadCrumb,
    LoyaltyList,
    LoyaltyCollapse
  }
})
export default class LoyaltyItem extends Vue {
  loyalties: Array<LoyaltyModel> = []
  loyaltyMeta: any = {}
  targetKeyword: string = ''
  isLoaded: boolean = false
  page: number = 1
  loading: boolean = false

  mounted() {
    this.getLoyalties(this.page);
  }

  async getLoyalties(page: number) {
    this.loading = true
    await LoyaltyService.all(page)
      .then((response: any) => {
         let filter = response.data.data
        this.loyalties = response.data.data
        this.loyaltyMeta = response.data.meta.pagination

        filter.map((c: any) => {
          let formatted = c.attributes
            formatted['fullname'] = c.attributes['customer-first-name'] +' '+ c.attributes['customer-last-name']
          
          return formatted
        })


        console.log(response.data.data);
        this.loading = false
      })
  }

  async searchLoyalties(name: string, type: string, from: string, to: string) {
    this.loading = true
    await LoyaltyService.search(name, type, from, to).then((response: any) => {
      this.loyalties = response.data.data;
      console.log(this.loyalties); 
      this.loading = false
    });

  }
}
