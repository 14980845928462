import BaseService from '../base.service'

let couponService = class CouponService extends BaseService {

  constructor() {
    super();
  }

  all(page = 1){
    const url = this.baseURL + 'coupons';

    return super.get(url, {
      page: page
    });
  }

  allCouponsGroup(page = 1) {
    const url = this.baseURL + 'coupon-groups';

    return super.get(url, {
      page: page,
      include: 'coupon',
    });
  }

  allDigitalCoupons(page = 1) {
    const url = this.baseURL + 'admin/digital-coupons';

    return super.get(url, {
      page: page,
      include: 'promotion',
    });
  }

  createCouponsGroup(payload: any) {
    const url = this.baseURL + 'coupon-groups';

    return super.post(url, payload);
  }

  deleteCouponsGroup(id: string) {
    const url = `${this.baseURL}coupon-groups/${id}`;

    return super.remove(url, {});
  }

  createCoupon(payload: any, groupId: any) {
    const url = this.baseURL + `coupon-groups/${groupId}/coupons`;

    return super.post(url, payload);
  }

  createDigitalCoupon(payload: any) {
    const url = this.baseURL + `admin/digital-coupons`;

    return super.post(url, payload);
  }

  getCoupon(groupId: any, couponId: any) {
    const url = this.baseURL + `coupon-groups/${groupId}/coupons/${couponId}`;

    return super.get(url, {
      include: 'promotion,customer',
    });
  }

  getDigitalCoupon(couponId: any) {
    const url = this.baseURL + `admin/digital-coupons/${couponId}`;

    return super.get(url, {
      include: 'promotion,customer',
    });
  }

  updateCoupon(payload: any, groupId: any, couponId: any) {
    const url = this.baseURL + `coupon-groups/${groupId}/coupons/${couponId}`;

    return super.put(url, payload);
  }

  updateDigitalCoupon(payload: any, couponId: any) {
    const url = this.baseURL + `admin/digital-coupons/${couponId}`;

    return super.put(url, payload);
  }

  getCouponsbyCouponGroupId(couponGroupId: string, page = 1) {
    const url = this.baseURL + `coupon-groups/${couponGroupId}/coupons`;

    return super.get(url, {
      page: page,
      include: 'promotion'
    });
  }
}

export default couponService
