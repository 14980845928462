


















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CriteriaInput from "./CriteriaInput";

@Component({
  components: {
      CriteriaInput,
  }
})
export default class Criteria extends Vue {
    @Prop() criteriaList!: any;
    @Prop() criterion!: any;
    @Prop() id!: number;
    @Prop() condition!: string;
    @Prop() index!: number;

    @Watch('criterion.provider', {immediate: true, deep: true})
    handler(provider: any) {
        this.criterion.value = null;
        this.setConditionList(provider);
        this.setChoices(provider);
    }

    html_type = this.criterion.html_type;
    choices = this.criterion.choices;
    conditionsList: any = [];
  created() {
      console.log('criteriaList: ', this.criteriaList);
    this.setConditionList(this.criterion.provider);
    this.setChoices(this.criterion.provider);
    this.setHtmlType(this.criterion.provider);
  }
setConditionList(provider: any) {
      this.conditionsList = this.getByProvider(provider).conditions;
      /* tslint:disable-next-line */      
      if (!this.conditionsList.includes(this.criterion.condition)) {
        this.criterion.condition = this.conditionsList[0];
      }
    }
    setChoices(provider: any) {
      let criteria = this.getByProvider(provider);
      this.criterion.choices = criteria.choices.map((choice: any) => {
        return {
          id: choice.id,
          text: choice.text || choice.name,
        };
      });
      this.criterion.html_type = criteria.html_type;
    }
    setHtmlType(provider: any) {
      let criteria = this.getByProvider(provider);
      this.criterion.html_type = criteria.html_type;
    }
    getByProvider(provider: any) {
      return this.criteriaList.find(
        (criterion: any) => criterion.provider === provider
      );
    }
    updateCriterionValue(val: any) {
      this.$emit('criterion-val', val);
    }
};
