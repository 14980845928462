















import ModgroupModal from './ModgroupModal.vue'
import ModgroupItems from './ModgroupItems.vue'
import { Settings } from '@/services/SOLO';
import { translations } from '@/mixins'
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
@Component({
  components: {
    ModgroupModal,
    ModgroupItems
  },
  mixins: [translations]
})
export default class ModGroup extends Vue {
  modifierGroups: Array<Object> = []
  modifierGroupItems: Array<Object> = []
  $router: any
  $refs!: {
    modgroupModal: ModgroupModal
  }
  translate!: Function 

  mounted() {    
    this.getModifierGroups()
    this.getItemModifiers()
  }

  openModal() {
    this.$refs.modgroupModal.open = true
  }

  getModifierGroups() {
    Settings.modifierGroups().then((response: any) => {
      this.modifierGroups = response.data.data                  
    })
  }

  getItemModifiers() {
    Settings.itemModifiers(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId).then((response: any) => {
      this.modifierGroupItems = response.data.data
    })
  }
}
