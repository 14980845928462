



























































































import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({

  mixins: [translations],
})
export default class PaymentDetails extends Vue {
  @Prop() isLoaded!: Boolean
  @Prop() payment!: Object  
  @Prop() order!: Object  
  public translate!: Function
  mounted() {
    console.log(this.order)
  }
}
