





























import moment from 'moment'
import {Table, TableColumn, Button, Form, FormItem, Select, Option} from 'element-ui'
import {Component, Watch, Prop, Vue} from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
mixins: [translations],
})
export default class BlacklistSingleModal extends Vue {
  @Prop() blacklistSingle?: Object
  public translate!: Function
  isOpen: boolean = false
  form: any = {
    attributes: {
      value: '',
      type: '',
      'created-at': '',
      'updated-at': ''
    }
  }

  standardDateFormat(value: any)  {
    if (! value) return '---';
    return moment(value).format('DD/MM/YYYY');
  }

  @Watch('openEdit', {deep: true})
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }
  @Watch('blacklistSingle', {deep: true})
  onBlacklistSingleChanged(newVal: any) {
    this.form = newVal
    // console.log(this.form.attributes['created-at'])
    // console.log(this.form.attributes['updated-at'])
    // this.form.attributes['created-at'] = this.standardDateFormat(newVal.attributes['created-at'])
    // this.form.attributes['updated-at'] = this.standardDateFormat(newVal.attributes['updated-at'])
    this.isOpen = true
  }

  handleClose() {
    this.isOpen = false
  }
}
