
































import moment from 'moment'
import { Table, TableColumn, Button } from "element-ui"
import { CustomerOrders as Orders } from "@/models"
import {Component, Prop, Watch, Vue} from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './Translations.vue'

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  filters: {
      standardDateFormat(value: any)  {
        if (! value) return '---';
        return moment(value).format('DD/MM/YYYY hh:mm:ss');
      }
  },
  mixins: [translations],
})
export default class CustomerOrders extends Vue {
  @Prop() orders!: Orders
  someOrders: any = []
  orderCount: number = 0
  public translate!: Function
  @Watch('orders', {deep: true})
  oncustomerOrdersChanged(newVal: any) {
    this.orders = newVal
    this.someOrders = this.orders
    this.orderCount = this.someOrders.length
  }
}
