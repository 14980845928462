import BaseService from '../base.service'

export default class SettingsFacilitiesService extends BaseService{
  url!: string
  constructor(){
    super();        
  }

  all(lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + 'facilities'
    return super.get(this.url)
  }

  find(id: string, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + 'facilities/' + id
    return super.get(this.url, {}, {})
  }

  create(payload: object, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + 'facilities'
    return super.post(this.url, payload)
  }

  update(id: string, payload: any = {}, lang: string = 'en-us') {    
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `facilities/${id}`    
    payload.append('_method', 'patch')
    return this.post(this.url, payload)
  }

  destroy(id: String, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `facilities/${id}`    
    return super.remove(this.url, {})
  }

}
