






































































import { User } from "@/interfaces/Landing";
import { Auth, FoodicsAuth } from "@/services/core";
import { Concept as ConceptApi, Menu, SettingsConcepts } from '@/services/SOLO'
import { ValidationObserver, configure } from "vee-validate";
import { mapGetters, mapMutations } from "vuex";
import { eventHandler, translations } from "@/mixins";
import WelcomeModal from "./components/WelcomeModal.vue";
import PermissionModal from "./components/PermissionModal.vue";
import {
  Component,
  Prop,
  Vue,
  Watch,
  Ref,
  Mixins,
} from "vue-property-decorator";
import authService from "@/services/core/auth.service";
import { Subscription } from "@/services/SOLO";
import { Concept } from "@/models";

@Component({
  components: { WelcomeModal, PermissionModal },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
      getUserRole: "account/getUserRole",
      isLoggedIn: "account/isLoggedIn",
      activeConcept: 'account/activeConcept',
      activeLanguage: 'account/activeLanguage',
      getUserClient: 'account/getUserClient'
    }),
  },
  methods: {
    ...mapMutations({
      setUser: "account/setUser",
      setUserRole: "account/setUserRole",
      setSubscription: 'account/setSubscription',
      setConceptSettings: 'account/setConceptSettings',
      setUserConcept: 'account/setUserConcept',
      setUserLanguage: 'account/setUserLanguage',
      setMenus: 'menu/setMenus',
    }),
  },
  mixins: [eventHandler, translations],
})
export default class Home extends Vue {
  private getUserClient: any
  activeConcept!: Concept
  activeLanguage!: Concept
  setUser!: Function;
  setUserRole!: Function;
  setMenus!: Function
  getUser!: typeof mapGetters;
  getUserRole!: typeof mapGetters;
  notify: boolean = false;  
  model: User = {
    username: "",
    password: "",
    rememberMe: false,
  };
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    welcomeModal: WelcomeModal;
    permissionModal: PermissionModal;
  };
  waiting!: Function;
  restore!: Function;
  setSubscription!: Function
  setConceptSettings!: Function
  setUserConcept!: Function
  setUserLanguage!: Function
  isLoggedIn!: Function
  constructor() {
    super();
  }

  mounted() {
    if(this.isLoggedIn) {
      this.$router.push({name: 'dashboard'})
    } 
  }

  private get logo(): string {
    if(this.getUserClient) {
      return this.getUserClient.attributes['logo-uri']
    }
    return ``
  }

  private get hasFoodicsRegister(): Boolean {
    if(this.getUserClient) {
      if(this.getUserClient.attributes.auth.split(',').includes('foodics')) {
        return true
      }
      return false
    }
    return false
  }

  private get hasSoloRegister(): Boolean {
    if(this.getUserClient) {
      if(this.getUserClient.attributes.auth.split(',').includes('solo-register')) {
        return true
      }
      return false
    }
    return false
  }

  async created() {
    let code : string = this.$route.query.code as string;
    if (code) {
      try {
        let response = await FoodicsAuth.getToken(code);
        this.$refs.welcomeModal.notify = true;
        this.$refs.welcomeModal.success = true;
      } catch (error) {
        console.log(error);
        this.$refs.welcomeModal.notify = true;
        this.$refs.welcomeModal.success = false;
      }
    }
  }  

  async authenticate (e: any) {
    const defaultText = e.target.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Signing in')
    Auth.login(this.model).then((response: any) => {        
        this.getSubscriptionFn(response.data.data, response.data.data.attributes.roles) 
        this.getConcepts();     
        this.restore(e, defaultText)
    }).catch((err: any) => {
      this.$refs.welcomeModal.notify = true
      this.$refs.welcomeModal.success = false
      this.restore(e, defaultText)
    })
  }

  getConcepts() {
      SettingsConcepts.get().then((response: any) => {
        this.getLanguages(response.data.data);
      }).catch((err: any) => {
        console.log(err)
      })
  }

  private getLanguages(data: any) {
      this.setUserLanguage(data.attributes.languages[1]) 
  }

  getApps () {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)      
    }).catch((err: any) => {
      console.log(err)
    })
  }

  private getAccountConcept(data: any, roles: any, subscription: any) {
    ConceptApi.get(this.activeConcept.id).then(response => {                                   
      console.log(response.data.data)
      this.setSubscription(subscription)    
      this.setConceptSettings(response.data.data)  
      this.getApps()
      this.$refs.welcomeModal.notify = true
      this.$refs.welcomeModal.success = true    
    })
  }

  private getSubscriptionFn(data: any, roles: any) {
      this.setUserConcept(data.attributes.concepts[0])   
      this.setUser(data)
      this.setUserRole(roles)
      Subscription.getSubscription().then(response => {   
        this.getAccountConcept(data,roles, response.data.data)                
      }).catch(err => {
        this.getAccountConcept(data,roles, null)                
        this.$refs.welcomeModal.notify = true
        this.$refs.welcomeModal.success = true    
      })
    }

  signInWithFoodics() {
    // window.location.href = 'https://app.getsolo.io/register/foodics';
    FoodicsAuth.redirect();
  }
}
