




































import {Select, Option} from "element-ui";
import {mapGetters} from "vuex";
import {Order as OrderApi} from "@/services/SOLO";
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters({
      getCurrentLocale: 'app/getLocale',
      // activeLanguage: 'account/activeLanguage',
    })
  },
})
export default class OrderStatus extends Vue {
  getCurrentLocale!: Array<typeof mapGetters>
  code: string = ''
  description: string = ''
  updateStatus: any = ''
  convertStatuses: any = ''
  currentLocale: any = ''
  @Prop() orderId!: string;
  @Prop() statuses!: Array<Object>;
  @Prop() orderStatusCode!: string;
  @Prop() orderType!: string;

  $notify: any;

  mounted() {
    this.description = this.orderStatusCode;
    this.filterStatuses()
    this.convertLocale()
  }

  @Watch("orderStatusCode", { deep: true })
  onStatusesCodeChanged(newVal: any) {
    console.log(newVal)
    // this.description = newVal
  }

  convertLocale() {
    this.currentLocale = this.getCurrentLocale
    this.currentLocale = this.currentLocale
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x: any) => x.toLowerCase())
        .join('-')

  }

  filterStatuses() {
    let someStatuses
    if (this.orderType !== 'deliver') {
      someStatuses = this.statuses.filter((status:any) => !['dispatched', 'delivery-in-progress', 'arrived-at-customer'].includes(status.attributes.code))

      this.updateStatus = someStatuses
    } else {
      this.updateStatus = this.statuses
    }
  }

  updateOrderStatus(data: any) {

    let selectedCode = {}
    this.description = data

    this.updateStatus.filter((code: any) => {
      if (code.attributes.description === this.description) {
        selectedCode = code.attributes.code
      }
    })

    let payload = {
        "order-status": selectedCode
    }

    OrderApi.updateStatus(payload, this.orderId)
        .then((response: any) => {
          this.$notify({
            title: "STATUS CHANGED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Status have been successfully changed",
            type: "success",
            icon: "fas fa-check",
          });
        })
        .catch((err: any) => {
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Something went wrong, please try again!",
            type: "danger",
            icon: "fas fa-bomb",
          });
        });
  }

  convertToKebabCase(string: any) {
    return string.replace(/\s+/g, '-').toLowerCase();
  }
}
