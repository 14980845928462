




































































































































































































import { Select, Option } from "element-ui";
import { Menu as MenuApi, Location, Concept, Category as CategoryApi } from "@/services/SOLO";
import {
  Category as CategoryModel,
  Location as LocationModel,
  Application as ApplicationModel,
  ItemAttributes as ItemAttributesModel,
  CatAttributes as CatAttributesModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";

interface objKey {
  [x: string]: string | number;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [eventHandler],
})
export default class ItemUpdate extends Vue {
  items: Array<any> = [{
      lang: "en-us",
  },
  {
      lang: "ar-sa"
  }];
  selectedLocations: any = [];
  selectedApplications: any = [];
  defaultLocations: Array<LocationModel> = [];
  defaultApplications: Array<ApplicationModel> = [];
  locations: Array<LocationModel> = [];
  applications: Array<any> = [];
  arrayLang: Array<string> = ["en-us", "ar-sa"];
  itemNames: objKey = {};
  itemDescs: objKey = {};
  imageType: number = 0;
  $notify: any;
  numOfLoadedData: number = 0;
  file: any;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ];
  waiting!: Function;
  restore!: Function;

  catItem: ItemAttributesModel = {
    name: {},
    description: {},
    code: '',        
    price: '',
    "display-order": 1,    
    calories: '',
    'person-count-per-serving': 1,
    'calorie-count': '',
    'image-uri': '',
    enabled: 1,
    'disable-for-pickup': 0,
    'disable-for-delivery': 0
  }

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {

  }

  mounted() {
    this.getApps();
    this.getLocations();
  }
  
  get categoryItemData(): CatAttributesModel {
    return this.catItem
  }

  get categoryItemForm(): FormData {
    let formData = new FormData()
    for(let i in this.arrayLang) {
      formData.append(`name[${this.arrayLang[i]}]`, this.catItem.name[this.arrayLang[i]])
      formData.append(`description[${this.arrayLang[i]}]`, this.catItem.description[this.arrayLang[i]])
    }          
    formData.append('code', this.catItem.code)
    formData.append('price', this.catItem.price)
    formData.append('display-order', this.catItem['display-order'].toString())
    formData.append('calories', this.catItem.calories)
    formData.append('person-count-per-serving', this.catItem['person-count-per-serving'].toString())
    formData.append('calorie-count', this.catItem['calorie-count'])
    formData.append('enabled', this.catItem.enabled.toString()) 
    formData.append('disable-for-pickup', this.catItem['disable-for-pickup'].toString())     
    formData.append('disable-for-delivery', this.catItem['disable-for-delivery'].toString())    
    formData.append('image', this.$refs.file.files[0])
    return formData
  }
  
  get isDisabledForPickup(): Boolean {
    return this.catItem['disable-for-pickup'] === 1 ? true : false;
  }

  set isDisabledForPickup(newVal: Boolean) {
    this.catItem['disable-for-pickup'] = newVal ? 1 : 0
  }

  get isDisabledForDelivery(): Boolean {
    return this.catItem['disable-for-delivery'] === 1 ? true : false;
  }

  set isDisabledForDelivery(newVal: Boolean) {
    this.catItem['disable-for-delivery'] = newVal ? 1 : 0
  }
  
  get isEnabled(): Boolean {
    return this.catItem.enabled === 1 ? true : false;
  }
  
  set isEnabled(newVal: Boolean) {
    this.catItem.enabled = newVal ? 1 : 0;
  }

  getApps() {
    Concept.find().then((response: any) => {
      this.applications = response.data.data.map((data: any) => {
        return {
          id: data.id.toString(),
          label: data.label,
        };
      });
    });
  }

  getLocations() {
    Location.fetchLocations().then((response: any) => {
      this.locations = response.data.data;
    });
  }

  filesChange(e: any, errors: any) {
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];
    }
  }

  createCategoryItem(e: any, reset: any) {    
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Creating Category Item'
    e.target.disabled = true    
    
    const payload = !this.imageType ? this.categoryItemData : this.categoryItemForm
    const content = !this.imageType ? 'application/json' : 'multipart/form-data'
    
    CategoryApi.createItem(payload, this.$route.params.id, this.$route.params.catId, content)
      .then(response => {
        this.$notify({
          title: "CATEGORY ITEM SAVED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Category item has been saved successfully",
          type: "success",
          icon: "fas fa-check",
        });
        e.target.innerHTML = defaultText
        e.target.disabled = false

        // reset headers
        CategoryApi.headers['Content-Type'] = 'application/json'
        // this.$emit('item:created')
        // this.$emit('modal:update', false);
        this.$router.push({
            name: "itemlist"
        })
        reset()
      })
      .catch(err => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}
