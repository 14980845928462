








































































































































































































import {translations} from '@/mixins'
import {Banner as BannerApi} from "@/services/SOLO";
import {
  BanAttributes,
  SlideAttributes,
  Banner,
  Slide,
  BanDesc,
  BanTitle,
  BanLinkLabel,
  BanImgUri,
} from "@/models";
import {Select, Option} from "element-ui";
import {ValidationProvider} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [translations],
})
export default class CreateBannerItem extends Vue {
  saving: Boolean = false
  arrayLang: Array<string> = ["en-us", "ar-sa"];
  menuID: any = "";
  banTitle: BanTitle = {};
  banDesc: BanDesc = {};
  banImgUri: BanImgUri = {};
  menus: Array<Slide> = [];
  categoryMenus: Array<any> = [];
  menu_id: number = 0;
  imageType: number = 0;
  imageTypes: Array<Object> = [
    {value: 0, text: "Image URL",},
    {value: 1, text: "Upload IMG",},
  ];
  linkTypes: Array<Object> = [
    {value: "url", text: "URL",},
    {value: "category", text: "Category",},
    {value: "item", text: "Item",},
    {value: "cms-page", text: "CMS-PAGE"},
  ];
  $notify: any;
  $refs!: {
    file: any;
  };
  public translate!: Function
  @Prop() open!: Boolean;
  bannerID!: any;

  bannerslider: SlideAttributes = {
    label: "",
    status: "",
    description: {},
    title: {},
    link: "",
    "display-order": "",
    "link-type": "category",
    "link-value": "",
    "link-label": {},
    "image-uri": {},
  };

  constructor() {
    super();
  }

  mounted() {
    this.getMenus()
    this.bannerID = this.$route.params.id
  }

  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:add", newVal);
    if (!newVal) {
    }
  }

  resetForm() {
    this.bannerslider["display-order"] = "";
    this.bannerslider["link-type"] = "";
    this.bannerslider["link-value"] = "";
    this.bannerslider.label = "";

    this.bannerslider.title = {};
    this.bannerslider.description = {};

    this.bannerslider["link-label"] = {};
    this.bannerslider["image-uri"] = {};
  }

  get bannerSliderData(): SlideAttributes {
    return this.bannerslider;
  }

  getMenus() {
    BannerApi.menus().then((response: any) => {
      this.menus = response.data.data;
    });
  }

  getCategorySearch(menuID: number) {
    BannerApi.categorySearch(menuID).then((response: any) => {
      this.categoryMenus = response.data.data;
    });
  }


  get bannerSliderForm(): FormData {
    let formData = new FormData();
    formData.append("link-type", this.bannerslider["link-type"]);
    formData.append("link-value", this.bannerslider["link-value"]);
    formData.append("label", this.bannerslider.label);
    formData.append(
        "display-order",
        this.bannerslider["display-order"].toString()
    );

    for (let i in this.arrayLang) {
      formData.append(
          `title[${this.arrayLang[i]}]`,
          this.bannerslider.title[this.arrayLang[i]] ?? ''
      );

      formData.append(
          `description[${this.arrayLang[i]}]`,
          this.bannerslider.description[this.arrayLang[i]] ?? ''
      );

      formData.append(
          `link-label[${this.arrayLang[i]}]`,
          this.bannerslider["link-label"][this.arrayLang[i]] ?? ''
      );

      formData.append(`image`, this.$refs.file[i].files[0])
      formData.append(`alt-image`, this.$refs.file[i].files[0])
    }
    return formData;
  }

  cancelCreate() {
    this.resetForm()
    this.$router.go(-1);
  }

  showNotifyStatus(status: Boolean) {
    if (status) {
      this.$notify({
        title: "BANNER SLIDE SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Banner slide created successfully.",
        type: "success",
        icon: "fas fa-check",
      });
    } else {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  async createBannerSlides() {

    this.saving = true
    let payload = this.imageType ? this.bannerSliderForm : this.bannerSliderData
    await BannerApi.createBannerSlide(payload, this.bannerID)
        .then((response) => {
          this.saving = false
          this.showNotifyStatus(true)
          this.resetForm()
          this.$router.back()
        })
        .catch((err) => {
          this.showNotifyStatus(false)
        });
  }
}
