











































































































































































































import {
  Form,
  Alert,
  Input,
  Row,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Button
} from 'element-ui'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Category, Integrations} from '@/services/SOLO'
import {translations} from '@/mixins';
import Translations from '../../../Translations.vue';

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    Translations,
  },
  filters: {
    formTitle(str: any) {
      return str.split('_').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    fieldTitle(str: any) {
      return str.split('-').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    capitalizeFirstLetter(str: any) {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mixins: [translations],
})

export default class IntegratedItems extends Vue {
  translate!: Function;
  @Prop() currentIntegrations?: Array<Object>
  isOpen: boolean = false
  items: any = []
  categories: any = []
  foodicsF5Menu: any = []
  foodicsMenu: any = []
  $notify: any
  integratedCollection?: Array<Object>
  formElements: any = {
    options: {
      address_types: {
        home: '',
        company: '',
        school: ''
      },
      config: {
        categories: '',
        fields: {
          items: '',
          modifiers: '',
          modifierGroups: '',
          location: '',
        },
        sources: {
          Web: '',
          iOS: '',
          Android: '',
        }
      },
      concept_id: '',
      extra_items_deliver: '',
      language: '',
      license_code: '',
      menu_id: '',
      menu_template_id: '',
      order_mode_deliver: '',
      order_mode_pickup: '',
      password_salt: '',
      pay_sub_type_card_on_delivery: '',
      pay_sub_type_cash: '',
      pay_type_card_on_delivery: '',
      pay_type_cash: '',
      request_code: '',
      service_charge: '',
      tender_id_card: '',
      tender_id_card_on_delivery: '',
      tender_id_cash: '',
      wsdl_url: '',
    }
  }
  itemFields = [
    {text: 'Name', value: 'name'},
    {text: 'Price', value: 'price'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
    {text: 'Enabled', value: 'enabled'},
  ]
  modifiers = [
    {text: 'Name', value: 'name'},
    {text: 'Price', value: 'price'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
    {text: 'Enabled', value: 'enabled'},
  ]
  locations = [
    {text: 'Lat', value: 'lat'},
    {text: 'Lng', value: 'lng'},
    {text: 'Opening Hours', value: 'opening_hours'},
  ]
  modifierGroups = [
    {text: 'Name', value: 'name'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
  ]

  @Watch('currentIntegrations', {deep: true})
  onCurrentIntegrationsChanged(newVal: any) {
    this.items = newVal
  }

  getProviderItem(data: any) {
    this.isOpen = true
    this.formElements = data.attributes

    if (this.formElements.type === 'pos') {
      if (this.formElements.provider === 'sdm') {
        this.formElements.options.address_types = JSON.parse(this.formElements.options.address_types)
      }
      if (this.formElements.provider === 'foodics-f5') {
        this.formElements.options.fields = JSON.parse(this.formElements.options.fields)
        this.getFoodicsF5Menu()
      } else if (this.formElements.provider === 'foodics') {
        this.formElements.options.config = JSON.parse(this.formElements.options.config)
        this.getFoodicsMenu()
      } else {
        this.formElements.options.config = JSON.parse(this.formElements.options.config)
        this.getSdmCategories()
      }
    } else if (this.formElements.type === 'pos-disabled') {
      if (this.formElements.provider === 'foodics-f5') {
        this.formElements.options.fields = JSON.parse(this.formElements.options.fields)
        this.getFoodicsF5Menu()
      }
    }
  }

  async getSdmCategories() {
    let staticMenuId = 28
    await Category.integrationCategories().then((response: any) => {
      this.categories = response.data.data
      for (let key in this.formElements.options.config.categories) {
        this.formElements.options.config.categories[key] = this.categories[key].name
      }
    })
  }

  getFoodicsF5Menu() {
      for (let key in this.formElements.options.fields) {
        this.foodicsF5Menu[key] = this.formElements.options.fields[key]
      }
  }
  getFoodicsMenu() {
      for (let key in this.formElements.options.config) {
        this.foodicsMenu[key] = this.formElements.options.config[key]
      }
  }

  async update() {
    let provider = this.formElements.provider
    let formPayload: any = {}
    let updatePayload: any = {}
    let payload: any = []
    let set: any = []
    for (let key in this.formElements.options) {
      set = {
        'key': key,
        'value': typeof(this.formElements.options[key]) === 'object' ? JSON.stringify(this.formElements.options[key]) : this.formElements.options[key]
      }
      payload.push(set)
    }
    updatePayload['provider'] = this.formElements.provider
    updatePayload['type'] = this.formElements.type
    updatePayload['options'] = payload
    delete this.formElements
    formPayload = {...updatePayload, ...this.formElements}

    await Integrations.store(provider, formPayload)
        .then((response) => {
          this.isOpen = false
          this.$notify({
            title: "Integration Provider",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Integration provider has been successfully updated.",
            type: "success",
            icon: "fas fa-check",
          });
          location.reload();
        })
        .catch((err) => {
          // this.isOpen = false
          // this.$notify({
          //   title: "Integration Provider",
          //   verticalAlign: "bottom",
          //   horizontalAlign: "left",
          //   message: "Integration provider has been successfully updated.",
          //   type: "success",
          //   icon: "fas fa-check",
          // });
          // location.reload();
        })
  }

}
