



















































































































































































































































































































































































































































































































































































































































import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option, Form, FormItem } from "element-ui";
import { translations, eventHandler } from "@/mixins";
import StoreTimes from "../components/StoreTimes.vue";
// import StoreTimes from "@/views/Admin/pages/Locations/components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {
  Subscription as SubscriptionApi,
  Menu,
  Category,
  Promotion
} from "@/services/SOLO";
import { mixins } from "vue-class-component";
// import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    Translations,
    StoreTimes,
    // BaseInput,
    ValidationProvider
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY");
    }
  },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
      getLocale: "app/getLocale",
      getSubscription: "account/getSubscription"
    })
  },
  mixins: [translations, eventHandler]
})
export default class PromotionsCreate extends Vue {
  private selected: number = 1;
  private scopeSelected: number = 1;

  $notify: any;

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  item: any = {
    menu: null,
    category: null
  };

  eligibilityItems: any = [
    {
      menu: null,
      category: null,
      item: null,
      quantity: null,
    }
  ];

  // cartMustContain: number = 1;
  minimumCartAmount: number = 0;

  posDiscount: string = "";

  orderTypes: any = {
    delivery: {
      value: "delivery",
      model: true
    },
    eatIn: {
      value: "eat-in",
      model: true
    },
    toGo: {
      value: "to-go",
      model: true
    },
    pickup: {
      value: "pickup",
      model: true
    },
    curbside: {
      value: "curbside",
      model: true
    }
  };

  paymentMethods: any = {
    cash: {
      value: "cash",
      model: true
    },
    card: {
      value: "card",
      model: true
    },
    // stcPay: {
    //   value: "stcpay",
    //   model: true
    // }
  };

  accountTypes: any = {
    full: {
      value: "full",
      model: true
    },
    guest: {
      value: "guest",
      model: true
    }
  };

  accountStatus: any = {
    new: {
      value: "new",
      model: true
    },
    returning: {
      value: "returning",
      model: true
    }
  };

  openingHours: any = [
    {
      open: "12:00 AM",
      closed: "11:59 PM"
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM"
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM"
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM"
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM"
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM"
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM"
    }
  ];

  dataForm: any = {
    type: "discount",
    name: {
      "en-us": "",
      "ar-sa": ""
    },
    duration: 1,
    "item-id": null,
    "item-code": null,
    "item-quantity": 0,
    "item-price": 0,
    "discount-type": "fixed",
    "discount-code": null,
    "discount-value": null,
    "discount-max": 0,
    "redemption-limit-global": -1,
    "redemption-limit-customer": 1,
    "eligibility-rules": {
      account_type: "",
      total_orders: "gte:47",
      type: ""
    },
    "availability-rules": {
      month: "9,10,11,12",
      schedule: {
        mon: {
          from: "0000",
          to: "2359",
          nextDay: false
        },
        tue: {
          from: "0000",
          to: "2359",
          nextDay: false
        },
        wed: {
          from: "0000",
          to: "2359",
          nextDay: false
        },
        thu: {
          from: "0000",
          to: "2359",
          nextDay: false
        },
        fri: {
          from: "0000",
          to: "2359",
          nextDay: false
        },
        sat: {
          from: "0000",
          to: "2359",
          nextDay: false
        },
        sun: {
          from: "0000",
          to: "2359",
          nextDay: false
        }
      }
    },
    "cart-rules": {
      items: {
        "2049": {
          quantity: "gte:2"
        }
      },
      category: {
        "1": {
          quantity: "gte:2"
        }
      },
      total: "gte:10",
      "payment-method": "",
      "order-type": ""
    }
  };
  menus: any = [];
  categories: any = [];
  items: any = [];
  menuId: string | number = "";
  categoryId: string | number = "";
  matchDay = [
    {
      idx: 0,
      day: "sun"
    },
    {
      idx: 1,
      day: "mon"
    },
    {
      idx: 2,
      day: "tue"
    },
    {
      idx: 3,
      day: "wed"
    },
    {
      idx: 4,
      day: "thu"
    },
    {
      idx: 5,
      day: "fri"
    },
    {
      idx: 6,
      day: "sat"
    }
  ];
  public enable247: boolean = true;

  created() {
    this.getMenus();
    if (this.$route.params.id) {
      Promotion.getPromotion(this.$route.params.id).then((response) => {
        const data = response.data.data;
        console.log('data PromotionsEdit: ', data);
        this.dataForm = {
          type: data.attributes.type,
          name: {
            'en-us': data.attributes.name,
          },
          duration: 1,
          "item-id": data.attributes['item-id'],
          "item-code": data.attributes['item-code'],
          "item-quantity": data.attributes['item-quantity'],
          "item-price": data.attributes['item-price'],
          "discount-type": data.attributes['discount-type'],
          "discount-code": data.attributes['discount-code'],
          "discount-value": data.attributes['discount-value'],
          "discount-max": data.attributes['discount-max'],
          "redemption-limit-global": data.attributes['redemption-limit-global'],
          "redemption-limit-customer": data.attributes['redemption-limit-customer'],
          "eligibility-rules": {
            account_type: "",
            total_orders: "gte:47",
            type: ""
          },
          "availability-rules": {
            month: "9,10,11,12",
            schedule: {
              mon: {
                from: "0000",
                to: "2359",
                nextDay: false
              },
              tue: {
                from: "0000",
                to: "2359",
                nextDay: false
              },
              wed: {
                from: "0000",
                to: "2359",
                nextDay: false
              },
              thu: {
                from: "0000",
                to: "2359",
                nextDay: false
              },
              fri: {
                from: "0000",
                to: "2359",
                nextDay: false
              },
              sat: {
                from: "0000",
                to: "2359",
                nextDay: false
              },
              sun: {
                from: "0000",
                to: "2359",
                nextDay: false
              }
            }
          },
          "cart-rules": {
            items: {
              "2049": {
                quantity: "gte:2"
              }
            },
            category: {
              "1": {
                quantity: "gte:2"
              }
            },
            total: "gte:10",
            "payment-method": "",
            "order-type": ""
          }
        };
      });
    }
  }

  async getMenus() {
    const response = await Menu.all();
    this.menus = response.data.data;
  }

  async getCategoriesByMenu(menuId: string | number) {
    const response = await Category.all(menuId, 1);
    this.categories = response.data.data;
  }

  async getItemsByCategory(
    menuId: string | number,
    categoryId: string | number
  ) {
    const response = await Category.items(menuId, categoryId, 1);
    this.items = response.data.data;
  }

  onMenuChange(id: string | number) {
    this.menuId = id;
    this.getCategoriesByMenu(id);
  }

  onCategoryChange(id: string | number) {
    this.categoryId = id;
    this.getItemsByCategory(this.menuId, id);
  }

  addPromo() {
    console.log("orderTypes: ", this.orderTypes);

    if (this.dataForm["item-id"]) {
      const item = this.items.find(
        (i: any) => i.id === this.dataForm["item-id"]
      );
      console.log("item: ", item);
      console.log("this.items: ", this.items);
      console.log(`this.dataForm['item-id']: `, this.dataForm["item-id"]);
      if (item) {
        this.dataForm["item-code"] = item.attributes.code;
      }
    }

    // Get order types
    let arr = [];
    for (let x in this.orderTypes) {
      if (this.orderTypes[x].model) {
        arr.push(this.orderTypes[x].value);
      }
    }
    this.dataForm["cart-rules"]["order-type"] = arr.length
      ? `${arr.join()}`
      : "";
    arr = [];

    // Get payment methods
    for (let x in this.paymentMethods) {
      if (this.paymentMethods[x].model) {
        arr.push(this.paymentMethods[x].value);
      }
    }
    this.dataForm["cart-rules"]["payment-method"] = arr.length
      ? `in:${arr.join()}`
      : "";
    arr = [];

    // Get account types
    for (let x in this.accountTypes) {
      if (this.accountTypes[x].model) {
        arr.push(this.accountTypes[x].value);
      }
    }
    this.dataForm["eligibility-rules"]["account_type"] = arr.length
      ? `in:${arr.join()}`
      : "";
    arr = [];

    // Get account status
    for (let x in this.accountStatus) {
      if (this.accountStatus[x].model) {
        arr.push(this.accountStatus[x].value);
      }
    }
    this.dataForm["eligibility-rules"]["type"] = arr.length
      ? `${arr.join()}`
      : "";
    arr = [];

    const schedule: any = {};
    this.openingHours.forEach((d: any, idx: number) => {
      const found: any = this.matchDay.find((m: any) => m.idx === idx);
      schedule[found.day] = {
        from: this.convertTime12to24(d.open),
        to: this.convertTime12to24(d.closed),
        nextDay: false
      };
    });
    this.dataForm["availability-rules"].schedule = schedule;

    const cartRules: any = {
      items: {},
      category: {}
    };
    this.eligibilityItems.forEach((i: any) => {
      cartRules.items[i.item] = {
        quantity: `gte:${i.quantity}`,
      };
      cartRules.category[i.category] = {
        quantity: `gte:${i.quantity}`,
      };
    });
    this.dataForm.name['ar-sa'] = this.dataForm.name['en-us'];
    this.dataForm["cart-rules"].items = cartRules.items;
    this.dataForm["cart-rules"].category = cartRules.category;
    this.dataForm["cart-rules"].total = this.minimumCartAmount;

    console.log("openingHours: ", this.openingHours);
    console.log("DataForm: ", this.dataForm);
    console.log("cartRules: ", cartRules);

    Promotion.savePromotion(this.dataForm).then(() => {
      this.$notify({
        title: "ADD PROMOTION",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Promotion successfully added",
        type: "success",
        icon: "fas fa-check"
      });
      this.$router.push({ name: "PromotionsList" });
    });
  }
  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }
  onAddEligibilityItem() {
    this.eligibilityItems.push({
      menu: null,
      category: null,
      item: null,
      quantity: null,
    });
  }
  onDeleteEligibilityItem(index: number) {
    this.eligibilityItems.splice(index, 1);
  }
  cancelPromo() {
    this.$router.push({ name: "PromotionsList" });
  }
}
