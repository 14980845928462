var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('translations'),_c('side-bar',{attrs:{"logo":_vm.logo}},[_c('template',{slot:"links"},[(_vm.activeConcept)?[_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('dashboard')),expression:"isViewable('dashboard')"}],attrs:{"link":{
            name: _vm.translate('Dashboard'),
            path: '/dashboard',
            icon: 'fas fa-chart-area text-orange',
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('sliding-banners')),expression:"isViewable('sliding-banners')"}],attrs:{"link":{
            name: _vm.translate('In-App Content'),
            icon: 'fas fa-images text-orange'
          }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Sliding Banners'), path: "/cms/banners/list" }}})],1),(_vm.getMenus.length)?_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('menus')),expression:"isViewable('menus')"}],ref:"menuPanel",attrs:{"link":{
            name: _vm.translate('Menus'),
            icon: 'fas fa-book-open text-orange',
          }}},_vm._l((_vm.getMenus),function(menu,i){return _c('sidebar-item',{key:i,ref:"menus",refInFor:true,attrs:{"link":{ name: menu.attributes.label, path: '/menus/' + menu.id }}})}),1):_vm._e(),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('locations')),expression:"isViewable('locations')"}],attrs:{"link":{
            name: _vm.translate('Locations'),
            path: '/locations',
            icon: 'fas fa-map-marked-alt text-orange',
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('kiosks')),expression:"isViewable('kiosks')"}],attrs:{"link":{
            name: _vm.translate('Kiosks'),
            path: '/kiosks',
            icon: 'fas fa-tablet-alt text-orange',
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('apps')),expression:"isViewable('apps')"}],attrs:{"link":{
            name: _vm.translate('Apps'),
            path: '/apps',
            icon: 'fas fa-mobile text-orange'
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('qr-ordering')),expression:"isViewable('qr-ordering')"}],attrs:{"link":{
            name: _vm.translate('QR Ordering'),
            path: '/qr-ordering',
            icon: 'fas fa-qrcode text-orange'
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('customers')),expression:"isViewable('customers')"}],attrs:{"link":{
              name: _vm.translate('Customers'),
              icon: 'fas fa-user text-orange'
            }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Customers'), path: "/customers" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Blacklists'), path: "/blacklists" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Loyalty'), path: "/loyalty" }}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('employees')),expression:"isViewable('employees')"}],attrs:{"link":{
              name: _vm.translate('Employees'),
              path: '/employees',
              icon: 'fas fa-user text-orange'
            }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('feedback')),expression:"isViewable('feedback')"}],attrs:{"link":{
            name: _vm.translate('Feedback'),
            path: '/feedback',
            icon: 'fas fa-comment-alt text-orange',
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('reports')),expression:"isViewable('reports')"}],attrs:{"link":{
            name: _vm.translate('Reports'),
            path: '/reports',
            icon: 'fas fa-flag text-orange',
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('orders')),expression:"isViewable('orders')"}],attrs:{"link":{
            name: _vm.translate('Orders'),
            path: '/orders',
            icon: 'fas fa-shopping-bag text-orange',
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('curbside')),expression:"isViewable('curbside')"}],attrs:{"link":{
            name: _vm.translate('Curbside Pickup'),
            path: '/curbside',
            icon: 'fas fa-car text-orange',
          }}}),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"link":{
              name: _vm.translate('Promotions'),
              icon: 'fas fa-badge-percent text-orange',
            }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Promotions'), path: "/promotions/list" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Coupons'), path: "/coupon-groups/list" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Digital Coupons'), path: "/digital-coupons/list?digital=true" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Segments'), path: "/segments/list" }}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('messaging')),expression:"isViewable('messaging')"}],attrs:{"link":{
              name: _vm.translate('Messaging'),
              icon: 'fas fa-align-left text-orange'
            }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Messaging'), path: "/messaging/notification" }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Order Notifications', path: "/messaging/order-statuses" }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Star Ratings', path: "/messaging/star-ratings" }}})],1),_c('sidebar-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isViewable('settings')),expression:"isViewable('settings')"}],attrs:{"link":{
              name: _vm.translate('Settings'),
              icon: 'fas fa-cog text-orange',
            }}},[_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Globals'), path: "/settings/globals" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Concept'), path: "/settings/concepts" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Facilities'), path: "/settings/facilities" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Allergens'), path: "/settings/allergens" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Order Statuses'), path: "/settings/order-statuses" }}}),_c('translate',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("Integration")]),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Integration'), path: "/settings/integrations" }}}),_c('sidebar-item',{attrs:{"link":{ name: _vm.translate('Account'), path: "/account" }}})],1),_c('a',{staticClass:"nav-link",attrs:{"href":"https://solo.skylinedynamics.com","aria-current":"page"}},[_c('i',{staticClass:"fal fa-external-link-square text-orange"}),_c('span',{staticClass:"nav-link-text"},[_vm._v(_vm._s(_vm.translate('Switch to Old Portal')))])]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDisplay),expression:"isDisplay"}],staticClass:"nav-link",attrs:{"aria-current":"page","href":"#"},on:{"click":_vm.logout}},[_c('i',{staticClass:"fas fa-sign-out-alt text-orange"}),_c('span',{staticClass:"nav-link-text"},[_vm._v(_vm._s(_vm.translate('Logout')))])])]:_vm._e()],2)],2),_c('div',{staticClass:"main-content"},[(_vm.getUser)?_c('dashboard-navbar',{attrs:{"type":"default"}}):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }