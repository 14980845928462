















































































































































































import {
  Form,
  Alert,
  Input,
  Row,
  Col,
  InputNumber,
  FormItem,
  Select,
  Option,
  Checkbox,
  Radio,
  RadioGroup,
  Button
} from 'element-ui'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Category, Integrations} from '@/services/SOLO'
import {translations} from '@/mixins';
import Translations from '../../../Translations.vue';

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Input.name]: Input,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    Translations,
  },
  filters: {
    formTitle(str: any) {
      return str.split('_').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    fieldTitle(str: any) {
      return str.split('-').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    capitalizeFirstLetter(str: any) {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mixins: [translations],
})

export default class IntegrationAvailable extends Vue {
  @Prop() currentProviders?: Array<Object>
  translate!: Function;
  items: any = []
  isOpen: boolean = false
  categories: any = []
  formElements: any = {
    options: {
      address_types: {
        home: '',
        company: '',
        school: ''
      },
      config: {
        categories: '',
        fields: {
          items: '',
          modifiers: '',
          modifierGroups: '',
          location: '',
        },
        sources: {
          Web: '',
          iOS: '',
          Android: '',
        }
      },
      concept_id: '',
      extra_items_deliver: '',
      language: '',
      license_code: '',
      menu_id: '',
      menu_template_id: '',
      order_mode_deliver: '',
      order_mode_pickup: '',
      password_salt: '',
      pay_sub_type_card_on_delivery: '',
      pay_sub_type_cash: '',
      pay_type_card_on_delivery: '',
      pay_type_cash: '',
      request_code: '',
      service_charge: '',
      tender_id_card: '',
      tender_id_card_on_delivery: '',
      tender_id_cash: '',
      wsdl_url: '',
    }
  }
  $notify: any
  providerId: any = ''
  formPayload: any = {}
  payloadForm: any = {}
  installForm: any = {
    options: {
      address_types: {
        home: '',
        school: '',
        company: ''
      },
      config: {
        categories: [],
        fields: {
          items: [],
          modifiers: [],
          modifierGroups: [],
          location: []
        },
        sources: {
          Web: '',
          iOS: '',
          Android: ''
        }
      }
    }
  }
  itemFields = [
    {text: 'Name', value: 'name'},
    {text: 'Price', value: 'price'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
    {text: 'Enabled', value: 'enabled'},
  ]
  modifiers = [
    {text: 'Name', value: 'name'},
    {text: 'Price', value: 'price'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
    {text: 'Enabled', value: 'enabled'},
  ]
  locations = [
    {text: 'Lat', value: 'lat'},
    {text: 'Lng', value: 'lng'},
    {text: 'Opening Hours', value: 'opening_hours'},
  ]
  modifierGroups = [
    {text: 'Name', value: 'name'},
    {text: 'Minimum', value: 'minimum'},
    {text: 'Maximum', value: 'maximum'},
  ]

  @Watch('currentProviders', {deep: true})
  onCurrentProvidersChanged(newVal: any) {
    this.items = newVal
  }

  getProviderItem(data: any) {
    this.isOpen = true
    this.providerId = data.id
    this.formElements = data.attributes
    if (this.formElements.type === 'pos') {
      // this.formElements.options.address_types = JSON.parse(this.formElements.options.address_types)
      // this.formElements.options.config = JSON.parse(this.formElements.options.config)
      this.getSdmCategories()
    }
  }

  redirectToInstallUrl(url: string) {
    window.location.href = process.env.VUE_APP_URL + '/' + url;
  }

  async getSdmCategories() {
    let staticMenuId = 16
    await Category.index(staticMenuId).then((response: any) => {
      this.categories = response.data.data
    })
  }

  checkImgSource(data: any) {
    try {
      let checkLogo = require(`@/assets/images/integrations/providers/${data}.png`)
      if (checkLogo) {
        return checkLogo
      }
    } catch (e) {}
  }

  async install() {
    let optionPayload: any = {}
    let payload: any = []
    let set: any = []
    for (let key in this.formPayload) {
      set = {
        'key': key,
        'value': this.formPayload[key]
      }
      payload.push(set)
    }

    optionPayload['type'] = this.formElements.type
    optionPayload['provider'] = this.formElements.label
    optionPayload['options'] = payload
    this.payloadForm = optionPayload

    await Integrations.store(this.formElements.label, this.payloadForm)
        .then((response) => {
          this.isOpen = false
          this.$notify({
            title: "Integration Provider",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Integration provider has been successfully installed.",
            type: "success",
            icon: "fas fa-check",
          });
          location.reload();
        })
        .catch((err) => {
          this.isOpen = false
          this.$notify({
            title: "Integration Provider",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Integration provider has been successfully installed.",
            type: "success",
            icon: "fas fa-check",
          });
          location.reload();
        })
  }
}
