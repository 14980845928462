
















































































import { Feedback as FeedbackModel } from "@/models";
import { Pagination } from "element-ui";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Feedback as FeedbackService} from "@/services/SOLO";
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'

@Component({
  components: {
    [Pagination.name]: Pagination,
  },
mixins: [translations],
})
export default class FeedbackCollapse extends Vue {
  @Prop() feedbackMeta!: Array<FeedbackModel>;
  public translate!: Function
  name: string = "";
  email: string = "";
  telephone: string = "";
  nextPage: number = 0;
  hasMore: boolean = false;
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  totalPage: number = 0;
  isLoaded: boolean = false;
  feedbacks: Array<any> = [];

  @Watch("feedbackMeta", { deep: true })
  onFeedbackMetaChanged(newVal: any) {
    this.isLoaded = true;
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }
  // filteredFeedbacks() {
  //   this.$emit("findFeedbacks", this.search);
  // }
  pageChange(data: any) {
    this.$emit("getFeedbacks", data);
  }

  searchFeedback(name: string, email: string, telephone: string) {
    this.$emit("searchFeedbacks", name, email, telephone);
  }

  resetForm(data: any) {
      this.name = "";
      this.email = "";
      this.telephone = "";
      this.$emit("getFeedbacks", data);
  }

}
