



















































































































import moment from "moment";
import "moment/locale/pt-br";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Order } from "@/services/SOLO";
import {
  OrderDetailAttributes,
  OrderDetail as OrderDetailModel,
} from "@/models";
import { translations } from '@/mixins'
import Translations from './Translations.vue'

@Component({
  components: {
    flatPicker
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY hh:mm A");
    },
  },
mixins: [translations],
})
export default class OrderDetails extends Vue {
  @Prop() isLoaded!: Boolean;
  @Prop() order!: Object;
  @Prop() customerAddress!: any;
  public translate!: Function


    tooltip(code: any, posResponse: any){
      if(code && posResponse) {
        return 'Order successfully sent to POS'
      } else if(!code && posResponse) {
        return 'Order failed in POS'
      } else {
        return ''
      }
    }
}
