




























































































































































import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Customer, Employee } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue';
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string
}

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
  },
mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openEdit: boolean = false
  @Prop() employee?: Object
  public translate!: Function
  id: any
  isOpen: boolean = false
  modalForm: any =  {
    'first-name': '',
    'last-name': '',
    email: '',
    mobile: '',
    pincode: 0,
    status: '',
    'account-type': '',
    roles: [],
    locations: [],
    password: '',
  }
  selectedStatus: any = ''
  selectedType: any = ''
  roles: any = [];
  locations: any = [];
  selectedRoles: any = [];
  selectedLocations: any = [];
  isPassword: boolean = false;
  password: string = '';
  successNotification!: Function;
  systemErrorNotification!: Function;
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  mounted() {
    Employee.getRoles().then((response) => {
      let roles = response.data.data;
      this.roles = roles.map((r: any) => {
        return {
          key: r.attributes.name,
          value: r.attributes.name,
        };
      });
    });
    Employee.getLocations().then((response) => {
      let locations = response.data.data;
      this.locations = locations.map((l: any) => {
        return {
          key: l.id,
          value: l.attributes.name,
        };
      });
    });
  }
  @Watch('openEdit', { deep: true })
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }
  @Watch('employee', { deep: true })
  onCustomerChanged(newVal: any) {
    console.log('newVal: ', newVal);
    this.modalForm = {
      'first-name': newVal.attributes['first-name'],
      'last-name': newVal.attributes['last-name'],
      email: newVal.attributes.email,
      mobile: newVal.attributes.mobile,
      pincode: newVal.attributes.pincode,
      status: newVal.attributes.status,
      'account-type': newVal.attributes['account-type'],
      roles: newVal.attributes && newVal.attributes.roles,
      locations: newVal.attributes
        && newVal.attributes.locations
        && newVal.attributes.locations.map((l:any) => l.id) || [],
    };
    this.id = newVal.id;
  }

  handleClose() {
    this.$emit('handleClose', false)
  }

  updateEmployeeDetails() {
    const payload = {
      ['first-name']: this.modalForm['first-name'],
      ['last-name']: this.modalForm['last-name'],
      email: this.modalForm.email,
      mobile: this.modalForm.mobile,
      roles: this.modalForm.roles.map((r: any) => r).join(),
      locations: this.modalForm.locations.map((l: any) => l).join(),
      password: '',
    };
    if (this.isPassword) {
      payload.password = this.modalForm.password;
    } else {
      delete payload.password;
    }

    Employee.update(this.id, payload)
        .then((response: any) => {
          this.$emit('handleClose', false);
          this.$emit('updateList', true);
          this.successNotification('EMPLOYEE UPDATED!', 'Employee successfully updated!');
        })
        .catch((err: any) => {
          console.log('response: ', err.response);
          let error = '';
          for (const prop in err.response.data.error) {
            err.response.data.error[prop].forEach((e: any) => {
              error += e + '\n';
            })
          }
          console.log('error: ', error);
          this.systemErrorNotification('CREATE ERROR!', error);
        });
  }

}
