<template>
  <bread-crumb list-classes="breadcrumb-links breadcrumb-dark">
    <BreadCrumbItem>
        <router-link to="/">
          <i class="fas fa-home"></i>
        </router-link>
    </BreadCrumbItem>
    <BreadCrumbItem
      v-for="(route, index) in $route.matched.slice()"
      :key="route.name"
      :active="index === $route.matched.length - 1"
      style="display:inline-block"
    >
      <router-link
        :to="{ name: route.name }"
        v-if="index < $route.matched.length - 1"
      >
        {{ displayName(route.name) }}
      </router-link>
      <span v-else>{{ displayName(route.name) }}</span>
    </BreadCrumbItem>
  </bread-crumb>
</template>

<script>
  import BreadCrumb from './Breadcrumb';
  import BreadCrumbItem from './BreadcrumbItem';

  export default {
    name: 'route-breadcrumb',
    components: {
      BreadCrumb,
      BreadCrumbItem
    },
    methods: {
      getBreadName(route) {
        return route.name;
      },
      displayName(name) {
        if (name.includes('_')) {
          name = name.split('_')[1];
        }
        return name.toUpperCase();
      }
    }
  };
</script>

<style scoped></style>
