











































import moment from "moment";
import { Table, TableColumn, Button } from "element-ui";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { notificationAlerts, translations } from "@/mixins";

import { Promotion } from "@/services/SOLO";
import  Translations  from '../components/Translations.vue'

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  mixins: [translations, notificationAlerts]
})
export default class SegmentsList extends Vue {
  @Prop() list!: Array<any>;
  //   @Prop() loading: boolean = false
  public translate!: Function;
  color: string = "white";
  currentPage: number = 1;
  successNotification!: Function;

  segments: Array<any> = [];
  singleSegment: any = {};

  @Watch("loading", { deep: true })
  //   onLoadingChanged(newVal: any) {
  //     this.loading = newVal
  //   }
  standardDateFormat(value: any) {
    if (!value) return "---";
    return moment(value).format("DD/MM/YYYY");
  }
  //   updateList() {
  //     this.$emit('updateList', true);
  //   }

  created() {
    Promotion.fetchSegments().then(response => {
      // console.log("segmentsData: ", response);
      this.segments = response.data.data.data;
      console.log("segmentsResponse: ", this.segments);
    });
  }
}
