






















































































































































































































import moment from "moment";
import { Order, Location, Integrations } from "@/services/SOLO";
import { Select, Option } from "element-ui";
import OrderStatus from "./components/OrderStatus.vue";
import ItemTableHeader from "./components/ItemTableHeader.vue";
import CustomerDetails from "./components/CustomerDetails.vue";
import OrderDetail from "./components/OrderDetails.vue";
import PaymentDetails from "./components/PaymentDetails.vue";
import PaymentDetailsContinued from "./components/PaymentDetailsContinued";
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'
import {Order as OrderApi} from "@/services/SOLO";
import {Payment as PaymentApi} from "@/services/SOLO";
import {
  OrderDetailAttributes,
  OrderDetail as OrderDetailModel,
  PaymentAttributes,
  Payments as PaymentModel,

} from "@/models";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
@Component({
  components: {
    RouteBreadCrumb,
    CustomerDetails,
    OrderDetail,
    PaymentDetails,
    PaymentDetailsContinued,
    ItemTableHeader,
    OrderStatus,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  filters: {
    getItemModifier(data: Array<any>, modifiers: Array<any>) {
      return data
        .map((data: any) => {
          let filtered = modifiers.filter((modifier: any) => {
            return modifier.id === data.id;
          });
          if (filtered.length) {
            return filtered[0].attributes.name;
          }
          return null;
        })
        .filter((name: any) => {
          return name;
        })
        .join(", ");
    },
  },
mixins: [translations],
})
export default class OrderComponent extends Vue {
  order: any = {};
  driverId: string = "";
  logistic: string = "";
  orderID: string = "";
  payment: any = {};
  posTooltip: string = "";
  orderIncludes: Array<Object> = [];
  customer: Object = {};
  isLoaded: Boolean = false;
  paymentIsLoaded: Boolean = false;
  addresses: Array<Object> = [];
  statuses: Array<Object> = [];
  drivers: Array<Object> = [];
  logistics: Array<Object> = [];
  $notify: any;
  public translate!: Function




  mounted() {
    this.getOrderDetails();
    this.getOrderStatuses();
    this.getLogisticPartners();
  }

  get customerAddress() {
    if (this.orderIncludes.length && this.isLoaded) {
      if (this.order.attributes.type === "deliver") {
        let filtered = this.orderIncludes.filter((include: any) => {
          return include.type === "customer-address";
        });

        if (filtered.length) {
          return filtered[0];
        }
      }
    }

    return null;
  }

  get modifiers() {
    if (this.orderIncludes.length && this.isLoaded) {
      return this.orderIncludes.filter((include: any) => {
        return include.type === "modifier";
      });
    }

    return [];
  }

  get ingredients() {
    if (this.orderIncludes.length && this.isLoaded) {
      return this.orderIncludes.filter((include: any) => {
        return include.type === "item-ingredient";
      });
    }

    return [];
  }

  @Watch("order", { immediate: true, deep: true })
  onOrderChange(order: any) {
    if (order.attributes) {
      this.orderID = order.id;
      this.changeDate(this.orderID);
      if (order.attributes.type === "deliver") {
        this.driverId = order.attributes["driver-id"]
          ? order.attributes["driver-id"].toString()
          : "";
        this.logistic = order.attributes["delivery-logistic"];
        this.getLocationDriver();
      }
    }
  }

  standardDateFormat(value: any) {
    if (!value) return "---";
    return moment(value).format("YYYY-MM-DD hh:mm:ss");
  }

  getLocationDriver() {
    Location.fetchDrivers(this.order.attributes["location-id"]).then(
      (response) => {
        this.drivers = response.data.data;
      }
    );
  }

  getOrderStatuses() {
    Order.statusList().then((response: any) => {
      this.statuses = response.data.data;
      console.log(this.statuses)
    });
  }

  getLogisticPartners() {
    Integrations.logisticsPartners("food-logistics").then((response) => {
      this.logistics = response.data.data;
    });
  }

  getOrderDetails() {
    Order.orderDetails(this.$route.params.id).then((response: any) => {
      this.order = response.data.data;
      console.log("asd");
      console.log(this.order)

      this.orderIncludes = response.data.included;

      this.getCustomer(response.data.data.attributes["customer-id"]);
      this.getOrderPayment();
    });
  }

  getCustomer(customerId: string) {
    Order.getCustomer(customerId).then((response: any) => {
      this.customer = response.data.data;
      this.isLoaded = true;
    });
  }

  changeDate(orderID: any) {
    let payload = {
      "promised-time": this.standardDateFormat(
        this.order.attributes["promised-time"]
      ),
    };

    Order.updateDate(payload, orderID)
      .then((response: any) => {})
      .catch((err: any) => {
        console.log("error");
      });
  }

    payCash(data: any) {

    let payload = {
       "payment": {
         method: 'cash'
       }
    };

    PaymentApi.updatePaymentMethod(payload, data)
        .then((response: any) => {
          this.$notify({
            title: "PAYMENT METHOD CHANGED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Payment method have been successfully changed",
            type: "success",
            icon: "fas fa-check",
          });
           this.getOrderDetails();
        })
        .catch((err: any) => {
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Something went wrong, please try again!",
            type: "danger",
            icon: "fas fa-bomb",
          });
        });
  }

  getAddresses(customerId: string) {
    Order.getCustomerAddresses(customerId).then((response: any) => {
      this.addresses = response.data.data;
    });
  }

  getOrderPayment() {
    Order.orderPayment(this.$route.params.id).then((response: any) => {
      this.payment = response.data.data[0];
      console.log(this.payment)
      this.paymentIsLoaded = true;
    });
  }

  assignDriver() {
    let payload = {
      order: this.order.id,
      employee: this.driverId,
      employee_id: this.driverId,
    };

    Location.assignDriver(payload, this.driverId)
      .then((response: any) => {
        this.$notify({
          title: "DRIVER ASSIGNED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Driver has been assigned",
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }

  assignLogistic() {
    Integrations.assignLogistics(this.order.id, this.logistic)
      .then((response: any) => {
        this.$notify({
          title: "LOGISTIC ASSIGNED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Logistic has been assigned",
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}
