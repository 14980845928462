












































































































































































































import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "@/assets/sass/main/index.scss";

function hasElement(className: string) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import {Auth} from '@/services/core'
import Translations from './global-component/Translations.vue'
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import ConcetListing from "./ConceptListing.vue";
import {FadeTransition} from "vue2-transitions";
import {Component, Prop, Vue, Watch, Ref} from "vue-property-decorator";
import BNavbarBrand from "@/components/Navbar/BaseNav";
import {mapGetters, mapMutations} from "vuex";
import {Concept} from "@/interfaces/SOLO";
import {Menus} from "@/models";
import {ElNotification} from "element-ui/types/notification";
import VueRouter, {Route} from "vue-router";
import {translations} from '@/mixins'

@Component({
  components: {
    BNavbarBrand,
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
    ConcetListing,
    Translations
  },
  computed: {
    ...mapGetters({
      getUserRole: 'account/getUserRole',
      activeConcept: "account/activeConcept",
      getMenus: "menu/getMenus",
      getUser: 'account/getUser',
      getPage: "page/getPage",
      getUserClient: 'account/getUserClient'
    })
  },
    methods: {
    ...mapMutations({
      logoutUser: 'account/logoutUser',
      setUserClient: 'account/setUserClient'
    })
  },
  mixins: [translations]
})
export default class Home extends Vue {
  getPage!: Array<typeof mapGetters>
  activeConcept!: Concept;
  getMenus!: Array<Menus>;
  getUser: any
  logo: string = ''
  $root!: any;
  $refs!: {
    menus: Array<HTMLFormElement>;
    menuPanel: HTMLFormElement;
  };
  menuRoutes: Array<any> = ["menus", "menu"];
  $notify: any;
  $route!: Route;
  isDisplay: Boolean = false
  logoutUser!: Function
  private setUserClient!: Function
  @Watch("getMenus", {immediate: true, deep: true})
  getMenusChanged(newVal: Array<Menus>, oldVal: Array<Menus>) {
    this.updateMenuPanel();
  }

  mounted() {
    this.initScrollbar();
    this.getLogo();
    this.showIconLogout();
  }

  showIconLogout() {
    if (!this.isMobile()) {
      this.isDisplay = false;
    } else {
      this.isDisplay = true;
    }
  }


  @Watch("isLoading", {immediate: true, deep: true})
  isLoadingState(newVal: boolean) {
    if (newVal)
      this.$notify({
        title: "INFO",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Loading data",
        type: "success",
        icon: "fas fa-spinner fa-spin",
      });
  }

  get isLoading(): boolean {
    return this.$root.isLoading;
  }

  isViewable(menu:any) {
    return this.getPage.includes(menu)
  }

  getLogo() {
    Auth.getClient()
        .then((response) => {
          this.setUserClient(response.data.data)
          this.logo = response.data.data.attributes['logo-uri'];
        })
  }

  logout() {
    this.logoutUser()
    location.reload()
    // this.$router.go(0)
  }

  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  updateMenuPanel() {
    setTimeout(() => {
      if (this.menuRoutes.includes(this.$route.name)) {
        console.log(this.$route.path, this.$refs.menus[0].link.path);
        if (this.$route.path != this.$refs.menus[0].link.path) {
          this.$router.push({path: this.$refs.menus[0].link.path});
        }
        this.$refs.menuPanel.collapsed = false;
      }
    }, 100);
  }

  initScrollbar() {
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      initScrollbar("sidenav");
    }
  }
}
