














































import moment from 'moment'
import EmployeeCollapse from "./components/EmployeeCollapse.vue"
import EmployeeList from "./components/EmployeeList.vue"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Customers as CustomerService } from "@/models"
import { Component, Vue } from "vue-property-decorator"
import { Employee } from '@/services/SOLO'
import { Button } from 'element-ui'
import EmployeeCreateModal from './components/EmployeeCreateModal';
import { translations } from '@/mixins'

@Component({
  components: {
    [Button.name]: Button,
    RouteBreadCrumb,
    EmployeeList,
    EmployeeCollapse,
    EmployeeCreateModal,
  },
  mixins: [translations],
})

export default class Employees extends Vue {
  customers: Array<CustomerService> = [];
  employeeMeta: any = {};
  targetKeyword: string = '';
  isLoaded: boolean = false;
  page: number = 1;
  loading: boolean = false;
  employees: Array<any> = [];
  openCreate: boolean = false

  mounted() {
    this.getEmployees(this.page);
  }

  onNew() {
    this.openCreate = true;
  }

  handleClose(value: any) {
    this.openCreate = value;
  }

  standardDateFormat(value: any)  {
    if (! value) return '---';
    return moment(value).format('DD/MM/YYYY');
  }

  get filteredEmployees(): Array<CustomerService> {
    let keyword = this.targetKeyword;
    let filter = {
      email: true,
    };
    // this.employees = this.employees.filter((c: any) => {
    //   for (let key in filter) {
    //     if (c.attributes[key] === null || undefined)
    //       return false;
    //   }
    //   return true;
    // });

    if (keyword) {
      return this.employees.filter((c: any) => {
        return (c.attributes.email && c.attributes.email.match(keyword));
      })
    }

    return this.employees;
  }

  async getEmployees(page: number) {
    this.loading = true;
    await Employee.all(page)
      .then((response: any) => {
        let filter = response.data.data;
        this.employeeMeta = response.data.meta.pagination;
        filter.map((c: any) => {
          let formatted = c.attributes;
          formatted['created-at'] = this.standardDateFormat(c.attributes['created-at']);
          return formatted;
        });
        this.employees = filter;
        console.log('filter: ', filter);
        this.loading = false;
      })
  }

  findEmployees(keyword: any) {
    this.targetKeyword = keyword;
  }
}
