





































































































































































































import { Select, Option } from "element-ui";
import { Menu as MenuApi, Location, Concept } from "@/services/SOLO";
import {
  Category as CategoryModel,
  Location as LocationModel,
  Application as ApplicationModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";

interface objKey {
  [x: string]: string | number;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [eventHandler],
})
export default class Menu extends Vue {
  @Prop() menuId!: string;
  @Prop() categoryId!: string;
  @Prop() categories!: Array<CategoryModel>;
  @Prop() numOfLoadedData!: number;
  @Prop() arrayLang!: Array<string>;
  @Prop() active!: Boolean

  selectedLocations: any = [];
  selectedApplications: any = [];
  defaultLocations: Array<LocationModel> = [];
  defaultApplications: Array<ApplicationModel> = [];
  locations: Array<LocationModel> = [];
  applications: Array<any> = [];
  catNames: objKey = {};
  catDesc: objKey = {};
  imageType: number = 0;
  $notify: any;
  file: any;
  waiting!: Function;
  restore!: Function;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ];
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {
    console.log(val);
  }
  @Watch("numOfLoadedData", { immediate: true, deep: true })
  onLoad(data: number) {
    if (data === 2) {
      this.categories[0].relationships.apps.data.map((data: any) => {
        this.selectedApplications.push(data.id);
      });

      this.categories[0].relationships.locations.data.map((data: any) => {
        this.selectedLocations.push(data.id);
      });
    }
  }
  mounted() {
    this.getApps();
    this.getLocations();
  }
  get isEnabled(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return !!this.categories[0].attributes.enabled;
    else return false;
  }
  set isEnabled(newVal: boolean) {
    this.categories[0].attributes.enabled = newVal ? 1 : 0;
  }

  getApps() {
    Concept.find().then((response: any) => {
      this.applications = response.data.data.map((data: any) => {
        return {
          id: data.id.toString(),
          label: data.label,
        };
      });
    });
  }

  getLocations() {
    Location.fetchLocations().then((response: any) => {
      this.locations = response.data.data;
    });
  }

  updateCategory(e: HTMLFormElement, isvalid: boolean) {
    if (!isvalid) {
      if (!this.imageType) {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        MenuApi.update(
          this.setData(),
          this.menuId,
          this.categoryId
        )
          .then((response: any) => {
            this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            this.$router.go(-1);
            // location.reload()
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.error.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      }else {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        MenuApi.updateFormdata(
          this.formData(),
          this.menuId,
          this.categoryId
        )
          .then((response: any) => {
            this.imageType = 0;
            this.categories[0].attributes["image-uri"] =
              response.data.data.attributes["image-uri"];
            this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            location.reload()
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      }
    }
  }

  formData() {
    let formData = new FormData();
    formData.append("code", this.categories[0].attributes.code);
    formData.append(
      "display-order",
      this.categories[0].attributes["display-order"].toString()
    );
    formData.append(
      "enabled",
      this.categories[0].attributes.enabled.toString()
    );
    for (let i in this.categories) {
      formData.append(`name[${this.categories[i].lang}]`, this.categories[i].attributes.name ? this.categories[i].attributes.name : '' )
      formData.append(`description[${this.categories[i].lang}]`, this.categories[i].attributes.description ? this.categories[i].attributes.description : '')
    }
    for(let i in this.selectedApplications) {
      formData.append(`apps[${i}]`, this.selectedApplications[i])
    }
    for(let i in this.selectedLocations) {
      formData.append(`locations[${i}]`, this.selectedLocations[i])
    }
    formData.append('image', this.$refs.file.files[0])
    return formData
  }

  setData() {
    for (let i in this.categories) {
      this.catNames[this.categories[i].lang] = this.categories[
        i
      ].attributes.name;
      this.catDesc[this.categories[i].lang] = this.categories[
        i
      ].attributes.description;
    }

    return {
      code: this.categories[0].attributes.code,
      "display-order": this.categories[0].attributes["display-order"],
      enabled: this.categories[0].attributes.enabled,
      name: this.catNames,
      description: this.catDesc,
      apps: this.selectedApplications,
      locations: this.selectedLocations,
      "image-uri": this.categories[0].attributes["image-uri"],
    };
  }

  filesChange(e: any, errors: any) {
    console.log(errors);
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];
      console.log(this.file);
    }
  }
}
