



































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import { Option, Select } from "element-ui";

@Component({
  components: {
    vSelect,
    DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  }
})
export default class CriteriaInput extends Vue {
  @Prop() htmlType!: string;
  @Prop() id!: number;
  @Prop() condition!: any;
  @Prop() choices!: Array<any>;
  @Prop() value!: any;

  @Watch("value", { immediate: true, deep: true })
  valueHandler(val: any) {
    console.log("handle val: ", val);
  }

  @Watch("condition", { immediate: true, deep: true })
  conditionHandler(val: any) {
    if (this.htmlType === "date" && val === "between") {
      this.modelValue = {
        between: []
      };
    }
    if (this.htmlType === "date" && val === "since") {
      this.modelValue = {
        since: {
          value: null,
          unit: "y"
        }
      };
    }
  }
  @Watch("htmlType", { immediate: true, deep: true })
  htmlTypeHandler(val: any) {
    if (val === "date" && this.condition === "since") {
      this.modelValue = {
        since: {
          value: 1,
          unit: "y"
        }
      };
    }
  }

  modelValue: any = null;

  @Watch("modelValue", { immediate: true, deep: true })
  modelValueHandler(val: any) {
    this.$emit("update-value", val);
  }

  created() {
    console.log("this.value: ", this.value);
    this.modelValue = this.value;
    console.log("modelValue: ", this.modelValue);
  }
}
