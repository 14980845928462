




































import moment from 'moment'
// import SegmentsList from "./pages/SegmentsList.vue"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Component, Vue } from "vue-property-decorator"
import { Button } from 'element-ui'
import { translations } from '@/mixins'

@Component({
  components: {
    [Button.name]: Button,
    RouteBreadCrumb,
    // SegmentsList,
  },
  mixins: [translations],
})

export default class Employees extends Vue {
  targetKeyword: string = '';
  isLoaded: boolean = false;
  page: number = 1;
  loading: boolean = false;
  openCreate: boolean = false

  mounted() {
  }

  redirectToAddSegment() {
    this.$router.push({
      name: "SegmentsCreate"
    });
  }

  handleClose(value: any) {
    this.openCreate = value;
  }

  standardDateFormat(value: any)  {
    if (! value) return '---';
    return moment(value).format('DD/MM/YYYY');
  }
}
