






























































































































































































































import moment from "moment";
import { translations } from "@/mixins";
import Translations from "../components/Translations.vue";
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Promotion, Coupon } from "@/services/SOLO";

@Component({
  components: {
    Translations
  },
  mixins: [translations]
})
export default class CouponGroupsList extends Vue {
  hasList: boolean = true;
  couponGroups: Array<any> = [];

  created() {
    this.loadCouponsGroup();
  }

  loadCouponsGroup() {
    Coupon.allCouponsGroup().then(response => {
      this.couponGroups = response.data.data;
      console.log('allDigitalCoupons: ', this.couponGroups);
    });
  }

  onEditGroup(coupon: any) {
    this.$router.push({
      path: `/coupon-groups/coupon-list/${coupon.id}`,
    });
  }

  onDeleteGroup(coupon: any) {
    const r = confirm(`Are you sure you want to delete this coupon "${coupon.attributes.name}"?`);
    if (r) {
      Coupon.deleteCouponsGroup(coupon.id)
      .then(() => this.loadCouponsGroup());
    }
  }

  onCreateGroupCoupon() {
    this.$router.push({
      name: "CouponGroupsCouponCreate"
    });
  }

  formatDate(date: Date) {
    console.log(moment.locale());
    return moment
      .utc(date)
      .locale("en-us")
      .local()
      .format("ll");
  }
}
