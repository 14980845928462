import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Admin from '@/views/Admin/MainLayout.vue'
import Dashboard from '@/views/Admin/pages/Dashboard/Dashboard.vue'
import Prices from '@/views/Admin/pages/Prices/Prices.vue'
import Account from '@/views/Admin/pages/Account/Account.vue'

import Promotions from '@/views/Admin/pages/Promotions/Promotions.vue'
import PromotionsCreate from '@/views/Admin/pages/Promotions/pages/PromotionsCreate.vue'
import PromotionsList from '@/views/Admin/pages/Promotions/pages/PromotionsList.vue'

import Segments from '@/views/Admin/pages/Promotions/Segments.vue'
import SegmentsCreate from '@/views/Admin/pages/Promotions/pages/SegmentsCreate.vue'
import SegmentsUpdate from '@/views/Admin/pages/Promotions/pages/SegmentsUpdate.vue'
import SegmentsList from '@/views/Admin/pages/Promotions/pages/SegmentsList.vue'

import Coupons from '@/views/Admin/pages/Promotions/Coupons.vue'
import CouponGroupsList from '@/views/Admin/pages/Promotions/pages/CouponGroupsList.vue'
import CouponGroupsCouponList from '@/views/Admin/pages/Promotions/pages/CouponGroupsCouponList.vue'
import CouponGroupsCouponCreate from '@/views/Admin/pages/Promotions/pages/CouponGroupsCouponCreate.vue'
// import CouponsCreate from '@/views/Admin/pages/Promotions/pages/PromotionsCreate.vue'

import SlidingBannerSlides from '@/views/Admin/pages/CMS/pages/SlidingBannerUpdateSlide.vue'
import Feedback from '@/views/Admin/pages/Feedback/Feedback.vue'
import FeedbackItem from "@/views/Admin/pages/Feedback/components/FeedbackItem.vue"
import LoyaltyItem from "@/views/Admin/pages/Customers/components/LoyaltyItem.vue"
import LandingPage from '@/views/Landing/MainLayout.vue'
import Login from '@/views/Landing/pages/Login/Login.vue'
import Register from '@/views/Landing/pages/Register/Register.vue'

import CustomerSubscription from '@/views/Landing/pages/Subscription/Subscription.vue'
import CustomerPayment from '@/views/Landing/pages/Subscription/Payment.vue'

import Menus from '@/views/Admin/pages/Menus/Menus.vue'
import Menu from '@/views/Admin/pages/Menus/pages/Menu.vue'
import Orders from '@/views/Admin/pages/Orders/Orders.vue'
import Curbside from '@/views/Admin/pages/Curbside/Curbside.vue'
import Order from '@/views/Admin/pages/Orders/Order.vue'
import MenuUpdateCategory from '@/views/Admin/pages/Menus/pages/MenuUpdateCategory.vue'
import MenuCategory from '@/views/Admin/pages/Menus/pages/MenuCategory.vue'
import MenuUpsells from '@/views/Admin/pages/Menus/pages/MenuUpsells.vue'
import MenuItems from '@/views/Admin/pages/Menus/pages/MenuItems.vue'
import ItemList from '@/views/Admin/pages/Menus/pages/itemPages/ItemList.vue'
import ItemCreate from '@/views/Admin/pages/Menus/pages/itemPages/itemCreate.vue'
import itemModifier from '@/views/Admin/pages/Menus/pages/itemPages/itemModifier.vue'
import EditModgroupItem from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/EditModgroupItem.vue'
import ModgroupModifiers from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/ModgroupModifiers.vue'
import ModgroupModifierItem from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/ModgroupModifierItem.vue'

import Report from '@/views/Admin/pages/Reports/Reports.vue'
import ReportGeneral from '@/views/Admin/pages/Reports/pages/General.vue'
import ReportOrders from '@/views/Admin/pages/Reports/pages/Orders.vue'
import ReportLocations from '@/views/Admin/pages/Reports/pages/Locations.vue'
import ReportCustomers from '@/views/Admin/pages/Reports/pages/Customers.vue'
import ReportProducts from '@/views/Admin/pages/Reports/pages/Products.vue'

import Locations from '@/views/Admin/pages/Locations/Locations.vue'
import LocationsMap from '@/views/Admin/pages/Locations/LocationsMap.vue'

import Settings from '@/views/Admin/pages/Settings/Settings.vue'
import Concepts from '@/views/Admin/pages/Settings/pages/Concepts.vue'
import Globals from '@/views/Admin/pages/Settings/pages/Globals.vue'
import Facilities from '@/views/Admin/pages/Settings/pages/Facilities.vue'
import Allergens from '@/views/Admin/pages/Settings/pages/Allergens.vue'
import OrderStatuses from '@/views/Admin/pages/Settings/pages/OrderStatuses.vue'
import QueueBuster from '@/views/Admin/pages/QROrdering/QueueBuster.vue'
import IntegrationSettings from '@/views/Admin/pages/Settings/pages/IntegrationSettings.vue'

import NotFound from '@/views/General/NotFound.vue'

import Apps from "@/views/Admin/pages/Apps/Apps.vue";
import EditAppItem from "@/views/Admin/pages/Apps/EditAppItem.vue"
import CreateApp from "@/views/Admin/pages/Apps/CreateApp.vue"
import AppsItemList from "@/views/Admin/pages/Apps/AppItems.vue"

import Test from "@/views/Admin/pages/Customers/test.vue"
import Customers from "@/views/Admin/pages/Customers/Customers.vue"
import Employees from "@/views/Admin/pages/Employees/Employees.vue"
import CustomerItem from "@/views/Admin/pages/Customers/components/CustomerItem.vue"
import CustomerSingle from "@/views/Admin/pages/Customers/components/CustomerSingle.vue"
import Blacklists from "@/views/Admin/pages/Customers/Blacklists.vue"
import BlacklistList from "@/views/Admin/pages/Customers/components/BlacklistList.vue"
import Loyalty from "@/views/Admin/pages/Customers/Loyalty.vue"

import store from '@/store'

import Messaging from "@/views/Admin/pages/Messaging/Messaging.vue";
import Notification from "@/views/Admin/pages/Messaging/pages/Notification.vue";
import MessagingStatuses from "@/views/Admin/pages/Messaging/pages/OrderStatuses.vue";
import StarRatings from "@/views/Admin/pages/Messaging/pages/StarRatings.vue";
import OAuthCallback from "@/views/Landing/pages/OAuthCallback.vue";
import IntegrationProviderRedirect from "@/views/Landing/pages/IntegrationProviderRedirect.vue";

import auth from '@/middleware/auth'
import log from '@/middleware/log'
import admin from '@/middleware/admin'
import subscription from '@/middleware/subscription'
import SlidingBannerIndex from "@/views/Admin/pages/CMS/banners/SlidingBannerIndex.vue";
import SlidingBanners from "@/views/Admin/pages/CMS/banners/SlidingBanners.vue";
import EditBannerItem from "@/views/Admin/pages/CMS/banners/EditBannerItem.vue";
import CreateBannerItem from "@/views/Admin/pages/CMS/banners/CreateBannerItem.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage,
    meta: {
      middleware: [auth, subscription],
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          noBodyBackground: true,
          isLoginPage: true,
        }
      },
      {
        path: '/register/:planId?',
        name: 'register',
        component: Register,
        meta: {
          noBodyBackground: true,
          isLoginPage: true,
        },
      },
      {
        path: '/customer-subscription/:planId?',
        name: 'customer-subscription',
        component: CustomerSubscription,
        meta: {
          noBodyBackground: true,
          isLoginPage: true,
          middleware: [auth, admin, log, subscription],
        }
      },
      {
        path: '/payment/:id',
        name: 'stripePayment',
        component: CustomerPayment,
        meta: {
          middleware: [auth, admin, log],
          hideFooter: true
        }
      },
    ]
  },
  {
    path: '/oauth/callback',
    name: 'oauthCallback',
    component: OAuthCallback,
    meta: {
      noBodyBackground: true,
      isLoginPage: true,
    }
  },
  {
    path: '/settings/integrations/:provider',
    name: 'IntegrationProviderRedirect',
    component: IntegrationProviderRedirect,
    meta: {
      noBodyBackground: true,
      isLoginPage: true,
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      hideFooter: true
    },
    children: [
      {
        path: '/kiosks',
        name: 'Kiosks',
        component: () => import('@/views/Admin/pages/Kiosks/Kiosks.vue'),
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/prices',
        name: 'prices',
        component: Prices,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/account',
        name: 'account',
        component: Account,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/promotions',
        name: 'Promotions',
        component: Promotions,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        redirect: '/promotions/list',
        children: [
          {
            path: '/promotions/list',
            name: 'PromotionsList',
            component: PromotionsList,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/promotions/create',
            name: 'PromotionsCreate',
            component: PromotionsCreate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/promotions/edit/:id',
            name: 'PromotionsEdit',
            component: PromotionsCreate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
      {
        path: '/segments',
        name: 'Segments',
        component: Segments,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        redirect: '/segments/list',
        children: [
          {
            path: '/segments/list',
            name: 'SegmentsList',
            component: SegmentsList,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/segments/create',
            name: 'SegmentsCreate',
            component: SegmentsCreate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/segments/edit/:id',
            name: 'SegmentsUpdate',
            component: SegmentsUpdate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
      {
        path: '/coupon-groups',
        name: 'CouponGroups',
        component: Coupons,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        redirect: '/coupon-groups/list',
        children: [
          {
            path: '/coupon-groups/list',
            name: 'CouponGroupsList',
            component: CouponGroupsList,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/coupon-groups/coupon-list/:id',
            name: 'CouponGroupsCouponList',
            component: CouponGroupsCouponList,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/coupon-groups/coupon-create/:id',
            name: 'CouponGroupsCouponCreate',
            component: CouponGroupsCouponCreate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/coupon-groups/coupon-edit/:id/coupon/:couponId',
            name: 'CouponGroupsCouponEdit',
            component: CouponGroupsCouponCreate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
      {
        path: '/digital-coupons',
        name: 'CouponGroups',
        component: Coupons,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        redirect: '/digital-coupons/list',
        children: [
          {
            path: '/digital-coupons/list',
            name: 'CouponGroupsCouponList',
            component: CouponGroupsCouponList,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/digital-coupons/create',
            name: 'CouponGroupsCouponCreate',
            component: CouponGroupsCouponCreate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/digital-coupons/edit/:couponId',
            name: 'CouponGroupsCouponEdit',
            component: CouponGroupsCouponCreate,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
      {
        path: '/cms',
        name: 'cms',
        component: SlidingBannerIndex,
        meta: {
          hideFooter: true
        },
        children: [
            {
            path: '/cms/banners/list',
            name: 'list',
            component: SlidingBanners,
            meta: {
              hideFooter: true
            }
          },
          {
            path: '/cms/banners/edit/:banner_id/slides/:slide_id',
            name: 'edit',
            component: EditBannerItem,
            meta: {
              hideFooter: true
            }
          },
          {
            path: '/cms/banners/create/:id',
            name: 'create',
            component: CreateBannerItem,
            meta: {
              hideFooter: true
            }
          },
        ],
      },
      {
        path: '/apps',
        name: 'apps',
        component: Apps,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        children: [
          {
            path: '/apps',
            name: 'list',
            component: AppsItemList,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/cms/sliding-banners/:bannerID/slides/:slidesID',
            name: 'update-slide',
            component: SlidingBannerSlides,
          },
          {
            path: '/apps/:id',
            name: 'edit-app',
            component: EditAppItem,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/apps/create',
            name: 'create-app',
            component: CreateApp,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
      {
        path: '/qr-ordering',
        name: 'qr-ordering',
        component: QueueBuster,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
      },
      {
        path: '/test',
        name: 'test',
        component: Test,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
      },
      {
        path: '/customers',
        name: 'customers',
        component: Customers,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        children: [
          {
            path: '/customers',
            name: 'list',
            component: CustomerItem,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/customers/:id',
            name: 'details',
            component: CustomerSingle,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          }
        ]
      },
      {
        path: '/employees',
        name: 'employees',
        component: Employees,
        meta: {
          middleware: [auth, admin, log],
          hideFooter: true
        },
      },
      {
        path: '/blacklists',
        name: 'blacklists',
        component: Blacklists,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        children: [
          {
            path: '/blacklists',
            name: 'list',
            component: BlacklistList,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          // {
          //   path: '/blacklists/:id',
          //   name: 'blacklist-single',
          //   component: BlacklistItem,
          //   meta: {
          //     hideFooter: true
          //   }
          // }
        ]
      },
      {
        path: '/loyalty',
        name: 'loyalty',
        component: Loyalty,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        children: [
          {
            path: '/loyalty',
            name: 'loyalty-item',
            component: LoyaltyItem,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },

        ]
      },
      {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/curbside',
        name: 'curbside',
        component: Curbside,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: Feedback,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        children: [
          {
            path: '/feedback',
            name: 'feedback-list',
            component: FeedbackItem,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
      {
        path: '/reports',
        name: 'reports',
        component: Report,
        redirect: '/reports/general',
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        },
        children: [
          {
            path: '/reports/general',
            name: 'reports_general',
            component: ReportGeneral,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/reports/orders',
            name: 'reports_orders',
            component: ReportOrders,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/reports/locations',
            name: 'reports_locations',
            component: ReportLocations,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/reports/customers',
            name: 'reports_customers',
            component: ReportCustomers,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/reports/products',
            name: 'reports_products',
            component: ReportProducts,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          }
        ]
      },
      {
        path: '/messaging',
        name: 'messaging',
        component: Messaging,
        redirect: '/messaging/notification',
        meta: {
          middleware: [auth, admin, log, subscription],
        },
        children: [
          {
            path: '/messaging/notification',
            name: 'messaging_notification',
            component: Notification,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/messaging/order-statuses',
            name: 'messaging_order_statuses',
            component: MessagingStatuses,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/messaging/star-ratings',
            name: 'star-ratings',
            component: StarRatings,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
      {
        path: '/orders/:id',
        name: 'order-details',
        component: Order,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/Locations',
        name: 'locations',
        component: Locations,
        children: [
          {
            path: '/Locations',
            name: 'store locations',
            component: LocationsMap,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          }
        ],
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: true
        }
      },
      {
        path: '/menus',
        name: 'menus',
        component: Menus,
        meta: {
          middleware: [auth, admin, log, subscription],
        },
        children: [
          {
            path: '/menus/:id',
            name: 'menus',
            component: Menu,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/menus/:id/categories/:catId/:tab?',
            name: 'MenuCategory',
            component: MenuCategory,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/menus/:id/categories/:catId',
            name: 'updatecategory',
            component: MenuUpdateCategory,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/menus/:id/categories/:catId/items',
            name: 'items',
            component: MenuItems,
            redirect: '/menus/:id/categories/:catId/items/list',
            children: [
              {
                path: '/menus/:id/categories/:catId/items/list',
                name: 'itemlist',
                component: ItemList,
                meta: {
                  middleware: [auth, admin, log, subscription],
                  hideFooter: true
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/create',
                name: 'itemCreate',
                component: ItemCreate,
                meta: {
                  middleware: [auth, admin, log, subscription],
                  hideFooter: true
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:tab?',
                name: 'itemModifier',
                component: itemModifier,
                meta: {
                  middleware: [auth, admin, log, subscription],
                  hideFooter: true
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:modifierId/update',
                name: 'EditModgroupItem',
                component: EditModgroupItem,
                meta: {
                  middleware: [auth, admin, log, subscription],
                  hideFooter: true
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:modifierId/items',
                name: 'modifier',
                component: ModgroupModifiers,
                meta: {
                  middleware: [auth, admin, log, subscription],
                  hideFooter: true
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:modifierId/items/:modifierGroupId',
                name: 'modifierGroupItem',
                component: ModgroupModifierItem,
                meta: {
                  middleware: [auth, admin, log, subscription],
                  hideFooter: true
                }
              },
              {
                path: '/menus/:id/upsells',
                name: 'MenuUpsells',
                component: MenuUpsells,
                meta: {
                  middleware: [auth, admin, log, subscription],
                  hideFooter: true
                }
              }
            ],
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          }
        ]
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
          middleware: [auth, admin, log, subscription],
        },
        children: [
          {
            path: '/settings/concepts',
            name: 'SettingsConcepts',
            component: Concepts,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/settings/globals',
            name: 'globals',
            component: Globals,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/settings/facilities',
            name: 'facilities',
            component: Facilities,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/settings/allergens',
            name: 'allergens',
            component: Allergens,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/settings/order-statuses',
            name: 'OrderStatuses',
            component: OrderStatuses,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
          {
            path: '/settings/integrations',
            name: 'Integration-Settings',
            component: IntegrationSettings,
            meta: {
              middleware: [auth, admin, log, subscription],
              hideFooter: true
            }
          },
        ]
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
})

function nextFactory(context: { from?: import("vue-router").Route; next: any; router?: VueRouter; to?: import("vue-router").Route }, middleware: { [x: string]: any }, index: number) {
  const subsequentMiddleware = middleware[index]

  if (!subsequentMiddleware) return context.next

  return (...parameters: any) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()

})

export default router

