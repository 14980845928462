import BaseService from '../base.service'
import store from '@/store'

export default class SettingsQueueBusterService extends BaseService {
    url!: string

    constructor() {
        super();
    }
}
