var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"modgroup",attrs:{"cols":"12"}},[_c('notifications'),_c('b-form',{attrs:{"id":"themeApp"},on:{"submit":function($event){$event.preventDefault();return _vm.updateTheme()}}},[_c('b-row',{staticClass:"justify-content-md-center my-auto align-middle"},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'social-ordering' ||'mobile'),expression:"type === 'social-ordering' ||'mobile'"}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Primary Color'))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md","aria-label":"test"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.s_prim_color),callback:function ($$v) {_vm.s_prim_color=$$v},expression:"s_prim_color"}})],1)],1)],1),_c('b-col',{class:("col-sm-2 col-md-2 col-lg-" + (_vm.type === 'social-ordering' ? '2' : '3') + " col-xl-" + (_vm.type === 'social-ordering' ? '2' : '3') + " my-auto pl-0")},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"bottom","trigger-style":{
                    width: '60px',
                    height: '60px',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                  }},model:{value:(_vm.s_prim_color),callback:function ($$v) {_vm.s_prim_color=$$v},expression:"s_prim_color"}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'social-ordering' ||'mobile'),expression:"type === 'social-ordering' ||'mobile'"}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Secondary Color'))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.s_sec_color),callback:function ($$v) {_vm.s_sec_color=$$v},expression:"s_sec_color"}})],1)],1)],1),_c('b-col',{class:("col-sm-2 col-md-2 col-lg-" + (_vm.type === 'social-ordering' ? '2' : '3') + " col-xl-" + (_vm.type === 'social-ordering' ? '2' : '3') + " my-auto pl-0")},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"bottom","trigger-style":{
                    width: '60px',
                    height: '60px',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                    }},model:{value:(_vm.s_sec_color),callback:function ($$v) {_vm.s_sec_color=$$v},expression:"s_sec_color"}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'mobile'),expression:"type === 'mobile'"}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Button 1 Color')))]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.form.button1_color),callback:function ($$v) {_vm.$set(_vm.form, "button1_color", $$v)},expression:"form.button1_color"}})],1)],1)],1),_c('b-col',{staticClass:"col-sm-2 col-md-2 col-lg-3 col-xl-3 my-auto pl-0"},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"right","trigger-style":{
                    width: '60px',
                    height: '60px',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                  }},model:{value:(_vm.form.button1_color),callback:function ($$v) {_vm.$set(_vm.form, "button1_color", $$v)},expression:"form.button1_color"}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'mobile'),expression:"type === 'mobile'"}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Button 2 Color')))]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.form.button2_color),callback:function ($$v) {_vm.$set(_vm.form, "button2_color", $$v)},expression:"form.button2_color"}})],1)],1)],1),_c('b-col',{staticClass:"col-sm-2 col-md-2 col-lg-3 col-xl-3 my-auto pl-0"},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"right","trigger-style":{
                    width: '60px',
                    height: '60px',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                    }},model:{value:(_vm.form.button2_color),callback:function ($$v) {_vm.$set(_vm.form, "button2_color", $$v)},expression:"form.button2_color"}})],1)],1)],1)],1),_c('b-row',{staticClass:"justify-content-md-center my-auto align-middle"},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'mobile' ),expression:"type === 'mobile' "}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Primary Text Color')))]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.form.primary_text_color),callback:function ($$v) {_vm.$set(_vm.form, "primary_text_color", $$v)},expression:"form.primary_text_color"}})],1)],1)],1),_c('b-col',{staticClass:"col-sm-2 col-md-2 col-lg-3 col-xl-3 my-auto pl-0"},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"right","trigger-style":{
                    width: '60px',
                    height: '60px',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                  }},model:{value:(_vm.form.primary_text_color),callback:function ($$v) {_vm.$set(_vm.form, "primary_text_color", $$v)},expression:"form.primary_text_color"}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'mobile' ),expression:"type === 'mobile' "}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Secondary Text Color')))]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.form.secondary_text_color),callback:function ($$v) {_vm.$set(_vm.form, "secondary_text_color", $$v)},expression:"form.secondary_text_color"}})],1)],1)],1),_c('b-col',{staticClass:"col-sm-2 col-md-2 col-lg-3 col-xl-3 my-auto pl-0"},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"right","trigger-style":{
                  width: '60px',
                  height: '60px',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  borderRadius: '6px',
                  boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                  }},model:{value:(_vm.form.secondary_text_color),callback:function ($$v) {_vm.$set(_vm.form, "secondary_text_color", $$v)},expression:"form.secondary_text_color"}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'mobile' ),expression:"type === 'mobile' "}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Button 1 Text Color')))]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.form.button1_text_color),callback:function ($$v) {_vm.$set(_vm.form, "button1_text_color", $$v)},expression:"form.button1_text_color"}})],1)],1)],1),_c('b-col',{staticClass:"col-sm-2 col-md-2 col-lg-3 col-xl-3 my-auto pl-0"},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"right","trigger-style":{
                    width: '60px',
                    height: '60px',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                  }},model:{value:(_vm.form.button1_text_color),callback:function ($$v) {_vm.$set(_vm.form, "button1_text_color", $$v)},expression:"form.button1_text_color"}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'mobile' ),expression:"type === 'mobile' "}]},[_c('b-row',[_c('b-col',{staticClass:"col-sm-7"},[_c('label',{staticClass:"color-label"},[_vm._v(_vm._s(_vm.translate('Button 2 Text Color')))]),_c('b-form-group',{staticClass:"box-input"},[_c('b-input-group',{attrs:{"size":"md"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('i',{staticClass:"fas fa-paste"})]),_c('b-form-input',{staticClass:"color-input",model:{value:(_vm.form.button2_text_color),callback:function ($$v) {_vm.$set(_vm.form, "button2_text_color", $$v)},expression:"form.button2_text_color"}})],1)],1)],1),_c('b-col',{staticClass:"col-sm-2 col-md-2 col-lg-3 col-xl-3 my-auto pl-0"},[_c('i',{class:((_vm.type === 'social-ordering' ? 'custom-pencil' : 'custom-pencil-app') + " fas fa-pencil-alt")}),_c('v-swatches',{attrs:{"show-fallback":"","fallback-input-type":"color","popover-x":"right","trigger-style":{
                    width: '60px',
                    height: '60px',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    borderRadius: '6px',
                    boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)'
                    }},model:{value:(_vm.form.button2_text_color),callback:function ($$v) {_vm.$set(_vm.form, "button2_text_color", $$v)},expression:"form.button2_text_color"}})],1)],1)],1)],1),_c('b-row',{staticClass:"float-right"},[_c('base-button',{staticClass:"ml-auto",attrs:{"type":"warning"}},[_vm._v(_vm._s(_vm.translate('Reset Changes')))]),_c('base-button',{attrs:{"type":"primary","native-type":"submit"}},[(_vm.saving)?_c('div',[_c('i',{staticClass:"fas fa-spinner fa-spin"}),_vm._v(_vm._s(_vm.translate('Saving...'))+" ")]):_c('span',[_vm._v(_vm._s(_vm.translate('Apply Changes')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }