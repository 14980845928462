










































































































// npm packages
import { Component, Vue } from 'vue-property-decorator';
import { DatePicker, TimeSelect } from 'element-ui';
import { mapGetters } from 'vuex';
import moment from 'moment';
import timezone from 'moment-timezone';
// our packages
import { Concept as ConceptService, Messaging } from '@/services/SOLO';
import TabHeader from '@/views/Admin/pages/Messaging/components/TabHeader';
import { translations } from '@/mixins'
import Translations from './Translations.vue'

interface DataObject {
  selected_segment: string;
  title_ar_sa: string;
  title_en_us: string;
  message_ar_sa: string;
  message_en_us: string;
  is_pushed: boolean;
  schedule_date: any;
  schedule_time: any;
}

interface SimpleItem {
  value: string;
  text: string;
}

@Component({
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      count: 'messaging/getDeviceCount',
      segments: 'messaging/getSegments',
    }),
  },
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    TabHeader,
  },
  mixins: [translations],
})
export default class TabCompose extends Vue {
  count!: number;
  segments!: Array<any>;
  data: DataObject = {
    selected_segment: '',
    title_ar_sa: '',
    title_en_us: '',
    message_ar_sa: '',
    message_en_us: '',
    is_pushed: false,
    schedule_date: '',
    schedule_time: '',
  };
  public translate!: Function
  options: SimpleItem[] = [
    { value: '', text: 'All Customers' },
  ];
  selectedSegment: string = '';
  currentConcept: string = '';

  created() {
    this.getCurrentConcept();
  }

  async getCurrentConcept() {
    await ConceptService.default().then((response: any) => {
      this.currentConcept = response.data.data.attributes.label;
    })
  }

  onSendPushNotification() {
    const tz = timezone.tz.guess();
    const r = confirm(`Are you sure you want to send to ${this.count} customers on the ${this.currentConcept} app?`);
    let scheduled_date = null;
    if (r) {
      let formData = new FormData();
      formData.append('segment_id', this.data.selected_segment);
      formData.append('title[ar-sa]', this.data.title_ar_sa);
      formData.append('title[en-us]', this.data.title_en_us);
      formData.append('message[ar-sa]', this.data.message_ar_sa);
      formData.append('message[en-us]', this.data.message_en_us);

      if (this.data.is_pushed) {
        scheduled_date = this.data.schedule_date.toString().replace('00:00:00', `${this.data.schedule_time}:00`);
        scheduled_date = timezone(scheduled_date).tz(tz).format('YYYY-MM-DD HH:mm:ss');
      } else {
        scheduled_date = timezone().tz(tz).format('YYYY-MM-DD HH:mm:ss');
      }
      formData.append('scheduled_date', scheduled_date);
      Messaging.sendPushNotification(formData)
        .then(() => {
          alert('Notification was sent successfully.');

          this.data.selected_segment = '';
          this.data.title_ar_sa = '';
          this.data.message_ar_sa = '';
          this.data.title_en_us = '';
          this.data.message_en_us = '';
          this.data.schedule_date = '';
          this.data.schedule_time = '';
          this.data.is_pushed = false;
        })
        .catch((error) => console.log('error: ', error));
    }
  }
}
