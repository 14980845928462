













import { Integration as Integrated } from '@/models';
import IntegratedItems from './components/integrations/IntegratedItems'
import IntegrationAvailable from './components/integrations/IntegrationAvailable'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'

import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import { Integrations, Location, Concept } from '@/services/SOLO'

@Component({
  components: {
    IntegratedItems,
    IntegrationAvailable,
    RouteBreadCrumb
  }
})

export default class IntegrationSettings extends Vue {
  integrations: Array<Integrated> = []
  providers: Array<Integrated> = []

  mounted() {
    this.getIntegrations()
    this.getProviders()
  }

  async getIntegrations() {
    await Integrations.getAllIntegrations()
        .then((response: any) => {
          this.integrations = response.data.data
        })
  }

  async getProviders () {
    await Integrations.getAllProviders()
        .then((response: any) => {
          let availableProviders = response.data.data
          this.providers = availableProviders.filter((available: any) => {
            let integrations = this.integrations.some(providers => providers.attributes.provider === available.attributes.label)
            if (!integrations) {
              return available
            }
          })
        })
  }
}
