

























import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
    }),
  },
})
export default class LocationItems extends Vue {
  @Prop() drivers!: Array<Object>
  @Prop() included!: Array<Object>

  private getLocale!: Function;

  orderCount(orders: number) {
    if(orders > 0) {
      return orders + ` Orders`
    }    
    return `No Orders`
  }

  private pan(driverData: any) {    
    console.log(driverData)    
    if(driverData.relationships.bearing.data) {      
      
      let driverId = driverData.relationships.bearing.data.id

      let bearing: any = this.included.filter((include: any) => {                
        return include.id  === driverId && include.type == 'bearing'
      })
      
      if(bearing.length) {        
        if(bearing[0].attributes.lat && bearing[0].attributes.long) {
          let payload = {
            lat: bearing[0].attributes.lat,
            long: bearing[0].attributes.long
          }
          console.log(payload)
          this.$emit('pan', payload)
        }
      }

    }            
  }
}
