









































































































































































































import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Employee } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue'
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";

interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string
}

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
  },
mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openCreate: boolean = false;
  @Prop() employee?: Object;
  public translate!: Function;
  id: any;
  isOpen: boolean = false
  // modalForm: any =  {
  //   'employee-id': '9999',
  //   username: 'calvingferrando',
  //   'first-name': 'Calvin',
  //   'last-name': 'Ferrando',
  //   password: 'A1asdasd',
  //   email: 'cferrando@skylinedynamics.com',
  //   mobile: '09175770388',
  //   roles: null,
  //   locations: null,
  // };
  modalForm: any =  {
    'employee-id': null,
    username: null,
    'first-name': null,
    'last-name': null,
    password: null,
    email: null,
    mobile: null,
    roles: [],
    locations: [],
  };
  roles: any = [];
  locations: any = [];
  private req: Function = (rule: any, value: any, callback: any) => {
    console.log('validator: ', value);
    if (!value) {
      callback(new Error('This field is required'));
    } else {
      callback();
    }
  };
  private reqValidator = [
    {
      validator: this.req,
      trigger: 'blur',
    }
  ];
  rules = {
    ['employee-id']: this.reqValidator,
    username: this.reqValidator,
    ['first-name']: this.reqValidator,
    ['last-name']: this.reqValidator,
    password: this.reqValidator,
    email: this.reqValidator,
    mobile: this.reqValidator,
    roles: this.reqValidator,
    locations: this.reqValidator,
  };
  successNotification!: Function;
  systemErrorNotification!: Function;
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  mounted() {
    Employee.getRoles().then((response) => {
      let roles = response.data.data;
      this.roles = roles.map((r: any) => {
        return {
          key: r.attributes.name,
          value: r.attributes.name,
        };
      });
      console.log('roles: ', this.roles);
    });
    Employee.getLocations().then((response) => {
      let locations = response.data.data;
      this.locations = locations.map((l: any) => {
        return {
          key: l.id,
          value: l.attributes.name,
        };
      });
    });
  }
  @Watch('openCreate', { deep: true })
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }

  handleClose() {
    this.clearForm();
    this.$emit('handleClose', false);
  }

  clearForm() {
    this.modalForm['employee-id'] = null;
    this.modalForm.username = null;
    this.modalForm['first-name'] = null;
    this.modalForm['last-name'] = null;
    this.modalForm.password = null;
    this.modalForm.email = null;
    this.modalForm.mobile = null;
    this.modalForm.roles = [];
    this.modalForm.locations = [];
  }

  onCreateEmployee(e: HTMLFormElement, invalid: boolean) {
    if (!invalid) {
      console.log('this.modalForm: ', this.modalForm);
      const payload: any = { ...this.modalForm };
      console.log('payload: ', payload);
      Employee.create(payload)
        .then(() => {
          this.clearForm();
          this.$emit('handleClose', false);
          this.$emit('updateList', true);
          this.successNotification('EMPLOYEE CREATED!', 'Employee successfully created!');
        })
        .catch((err: any) => {
          console.log('response: ', err.response);
          let error = '';
          for (const prop in err.response.data.error) {
            err.response.data.error[prop].forEach((e: any) => {
              error += e + '\n';
            })
          }
          console.log('error: ', error);
          this.systemErrorNotification('CREATE ERROR!', error);
        });
    }
  }
}
