





























































































import {Select, Option} from "element-ui";
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Applications, Menu, Slider} from "@/services/SOLO";
import {translations} from '@/mixins'
import {mapGetters} from 'vuex'

interface AppDetailsForm {
  application_type?: any
  key?: any
  label?: any
  default_menu_id?: any
  hosts?: any
  slider_id?: any
  'menu-only'?: boolean
  'payment-gateway'?: string | any
  'allowed-order-types'?: string | any
  'allowed-payments-pickup'?: string | any
  'allowed-payments-delivery'?: string | any
  theme: {
    primary_color?: string | any
    secondary_color?: string | any
  }
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  computed: {
    ...mapGetters({
      getConceptSettings: "account/getConceptSettings",
    }),
  },
  mixins: [translations]
})
export default class AppDetails extends Vue {
  getConceptSettings!: Array<typeof mapGetters>
  @Prop() details!: any
  saving: boolean = false
  $notify: any
  defaultMenus: any = ''
  sliders: any = ''

  form: AppDetailsForm = {
    application_type: '',
    key: '',
    label: '',
    default_menu_id: null,
    hosts: '',
    slider_id: null,
    'menu-only': false,
    'payment-gateway': '',
    'allowed-order-types': '',
    'allowed-payments-pickup': '',
    'allowed-payments-delivery': '',
    theme: {
      primary_color: '',
      secondary_color: '',
    }
  }

  applicantDetails: any = {}
  appMenu: any = ''
  appSlider: any = ''
  appDefaultMenu: any = ''
  appDefaultSlider: any = ''
  defaultData: any = {}
  selectedMenu: any = []
  selectedSlider: any = []
  getPackageType: any = {}
  isFreePackage: any = {}
  translate!: Function

  @Watch('details', {deep: true})
  onDetailsChanged(newVal: any) {
    let data = newVal.attributes
    this.processApplicantDetails(data)
    this.getMenus()
    this.getSliders()
    this.defaultData = data

    this.getPackageType = this.getConceptSettings
    this.isFreePackage = this.getPackageType.attributes['is-free-package']
    this.form['menu-only'] = !!this.isFreePackage
  }

  async getMenus() {
    await Menu.all()
        .then((response: any) => {
          this.defaultMenus = response.data.data
          this.processMenus(this.defaultMenus)
        })
  }

  async getSliders() {
    await Slider.all()
        .then((response: any) => {
          this.sliders = response.data.data
          this.processSliders(this.sliders)
        })
  }

  processApplicantDetails(data: any) {
    this.form.application_type = data['application-type']
    this.form.key = data['key']
    this.form.label = data['label']
    this.form.hosts = data['hosts']
    this.form.default_menu_id = data['default-menu-id']
    this.form.slider_id = data['slider-id']
    this.form['menu-only'] = data['menu-only']
  }

  processMenus(data: any) {
    let payload = data
    this.appMenu = payload
    payload.filter((v: any) => {
      return v.attributes.code != null
    })
        .reduce((a: any, b: any) => {
          this.appDefaultMenu = b.attributes.code == this.form.default_menu_id
              ? b.attributes.label : null
        }, [])

    this.appDefaultMenu = this.appMenu.filter((active: any) => active.id == this.form.default_menu_id)
    // this.selectedMenu = this.appDefaultMenu[0].attributes.label
    this.selectedMenu = this.appDefaultMenu[0].id
  }

  processSliders(data: any) {
    let payload = data
    this.appSlider = payload
    payload.filter((v: any) => v.attributes.label)
        .reduce((a: any, b: any) => {
          if (b.id == this.form.slider_id) {
            this.appDefaultSlider = b.attributes.label
            return true
          }
        }, [])

    this.appDefaultSlider = this.appSlider.filter((active: any) => active.id == this.form.slider_id)
    this.selectedSlider = this.appDefaultSlider[0].attributes.label
  }

  updateApp() {
    this.saving = true
    this.form.default_menu_id = this.selectedMenu
    this.form.slider_id = this.selectedSlider

    Applications.updateApplication(Number(this.$route.params.id), this.form)
        .then((response: any) => {
          this.$notify({
            title: this.translate("RECORD UPDATED!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate("Application details has been updated successfully"),
            type: "success",
            icon: "fas fa-check"
          })
          this.saving = false
        })
  }

  resetForm() {
    this.form.application_type = this.defaultData['application-type']
    this.form.key = this.defaultData['key']
    this.form.label = this.defaultData['label']
    this.form.hosts = this.defaultData['hosts']
    this.form['default_menu_id'] = this.defaultData.default_menu_id
    this.form.slider_id = this.defaultData.slider_id

    return
  }
}

