








































import {
  Component,
  Vue
} from 'vue-property-decorator'
import { mapGetters } from 'vuex';
@Component({
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  }
})
export default class App extends Vue {
  private getLocale!: Function
}
