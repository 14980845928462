import config from './config'
import store from '@/store'
import { Headers } from '@/interfaces/SOLO'
import axios, { AxiosInstance, AxiosResponse } from 'axios';
let baseService = class BaseService {

    baseURL: any = process.env.VUE_APP_API_DOMAIN
    baseURL1: any = process.env.VUE_APP_API_DOMAIN
    translationURL: string = ''
    headers: Headers
    $http: AxiosInstance
    conceptId: any
    activeDomain: string = ''

    constructor() {

        this.headers = {
            'Content-Type': 'application/json',
            'Solo-Concept': '',
            'Accept-Language': store.getters['app/getLocale'].replace('_', '-').toLowerCase()
        }

        this.$http = axios.create({
            timeout: 1600000
        });

        this.$http.interceptors.request.use((config: any) => {
            return config
        })

        this.$http.interceptors.response.use((response: any) => {
            return response
        })
    }



    getHeaders(additionalHeaders = {}, multipart = false) {
        let defaultHeaders = this.headers;

        if (store.getters['account/isLoggedIn']) {
            defaultHeaders['Solo-Concept'] = store.getters['account/activeConcept'].key
            defaultHeaders['Authorization'] = 'Bearer ' + store.getters['account/getUser'].attributes.token
        }

        return {
            ...defaultHeaders,
            ...additionalHeaders
        }
    }


    get sendGridHeaders(): Headers {
        return {
            "content-type": "application/x-www-form-urlencoded",
        }
    }

    postMail(uri: string, data: {}, additionalHeaders = {}) {
        return this.$http.post(uri, data, {
            headers: this.getHeaders(this.sendGridHeaders),
        })
    }

    prepareUrl(url: string, params: any) {
        for (let index in params) {
            let identifier = ':' + index;
            url = url.replace(identifier, params[index]);
        }
        return url;
    }

    getQueryString(params: { [x: string]: string | number | boolean; }) {
        return (
            Object
                .keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&')
        )
    }

    post(uri: string, data: {}, additionalHeaders = {}) {
        return this.$http.post(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    put(uri: any, data: any, additionalHeaders = {}) {
        return this.$http.put(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    patch(uri: any, data: any, additionalHeaders = {}) {
        return this.$http.patch(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    remove(uri: any, data: any, additionalHeaders = {}) {
        return this.$http(uri, {
            method: 'DELETE',
            headers: this.getHeaders(additionalHeaders), 
            data: data
        })
    }

    get(uri: string, data = {}, additionalHeaders = {}) {
        if (Object.keys(data).length > 0) {
            uri = `${uri}?${this.getQueryString(data)}`
        }

        return this.$http.get(uri, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    mcxGet(uri: string, data = {}, additionalHeaders = {}) {
        if (Object.keys(data).length > 0) {
            uri = `${uri}?${this.getQueryString(data)}`
        }

        return this.$http(uri, {
            method: 'GET',
            headers: {
                'Accept-Ranges': 'bytes',
                'Content-Type': 'application/json',
                'Connection': 'keep-alive'
            },
        })
    }
};

export default baseService
