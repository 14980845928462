




































// npm package
import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';

// our package
import { translations } from '@/mixins';
import Translations from './Translations.vue';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';

@Component({
  components: {
    RouteBreadCrumb,
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [translations],
})
export default class Reports extends Vue {
  public getLocale!: string;
  public btnLinks: Array<any> = [];

  private translate!: Function;
  created() {
    this.btnLinks = [
      {
        btnName: this.translate('General'),
        btnRouteName: 'reports_general',
        btnPath: '/reports/general',
      },
      {
        btnName: this.translate('Orders'),
        btnRouteName: 'reports_orders',
        btnPath: '/reports/orders',
      },
      {
        btnName: this.translate('Locations'),
        btnRouteName: 'reports_locations',
        btnPath: '/reports/locations',
      },
      {
        btnName: this.translate('Customers'),
        btnRouteName: 'reports_customers',
        btnPath: '/reports/customers',
      },
      {
        btnName: this.translate('Products'),
        btnRouteName: 'reports_products',
        btnPath: '/reports/products',
      },
    ];
  }

  redirectTo(name: string) {
    this.$router.push({ name });
  }
}
