import BaseService from '../base.service'

export default class SettingsFacilitiesService extends BaseService {
  url!: string
  constructor() {
    super();
  }

  all(lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + 'devices'
    return super.get(this.url)
  }


  create(payload: object, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + 'devices'
    return super.post(this.url, payload)
  }

  update(id: string, payload: any = {}, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `devices/${id}`
    return this.patch(this.url, payload)
  }

  refreshMdm(lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `devices/mdm`
    return this.get(this.url)
  }

  
getMdmDeviceDetails(id: string, lang: string = 'en-us') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `devices/${id}/mdm`
    return this.get(this.url)
  }
}
