





























































import Modal from '@/components/Modal.vue'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Category as CategoryModel, CustomField, Menus as MenuModel } from "@/models"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import Translations from '../../../Translations.vue'
import { Settings } from '@/services/SOLO';
import { Ingredient } from '@/models'

interface nameObj {
  [x: string]: string
}

@Component({
  components: {
    Translations,
  },
  mixins: [translations, eventHandler]
})
export default class IngredientFormModal extends Vue {
  public translate!: Function
  open: boolean = false
  arrayLang: Array<string> = ["en-us", "ar-sa"]
  ingNameObj: nameObj = {}
  enabled: boolean = true
  ingredient: Ingredient = {
    name: {},
    'display-order': 1,
    code: '0',
    price: 0,
    enabled: 1,
    'image-uri': ''
  }

  waiting!: Function
  restore!: Function
  $notify: any

  constructor() {
    super()
    this.setName()
  }

  @Watch('enabled', { immediate: true, deep: true })
  isEnabled(newVal: boolean) {
    this.ingredient.enabled = newVal ? 1 : 0
  }

  get setData() {
    this.ingredient.name = this.ingNameObj
    return this.ingredient
  }

  setName() {
    for(let i in this.arrayLang) {
      this.ingNameObj[this.arrayLang[i]] = ''
    }
  }

  saveForm(e: HTMLFormElement, reset: any) {
    const defaultText = e.submitter.innerHTML
    this.waiting(e, `<i class="fas fa-spinner fa-spin"></i> ${this.translate('Updating...')}`)
    Settings.createIngredients(this.setData)
      .then((response: any) => {
        this.restore(e, defaultText)
        this.$emit('ingredient:created')
        this.$notify({
          title: this.translate('DATA SAVED'),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate('Global Ingredient has been saved!'),
          type: "success",
          icon: "fas fa-check",
        })
        this.setName()
        this.open = false
        reset()
      })
      .catch((err: any) => {
        this.restore(e, defaultText)
        this.$notify({
          title: this.translate('SYSTEM ERROR!'),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate('Something went wrong, please try again!'),
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}
