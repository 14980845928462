import { render, staticRenderFns } from "./IntegratedItems.vue?vue&type=template&id=102565f4&"
import script from "./IntegratedItems.vue?vue&type=script&lang=ts&"
export * from "./IntegratedItems.vue?vue&type=script&lang=ts&"
import style0 from "./IntegratedItems.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports