







































import EditAllergenItem from './EditAllergenItem'
import {Component, ProvideReactive, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Settings} from '@/services/SOLO'

@Component({
  components: {
    EditAllergenItem
  }
})


export default class AllergenItems extends Vue {
  @Prop() item!: Array<Object>
  allergens: Array<Object> = []

  mounted() {
    this.getAllergens()
  }

  getAllergens() {
    Settings.getAllergens()
        .then((response: any) => {
          this.allergens = response.data.data
        })
  }

  @Watch('item', { deep: true }) onItemChanged() {
    this.allergens.unshift(this.item);
  }
  // accept emit for deleting
  deleteAllergen(index: any) {
      let targetIndex = this.allergens.map((i: any) => {
          return i.id;
      }).indexOf(index);
      this.allergens.splice(targetIndex, 1);
  }
  // accept emit for updating a single record
  updateItemRecord(data: any) {
    this.allergens.map((i: any) => {
      if (i.id === data.id) {
        Object.keys(data.attributes).forEach(key => {
            return i.attributes[key] = data.attributes[key]
        });
      }
    })
  }
}
