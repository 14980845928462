









































































import {
  Applications,
  Banner as BannerApi
} from '@/services/SOLO'
import {eventHandler, translations} from '@/mixins'
import BannerSet from './components/BannerSetModal'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"

@Component({
  components: {
    BannerSet
  },
  mixins: [eventHandler, translations]
})
export default class SlidingBanners extends Vue {
  isBannerSetOpen: Boolean = false
  banners: Array<Object> = []
  sliders: Array<Object> = []
  translate!: Function
  $notify: any
  confirm!: Function

  mounted() {
    this.getBanner()
  }

  getBanner() {
    BannerApi.banners().then((response: any) => {
      this.banners = response.data.data
      this.sliders = response.data.included
      console.log(this.banners.length)
    });
  }

  addNewBannerSet(open: any) {
    this.isBannerSetOpen = open
  }

  get deleteMsgBoxText(): String {
    return "Are you sure you want to delete this Banner Set?";
  }

  removeBanner(bannerId: Number) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText).then(
      (value: boolean) => {
        if(value) {
          BannerApi.removeBannerSet(bannerId).then((response) => {
            this.getBanner();
            this.$notify({
              title: "BANNER DELETED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Banner Set has been deleted successfully",
              type: "success",
              icon: "fas fa-check",
            });
          });
        }
      }
    );
  }
}
