











































































































































































































































import { Select, Option } from "element-ui";
import { Menu as MenuApi, Location, Concept } from "@/services/SOLO";
import {
  Category as CategoryModel,
  Location as LocationModel,
  Application as ApplicationModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";

interface objKey {
  [x: string]: string | number;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  mixins: [eventHandler],
})
export default class ItemUpdate extends Vue {
  items: Array<any> = [];
  selectedLocations: any = [];
  disabledLocations: any = [];
  selectedApplications: any = [];
  defaultLocations: Array<LocationModel> = [];
  defaultApplications: Array<ApplicationModel> = [];
  locations: Array<LocationModel> = [];
  applications: Array<any> = [];
  arrayLang: Array<string> = ["en-us", "ar-sa"];
  itemNames: objKey = {};
  itemDescs: objKey = {};
  imageType: number = 0;
  $notify: any;
  numOfLoadedData: number = 0;
  file: any;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ];
  waiting!: Function;
  restore!: Function;

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {

  }
  @Watch("numOfLoadedData", { immediate: true, deep: true })
  onLoad(data: number) {
    if (data === this.arrayLang.length) {
      this.items[0].relationships.apps.data.map((data: any) => {
        this.selectedApplications.push(data.id);
      });

      this.items[0].relationships.locations.data.map((data: any) => {
        this.selectedLocations.push(data.id);
        this.disabledLocations.push(data.id);
      });
    }
  }
  mounted() {
    this.getApps();
    this.getLocations();
    this.initializeForms();
  }
  get isEnabled(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return this.items[0].attributes.enabled ? true : false;
    else return false;
  }
  set isEnabled(newVal: boolean) {
    this.items[0].attributes.enabled = newVal === true ? 1 : 0;
  }

  // disable-for-delivery
  get isEnabledDelivery(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return this.items[0].attributes['disable-for-delivery'] ? true : false;
    else return false;
  }
  set isEnabledDelivery(newVal: boolean) {
    this.items[0].attributes['disable-for-delivery'] = newVal === true ? 1 : 0;
  }

  // disable-for-pickup
  get isEnabledPickup(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return this.items[0].attributes['disable-for-pickup'] ? true : false;
    else return false;
  }
  set isEnabledPickup(newVal: boolean) {
    this.items[0].attributes['disable-for-pickup'] = newVal === true ? 1 : 0;
  }

  initializeForms() {
    for (let i in this.arrayLang) this.findItems(this.arrayLang[i]);
  }
  findItems(lang: string = "en-us") {
    MenuApi.items(
      this.$route.params.id,
      this.$route.params.catId,
      this.$route.params.itemId,
      lang
    ).then((response: any) => {
      let data = { ...response.data.data, ...{ lang: lang } }
      this.items.push(data)
      this.numOfLoadedData++
    });
  }
  getApps() {
    Concept.find().then((response: any) => {
      this.applications = response.data.data.map((data: any) => {
        return {
          id: data.id.toString(),
          label: data.label,
        };
      });
    });
  }
  getLocations() {
    Location.fetchLocations().then((response: any) => {
      this.locations = response.data.data;
    });
  }
  updateItem(e: HTMLFormElement, isvalid: boolean) {
    // console.log('selected: ', this.selectedLocations);
    // console.log('disabled: ', this.disabledLocations);
    // console.log('to be removed: ', this.disabledLocations.filter((d: any) => !this.selectedLocations.find((s: any) => s === d)));
    if (!isvalid) {
      if (!this.imageType) {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        MenuApi.updateItem(
          this.setData(),
          this.$route.params.id,
          this.$route.params.catId,
          this.$route.params.itemId
        )
          .then((response: any) => {
            const filtered = this.selectedLocations.filter((l: any) => !this.disabledLocations.find((k: any) => k === l));
            const toBeRemoved = this.disabledLocations.filter((d: any) => !this.selectedLocations.find((s: any) => s === d)) || [];
            const promiseArray = filtered.map((l: any) => {
              const payload = {
                items: [this.$route.params.itemId],
              };
              return Location.disableLocation(payload, l);
            });
            if (toBeRemoved.length) {
              toBeRemoved.forEach((t: any) => {
                promiseArray.push(Location.removeDisabledLocation(t, this.$route.params.itemId))
              });
            }

            return Promise.all(promiseArray);
          })
          .then(() => {
            this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            this.$router.go(-1)
            // location.reload()
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.error.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      } else {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        MenuApi.updateItemFormdata(
          this.formData(),
          this.$route.params.id,
          this.$route.params.catId,
          this.$route.params.itemId
        )
        .then((response: any) => {
          this.imageType = 0;
          this.items[0].attributes["image-uri"] =
            response.data.data.attributes["image-uri"];
          const filtered = this.selectedLocations.filter((l: any) => !this.disabledLocations.find((k: any) => k === l));
          const toBeRemoved = this.disabledLocations.filter((d: any) => !this.selectedLocations.find((s: any) => s === d)) || [];
          const promiseArray = filtered.map((l: any) => {
            const payload = {
              items: [this.$route.params.itemId],
            };
            if (toBeRemoved.length) {
              toBeRemoved.forEach((t: any) => {
                promiseArray.push(Location.removeDisabledLocation(t, this.$route.params.itemId))
              });
            }
            return Location.disableLocation(payload, l);
          });

          return Promise.all(promiseArray);
        })
        .then(() => {
          this.restore(e, defaultText);
          this.$notify({
            title: "UPDATES SAVED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Changes have been saved successfully",
            type: "success",
            icon: "fas fa-check",
          });
          location.reload()
        })
        .catch((err: any) => {
          this.restore(e, defaultText);
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Something went wrong, please try again!",
            type: "danger",
            icon: "fas fa-bomb",
          });
        });
      }
    }
  }
  formData() {
    let formData = new FormData();
    formData.append("code", this.items[0].attributes.code);
    formData.append(
      "display-order",
      this.items[0].attributes["display-order"].toString()
    );
    formData.append("enabled", this.items[0].attributes.enabled.toString());
    formData.append("price", this.items[0].attributes.price);
    formData.append("calories", this.items[0].attributes.calories);
    formData.append("disable-for-delivery", this.items[0].attributes['disable-for-delivery'])
    formData.append("disable-for-pickup", this.items[0].attributes['disable-for-pickup'])
    formData.append(
      "person-count-per-serving",
      this.items[0].attributes["person-count-per-serving"]
    );
    formData.append("calorie-count", this.items[0].attributes["calorie-count"]);

    for (let i in this.items) {
      formData.append(
        `name[${this.items[i].lang}]`,
        this.items[i].attributes.name ? this.items[i].attributes.name : ""
      );
      formData.append(
        `description[${this.items[i].lang}]`,
        this.items[i].attributes.description
          ? this.items[i].attributes.description
          : ""
      );
    }
    for (let i in this.selectedApplications) {
      formData.append(`apps[${i}]`, this.selectedApplications[i]);
    }
    for (let i in this.selectedLocations) {
      formData.append(`locations[${i}]`, this.selectedLocations[i]);
    }
    formData.append("image", this.$refs.file.files[0]);
    return formData;
  }
  setData() {
    console.log('menu to be updated')
    for (let i in this.items) {
      this.itemNames[this.items[i].lang] = this.items[i].attributes.name;
      this.itemDescs[this.items[i].lang] = this.items[i].attributes.description;
    }

    this.items[1].attributes.price = this.items[0].attributes.price

    return {
      code: this.items[0].attributes.code,
      "display-order": this.items[0].attributes["display-order"],
      enabled: this.items[0].attributes.enabled,
      name: this.itemNames,
      description: this.itemDescs,
      price: this.items[1].attributes.price,
      calories: this.items[0].attributes.calories,
      "person-count-per-serving": this.items[0].attributes[
        "person-count-per-serving"
      ],
      "calorie-count": this.items[0].attributes["calorie-count"],
      apps: this.selectedApplications,
      locations: this.selectedLocations,
      "image-uri": this.items[0].attributes["image-uri"],
      'disable-for-delivery': this.items[0].attributes['disable-for-delivery'],
      'disable-for-pickup': this.items[0].attributes['disable-for-pickup']
    };
  }
  filesChange(e: any, errors: any) {
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];
    }
  }
}
