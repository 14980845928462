
























import CustomerCollapse from "./components/CustomerCollapse.vue"
import CustomerList from "./components/CustomerList.vue"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {
    RouteBreadCrumb,
    CustomerList,
    CustomerCollapse
  }
})

export default class Customers extends Vue {}
