

















































import ModGroup from "./modifiercomponents/Modgroup.vue";
import GlobalIngredients from "./ingredients/GlobalIngredients.vue";
import GlobalAllergens from "./allergens/GlobalAllergens.vue";
import CustomFields from "./customfields/CustomFields.vue";
import ItemTimedEvents from "./timedevents/ItemTimedEvents.vue";
import ItemUpdate from "./ItemUpdate.vue";
import ComboMeals from "./combomeals/ComboMeals.vue"
import { ElNotification } from "element-ui/types/notification";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Menu } from "@/services/SOLO";
import { Item as ItemModel } from "@/models";
import { translations } from '@/mixins'

@Component({
  components: {
    ModGroup,
    ItemUpdate,
    GlobalIngredients,
    GlobalAllergens,
    CustomFields,
    ItemTimedEvents,
    ComboMeals
  },
  mixins: [translations]
})
export default class itemModifier extends Vue {
  tab: number = 1
  item: ItemModel = {
    attributes: {
      'is-combo': null
    },
    id: undefined,
    links: undefined,
    relationships: undefined,
    type: undefined
  };
  translate!: Function  

  mounted() {
    if (this.$route.params.hasOwnProperty("tab")) {
      this.tab = Number(this.$route.params.tab);
    }
    this.getItem();
  }

  get dropDownText(): string {
     if (this.tab === 1) {
       return this.translate('Modifier Groups')
     } else if (this.tab === 2) {
       return this.translate('Allergens')
     } else if (this.tab === 3) {
       return this.translate('Custom Fields')
     } else if (this.tab === 4) {
       return this.translate('Custom Fields')
     } else if (this.tab === 5) {
       return this.translate('Timed Events')
     } else if (this.tab === 6) {
       return this.translate('Update Item')
     } else {
       return this.translate('Combo Components')
     }                   
  }

  getItem() {
    Menu.items(
      this.$route.params.id,
      this.$route.params.catId,
      this.$route.params.itemId
    ).then((response) => {
      this.item = response.data.data;
      console.log(this.item);
    });
  }
}
