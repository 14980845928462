


































































































































import {
  Employee,
  Location,
  Concept as ConceptService,
  Integrations as IntegrationService,
} from "@/services/SOLO";
import SearchItem from "./components/SearchItem.vue";
import LocationItems from "./components/LocationItems.vue";
import DriverItems from "./components/DriverItems.vue";
import ChangeAreaNameModal from "./components/ChangeAreaNameModal.vue";
import LocationModal from "./components/LocationModal.vue";
import GoogleMapsLoader from "google-maps";
import {Component, Vue, Watch} from "vue-property-decorator";
import {notificationAlerts, translations, eventHandler} from "@/mixins";
import ConfirmCard from "./components/ConfirmCard.vue";
import {mapGetters} from "vuex";
import {Location as LocationModel} from "@/models";
import moment from "moment";

GoogleMapsLoader.LIBRARIES = ["drawing"];
GoogleMapsLoader.KEY = "AIzaSyBGrCkKHoHh3nvofpx3OhbVZpkG9cOSf9Q";

interface Enable {
  branches: Boolean;
  delivery: Boolean;
  drivers: Boolean;
}

interface Store {
  [x: string]: any;
}

interface DeliveryAreaObjectAttributes {
  coordinates: Array<string>;
}

interface DeliveryAreaObject {
  id: "string";
  attributes: DeliveryAreaObjectAttributes;
  type: "string";
}

interface ojbKey {
  [x: string]: string;
}

interface ConceptAttributes {
  "default-opening-hours": string;
  "default-promised-time-delta-delivery": string;
  "default-promised-time-delta-pickup": string;
  "default-delivery-charge": string;
  country: string;
  "default-pos": string;
}

interface ConceptObject {
  id: string;
  attributes: ConceptAttributes;
}

interface LocationObject {
  name: ojbKey;
  status: string;
  telephone: string;
  pos: string;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": boolean;
  "pickup-enabled": boolean;
  "curbside-enabled": boolean;
  "delivery-charge": string;
  "delivery-integration-id": string;
  line1: ojbKey;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
}

let defaultLocationObject = {
  name: {},
  status: "active",
  telephone: "",
  pos: "",
  email: "",
  country: "",
  lat: "",
  long: "",
  "delivery-enabled": true,
  "pickup-enabled": true,
  "curbside-enabled": true,
  "delivery-charge": "",
  "delivery-integration-id": "",
  line1: {},
  "promised-time-delta": {
    delivery: "",
    pickup: "",
  },
  "opening-hours": [],
};

@Component({
  components: {
    SearchItem,
    LocationItems,
    DriverItems,
    ConfirmCard,
    ChangeAreaNameModal,
    LocationModal,
  },
  computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
    }),
  },
  mixins: [translations, notificationAlerts, eventHandler],
})
export default class LocationsMap extends Vue {
  private gmap: any;
  private google: any;
  private controller: Object = {
    width: 400,
  };
  private activeLocation!: LocationModel;
  private activeLocationDeliveryAreas: Array<Object> = [];
  private searchLocationText: String = "";
  private searchDriverText: String = "";
  private locations: Array<Object> = [];
  private locationsArr: Array<Object> = [];
  private newlocationsArr: Array<Object> = [];
  private included: Array<any> = [];
  private drivers: Array<Object> = [];
  private driversMarkers: Array<any> = [];
  private driversIncluded: Array<Object> = [];
  private enable: Enable = {
    branches: true,
    delivery: false,
    drivers: false,
  };
  private deliveryAreas: Array<any> = [];
  private deliveryArea: any;
  private deliveryAreaIsUpdating: Boolean = false;
  private tempDeliveryArea: Store = {};
  private activeDeliveryArea: Store = {};
  private activeDeliveryAreaId: String = "";
  private activeDeliveryAreaName: String = "";
  private markers: Array<any> = [];
  private areaNameModal: Boolean = false;
  private driverInfowWindows: Array<any> = [];
  private infoWindows: Array<any> = [];
  private shapeDrawn: any;
  private getLocale!: any;
  public translate!: Function;
  private systemErrorNotification!: Function;
  private successNotification!: Function;
  public isSaving: Boolean = false;
  public isAdding: Boolean = false;
  public isShowing: Boolean = false;
  public drawArea: any;
  public confirm!: Function;
  public $refs!: {
    confirmCard: any;
    locationInfoFormModal: any;
  };
  private locationInfoFormModalKey: number = 1;

  private concept!: ConceptObject;
  private isOpeningHoursSet: Boolean = false;
  private location: LocationObject = {...defaultLocationObject};
  private locationClone!: any;
  private arrayLang: Array<string> = ["en-us", "ar-sa"];

  private totalDriversCount: number | null = null;
  private totalLocationsCount: number | null = null;
  private isPolygonDisplayed: boolean = false;
  private deliveryPolygon: any = null;

  constructor() {
    super();
  }

  @Watch("enable.drivers", {immediate: true, deep: true})
  onShowDriver(show: Boolean = false) {
    if (!show) {
      this.driversMarkers.map((driver: any) => {
        driver.setMap(null);
      });
    } else {
      this.driversMarkers = [];

      this.drivers.map((driver: any) => {
        let driverId: null = null,
            orderId: null = null;
        if (driver.relationships.bearing.data) {
          driverId = driver.relationships.bearing.data.id;
        }
        if (driver.relationships.order.data) {
          orderId = driver.relationships.order.data.id;
        }
        let bearing: any = this.driversIncluded.filter((include: any) => {
          return include.id === driverId && include.type == "bearing";
        });
        let order: any = this.driversIncluded.filter((include: any) => {
          return include.id === orderId && include.type == "order";
        });
        if (bearing.length) {
          if (bearing[0].attributes.lat && bearing[0].attributes.long) {
            this.addDriverMarker(
                {
                  lat: bearing[0].attributes.lat,
                  long: bearing[0].attributes.long,
                },
                order
            );
          }
        }
      });

      this.driversMarkers.map((driver: any) => {
        driver.setMap(this.gmap);
      });
    }
  }

  @Watch("enable.delivery", {immediate: true, deep: true})
  onShowDelivery(show: Boolean = false) {
    this.refreshDeliveryAreas(show);
  }

  @Watch("enable.branches", {immediate: true, deep: true})
  onShowBranches(show: Boolean = false) {
    if (!show) {
      this.markers.map((marker: any) => {
        marker.setVisible(false);
      });
    } else {
      this.markers.map((marker: any) => {
        marker.setVisible(true);
      });
    }
  }

  @Watch("deliveryArea", {immediate: true, deep: true})
  onDeliveryAreaChange() {
    // console.log(this.deliveryArea);
  }

  created() {
    this.setDefaultData();
  }

  mounted() {
    let canvas: any = document.getElementById("map-custom");
    setTimeout(() => {
      canvas.style.height =
          (window.outerHeight - 283 > 730 ? window.outerHeight - 250 : 730) +
          "px";
      // console.log(height, window.outerHeight > 720 ? true : false)

      GoogleMapsLoader.load((google: any) => {
        this.google = google;
        let lat = 24.774265,
            lng = 46.738586;
        this.initMap(lat, lng);
      });
    }, 100);
  }

  private hideDeliveryAreas() {
    this.deliveryAreas.map((delivery: any) => {
      delivery.setMap(null);
    });
  }

  private refreshDeliveryAreas(show: Boolean) {
    if (!show) {
      this.deliveryAreas.map((delivery: any) => {
        delivery.setMap(null);
      });
    } else {
      this.deliveryAreas.map((delivery: any) => {
        delivery.setMap(this.gmap);
      });
    }
  }

  private addNewLocation() {
    this.$refs.locationInfoFormModal.open = true;
  }

  private onLocationUpdate(location: any) {
    this.location = location;
    this.$refs.locationInfoFormModal.open = true;
  }

  private onLocationCreated(location: any) {
    setTimeout(() => {
      location.relationships["delivery-areas"].data = [];
      this.addMarker(location);
      this.pan(
          {
            lat: location.attributes.lat,
            long: location.attributes.long,
          },
          16
      );
      this.locations.push(location);
      this.$refs.locationInfoFormModal.open = false;
    }, 1000);
  }

  private onLocationUpdated(location: any) {
    setTimeout(() => {
      let existingLocation = this.locations.find(
          (item: any) => item.id === location.id
      ) as LocationModel;
      if (existingLocation) {
        existingLocation.attributes.name = location.attributes.name;
        this.addMarker(existingLocation);
        this.$refs.locationInfoFormModal.open = false;
      }
    }, 1000);
  }

  private onLocationDeleted(location: any) {
    setTimeout(() => {
      let google = this.google;
      let locationsFiltered = this.locations.filter(
          (item: any) => item.id !== location.id
      );

      this.locations = locationsFiltered;
      this.clearAllMarker();
      this.locations.map((location: any, i: number) => {
        this.addMarker(location);
      });
      this.$refs.locationInfoFormModal.open = false;
    }, 1000);
  }

  private showDriver(bearing: any, order: any) {
    // console.log(bearing, order);
    if (bearing.lat && bearing.long) {
    }
  }

  private enableStatus(payload: Object = {}, id: string) {
    Location.updateLocation(payload, id).then((response: any) => {
      let location = this.locations.find(
          (item: any) => item.id === response.data.data.id
      ) as LocationModel;
      location.attributes.status = response.data.data.attributes.status;
      this.successNotification(
          "RECORD SAVED!",
          "Location Status successfully saved!"
      );
    });
  }

  public async syncLocations() {
    this.confirm(
        this.$bvModal,
        this.translate("Are you sure you want to sync locations?")
    ).then(async (value: boolean) => {
      if (value) {
        try {
          let response = await IntegrationService.syncLocations("pos");
          this.getLocations(false, 1);
          this.successNotification(
              "LOCATION SYNCED!",
              "Locations successfully sycned"
          );
        } catch (error) {
          this.systemErrorNotification();
        }
      }
    });
  }

  public deleteLocation(id: string) {
    // console.log("id: ", id);
    const r = confirm(`Are you sure you want to delete this location?`);
    if (r) {
      Location.removeLocation(id)
          .then((response: any) => {
            this.getLocations(false, 1);
            // console.log("response:", response);
          })
          .catch((error) => {
            // console.log("err:", error);
          });
    }
  }

  private updateAreaNameModalState(open: Boolean = true) {
    if (!this.deliveryAreaIsUpdating) {
      this.initUpdate();
    }
    this.areaNameModal = open;
  }

  private filterLocation(text: String) {
    this.searchLocationText = text;
  }

  private filterDriver(text: String) {
    this.searchDriverText = text;
  }

  get filteredLocations() {
    this.newlocationsArr = this.newlocationsArr.concat(this.locations)
    this.newlocationsArr = [...new Set(this.newlocationsArr)]
    return this.newlocationsArr.filter((location: any, key: any) => {
      return location.attributes.name
          .toLowerCase()
          .match(this.searchLocationText)
    });
  }

  get filteredDrivers() {
    return this.drivers.filter((driver: any) => {
      let driverName: any =
          driver.attributes["first-name"] + " " + driver.attributes["last-name"];
      return driverName.toLowerCase().match(this.searchDriverText);
    });
  }

  private updateAreaName(areaName: String) {
    this.activeDeliveryAreaName = areaName;
    this.areaNameModal = false;
    if (!this.deliveryAreaIsUpdating) {
      this.deliveryAreaIsUpdating = true;
    }
  }

  private resetDefault() {
    this.isAdding = false;
    this.deliveryAreaIsUpdating = false;
    this.activeDeliveryArea = {};
    this.disbleDraw();
    this.removeActiveLocationDeliveryAreasFromMap();
  }

  private saveNewArea(areaName: String) {
    let payload = {
      label: areaName,
      coordinates: this.getCoordinates(this.shapeDrawn),
    };
    this.isSaving = true;
    Location.createArea(payload, this.activeDeliveryArea.id).then(
        (response: any) => {
          if (this.deliveryArea) {
            this.deliveryArea.setMap(null);
          }
          this.deliveryAreaIsUpdating = false;
          this.activeDeliveryArea = {};
          this.isSaving = false;
          // this.deliveryAreas = [];
          this.removeActiveLocationDeliveryAreasFromMap();

          // hack refreshes delivery areas on the map
          this.enable.delivery = !this.enable.delivery;
          this.enable.delivery = !this.enable.delivery;

          this.refreshLocationMap(this.activeLocation, response.data.data.pop());
          this.resetDefault();

          this.$refs.confirmCard.areaName = "";

          this.successNotification(
              "RECORDS SAVED!",
              "New Delivery Area successfully added!"
          );
        }
    );
  }

  private async refreshLocationMap(
      location: LocationModel,
      deliveryArea: any,
      isUpdate: boolean = false
  ) {
    if (isUpdate) {
      let existingDeliveryArea = this.included.find(
          (item: any) => item.id === deliveryArea.id
      );
      existingDeliveryArea = deliveryArea;
    } else {
      this.included.push(deliveryArea);
    }

    // this.activeDeliveryAreaId = deliveryArea.id;
    // let loc = this.locations.find((location: any) => location.id === this.activeLocation.id) as Object

    if (!isUpdate) {
      this.activeLocation.relationships["delivery-areas"].data.push(
          deliveryArea
      );
      let delivery = this.addCoordinatesAsPolygon(
          deliveryArea.attributes.coordinates
      );
      this.deliveryAreas.push(delivery);
    }

    this.addMarker(this.activeLocation);
  }

  private getCoordinates(data: any) {
    return data
        .getPath(this.gmap)
        .getArray()
        .map((latLang: any, index: number) => {
          return [latLang.lat(), latLang.lng()];
        });
  }

  private updateCoordinates() {
    let coordinates = this.getCoordinates(this.deliveryArea);

    let payload = {
      label: this.activeDeliveryAreaName,
      coordinates: coordinates,
    };

    this.isSaving = true;

    Location.updateDeliveryArea(
        payload,
        this.activeLocation.id,
        this.activeDeliveryAreaId
    ).then((response: any) => {
      // this.addDelivery(coordinates);
      // this.activeDeliveryArea.attributes.coordinates = coordinates;
      // this.refreshLocationMap(this.activeLocation, this.activeDeliveryArea);

      this.deliveryArea.setVisible(false);
      this.deliveryAreaIsUpdating = false;
      this.activeDeliveryArea = {};
      this.isSaving = false;
      this.hideDeliveryAreas();
      this.getLocations(false, 1);
    });
  }

  private async getLocations(recenterMap: Boolean = true, page: 1) {
    let lang = 'en-us'
    let google = this.google

    await Location.fetchLocations(lang, page)
        .then((response: any) => {
          // this.locations = this.locations.concat(response.data.data)
          // console.log(this.locations)
          this.locations = response.data.data
          if (response.data.included && Array.isArray(response.data.included)) {
            this.included = response.data.included.filter((include: any) => {
              return include.type === "delivery-area";
            })
          }

          this.addDeliveryAreas(this.included);
          // this.clearAllMarker();
          this.locations.map((location: any, i: number) => {
            this.addMarker(location);
            if (i === 0 && recenterMap) {
              let latLng = new google.maps.LatLng(
                  location.attributes.lat,
                  location.attributes.long
              );
              this.pan({
                lat: location.attributes.lat,
                long: location.attributes.long,
              });
            }
          });

          if (page === 1) {
            this.totalLocationsCount = response.data.meta.pagination.total;
          }

          if (
              response.data.meta.pagination.current_page <
              response.data.meta.pagination.total_pages
          ) {
            this.getLocations(false, response.data.meta.pagination.current_page + 1)
          }

        })
        .catch((err: any) => {
          console.log(err);
        });
  }

  private pan(latLng: any, zoom: number = 11) {
    let google = this.google;
    let LatLng = new google.maps.LatLng(latLng.lat, latLng.long);
    this.gmap.panTo(LatLng);
    this.gmap.setOptions({zoom: zoom});
    this.panBy(this.gmap);
  }

  private getDrivers(page: number = 1) {

    Employee.getDrivers(page).then((response: any) => {
      let google = this.google;
      let activeDrivers: any = {}
      response.data.data.map((data: any) => {
        this.drivers.push(data);
      });

      if (response.data.included) {
        response.data.included.map((data: any) => {
          this.driversIncluded.push(data);
        })
      }

      if (page === 1) {
        this.totalDriversCount = response.data.meta.pagination.total;
      }

      if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
      ) {
        this.getDrivers(response.data.meta.pagination.current_page + 1)
      }
    });
  }

  private addDriverMarker(LatLang: any, order: any) {
    let google = this.google;
    let myLatlng = {
      lat: LatLang.lat,
      lng: LatLang.long,
    };

    let icon = "";

    if (!order.length) {
      icon = `/img/icons/bikes/marker-bike-green-sm.png`;
    } else if (
        order[0].attributes["current-status"].code !== "delivery-in-progress"
    ) {
      icon = `/img/icons/bikes/marker-bike-yellow-sm.png`;
    } else if (
        order[0].attributes["current-status"].code === "delivery-in-progress"
    ) {
      icon = `/img/icons/bikes/marker-bike-red-sm.png`;
    } else {
      icon = `/img/icons/bikes/marker-bike-red-sm.png`;
    }

    let marker = new google.maps.Marker({
      position: myLatlng,
      map: this.gmap,
      animation: google.maps.Animation.DROP,
      title: "Locations",
      icon: icon,
    });

    if (order.length) {
      let infowindow = new google.maps.InfoWindow({
        content: this.driverInfow(order[0]),
      });
      let self = this;
      google.maps.event.addListener(marker, "click", () => {
        self.driverInfowWindows.map((infoWin: any) => {
          infoWin.close();
        });
        infowindow.open(self.gmap, marker);
      });
      this.driverInfowWindows.push(infowindow);
    }

    this.driversMarkers.push(marker);
  }

  private driverInfow(order: any) {
    return `
        <h5 class="default-text">${order.attributes["driver-name"]}</h5>
        <small class="text-muted">Mobile #: ${order.attributes.mobile}</small><br />
        <small class="text-muted">Order #: ${order.id}</small>
      `;
  }

  private addDeliveryAreas(deliveryAreas: Array<Object>) {
    deliveryAreas.map((area: any) => {
      this.addDelivery(area.attributes.coordinates);
    });
  }

  private addCoordinatesAsPolygon(coordinates: Array<Object>) {
    let google = this.google;
    let delivery = new google.maps.Polygon({
      paths: coordinates.map((coordinate: any) => {
        return {
          lat: coordinate[0],
          lng: coordinate[1],
        };
      }),
      geodesic: true,
      strokeColor: "#5E72E4",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#5E72E4",
      fillOpacity: 0.35,
    });

    return delivery;
  }

  private addDelivery(coordinates: Array<Object>) {
    let google = this.google;

    let delivery = new google.maps.Polygon({
      paths: coordinates.map((coordinate: any) => {
        return {
          lat: coordinate[0],
          lng: coordinate[1],
        };
      }),
      geodesic: true,
      strokeColor: "#5E72E4",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#5E72E4",
      fillOpacity: 0.35,
    });

    // delivery.setMap(this.gmap);
    this.deliveryAreas.push(delivery);
  }

  private initMap(lat: number, lng: number, color: String = "#5e72e4") {
    let google = this.google;
    let map = document.getElementById("map-custom");

    let myLatlng = new google.maps.LatLng(lat, lng);
    let mapOptions = {
      zoom: 12,
      scrollwheel: true,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      streetViewControl: false,
      style: this.styles(color),
    };

    this.gmap = new google.maps.Map(map as HTMLElement, mapOptions);
    this.panBy(this.gmap);

    this.initDraw();
    this.getLocations(false, 1);
    this.getDrivers();
  }

  private panBy(gmap: any) {
    if (this.getLocale === "en_US") {
      gmap.panBy(-200, 0);
    } else {
      gmap.panBy(150, 0);
    }
  }

  private addMarker(location: any) {
    let google = this.google;
    let myLatlng = {
      lat: location.attributes.lat,
      lng: location.attributes.long,
    };

    let marker = new google.maps.Marker({
      position: myLatlng,
      map: this.gmap,
      animation: google.maps.Animation.DROP,
      title: "Locations",
    });

    let infowindow = new google.maps.InfoWindow({
      content: this.contentString(location),
    });

    this.markers.push(marker);

    let self = this;

    google.maps.event.addListener(marker, "click", () => {
      self.activeLocation = location;

      self.infoWindows.map((infoWin: any) => {
        infoWin.close();
      });
      infowindow.open(self.gmap, marker);
      let DOM = new DOMParser().parseFromString(infowindow.content, "text/xml");
      console.log(DOM.querySelectorAll("h2"));
      setTimeout(() => {
        document
            .querySelectorAll("p.delivery-area, i.edit-delivery-area")
            .forEach((p) => {
              p.addEventListener("click", (e: any) => {
                self.isAdding = false;
                self.activeDeliveryAreaId = e.target.dataset.id;
                self.activeDeliveryAreaName = e.target.dataset.name;
                self.activeDeliveryArea = this.included.find(
                    (include: any) => include.id === this.activeDeliveryAreaId
                );
                infowindow.close();
              });
            });

        document.querySelectorAll("button.btn-add-area").forEach((btn: any) => {
          btn.addEventListener("click", (e: any) => {
            self.deliveryAreaIsUpdating = false;
            self.tempDeliveryArea = location;
            if (self.deliveryArea) {
              self.deliveryArea.setMap(null);
            }
            self.enableDraw();
            infowindow.close();

            this.activeLocation = location;
            this.prepareMapForAddingNewDeliveryArea(location);
          });
        });

        document.querySelectorAll("button.btn-show-area").forEach((btn: any) => {
          btn.addEventListener("click", (e: any) => {
            infowindow.close();
            this.removeShowDeliveryAreasFromMap()
            this.prepareMapForAddingNewDeliveryArea(location);
          });
        });
      }, 0);
    });

    this.infoWindows.push(infowindow);
  }

  private removeShowDeliveryAreasFromMap() {
    this.activeLocationDeliveryAreas.map((delivery: any) => {
      delivery.setMap(null);
    });
  }

  private removeActiveLocationDeliveryAreasFromMap() {
    this.activeLocationDeliveryAreas.map((delivery: any) => {
      delivery.setMap(null);
    });
  }

  private async prepareMapForAddingNewDeliveryArea(location: LocationModel) {
    try {
      let _this = this;
      let google = _this.google;
      let gmap = _this.gmap;
      let response = await Location.deliveryAreas(location.id, 150);
      let deliveryAreas = response.data.data;
      let myLatlng = new google.maps.LatLng(
          location.attributes.lat,
          location.attributes.long
      );

      _this.gmap.panTo(myLatlng);
      _this.gmap.setOptions({zoom: 12});
      _this.panBy(_this.gmap);

      _this.hideDeliveryAreas();

      deliveryAreas.map((area: any) => {
        let delivery = _this.addCoordinatesAsPolygon(
            area.attributes.coordinates
        );
        delivery.setMap(gmap);
        this.activeLocationDeliveryAreas.push(delivery);
      });
      self.addEventListener('keydown', (e: any) => {
        if (e.code === 'Escape') {
          this.removeShowDeliveryAreasFromMap()
        }
      })
    } catch (error) {
      this.systemErrorNotification(
          "LOADIN DELIVERY AREAS ERROR!",
          "Something went wrong while loading Location Delivery Areas, please try again."
      );
    }
  }

  private initDraw() {
    let google = this.google;
    this.drawArea = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        strokeWeight: 0.5,
        fillColor: "#ff4d4d",
        strokeColor: "#ff1a1a",
        editable: true,
        supressUndo: true,
      },
    });

    let self = this;

    google.maps.event.addListener(this.drawArea, "overlaycomplete", function (
        e: any
    ) {
      if (e.type == google.maps.drawing.OverlayType.POLYGON) {
        self.drawArea.setDrawingMode(null);
        self.shapeDrawn = e.overlay;
        self.isAdding = true;
        self.activeDeliveryArea = self.tempDeliveryArea;
        self.drawArea.setOptions({
          drawingControl: false,
        });
      }
    });
  }

  private enableDraw() {
    let google = this.google;
    this.drawArea.setOptions({
      drawingControl: true,
    });
    this.drawArea.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    this.drawArea.setMap(this.gmap);
  }

  private disbleDraw() {
    let google = this.google;
    this.drawArea.setOptions({
      drawingControl: false,
      drawingMode: null,
    });
    this.drawArea.setDrawingMode(null);
    this.drawArea.setMap(null);
    if (this.shapeDrawn) {
      this.shapeDrawn.setMap(null);
    }
    if (this.deliveryArea) this.deliveryArea.setMap(null);
  }

  private clearAllMarker() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  private initUpdate(bol: Boolean = true) {
    const {google} = this;

    if (this.deliveryPolygon) {
      this.deliveryPolygon.setMap(null);
      this.deliveryPolygon = null;
    }

    this.deliveryPolygon = new google.maps.Polygon({
      paths: this.activeDeliveryArea.attributes.coordinates.map(
          (coordinate: any) => {
            return {
              lat: coordinate[0],
              lng: coordinate[1],
            };
          }
      ),
      strokeColor: "#FF6B00",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#FF6B00",
      fillOpacity: 0.35,
    });

    this.deliveryAreaIsUpdating = true;
    this.deliveryPolygon.setMap(this.gmap);

    this.deliveryArea = this.deliveryPolygon;
    this.deliveryArea.setOptions({editable: bol});
  }

  private deleteArea() {
    Location
        .deleteDeliveryArea(this.activeLocation.id, this.activeDeliveryArea.id)
        .then((response) => {
          const deliveryAreaIdx = this.included.findIndex((d: any) => d.id === this.activeDeliveryArea.id);
          const deliveryAreaRelIdx = this.activeLocation.relationships["delivery-areas"].data.findIndex((d: any) => d.id === this.activeDeliveryArea.id);
          if (deliveryAreaIdx > -1) {
            this.included.splice(deliveryAreaIdx, 1);
          }
          if (deliveryAreaRelIdx > -1) {
            this.activeLocation.relationships["delivery-areas"].data.splice(deliveryAreaRelIdx, 1);
          }
          this.refreshLocationMap(this.activeLocation, this.activeDeliveryArea, true);
          this.resetDefault();
        });
  }

  private contentString(location: any) {
    return `<div class="info-window-content" style="min-width: 300px">
                <h2 class="location-name">${location.attributes.name}</h2>
                ${this.contentDeliveryAreas(
        location.relationships["delivery-areas"].data
    )}
                <button type="button" class="btn btn-sm btn-warning btn-add-area mt-4">
                  <i class="ni ni-fat-add"></i> ${this.translate(
        "Add Delivery Area"
    )}
                </button>
                <button type="button" class="btn btn-sm btn-warning btn-show-area mt-4">
                  ${this.translate(
        "Show Delivery Area"
    )}
                </button>
              </div>`;
  }

  private contentDeliveryAreas(data: Array<Object>) {
    let content = "";
    let deliveryAreas: any[] = [];

    data.map((area: any) => {
      let filteredArea = this.included
          .filter((include: any) => {
            return include.type === "delivery-area";
          })
          .filter((include: any) => {
            return area.id === include.id;
          });
      deliveryAreas.push(filteredArea[0]);
    });

    if (deliveryAreas.length) {
      deliveryAreas.map((area) => {
        content += `<p class="delivery-area py-0 my-0" data-id="${area.id}" data-name="${area.attributes.label}">
                        ${area.attributes.label} <i class="far fa-edit edit-delivery-area" data-id="${area.id}" data-name="${area.attributes.label}"></i>
                      </p>`;
      });
    }

    return content;
  }

  private styles(color: String = "#5e72e4") {
    return [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{color: "#444444"}],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{color: "#f2f2f2"}],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{visibility: "on"}],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{saturation: -100}, {lightness: 45}],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{visibility: "simplified"}],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{visibility: "on"}],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{visibility: "on"}],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{color: color}, {visibility: "on"}],
      },
    ];
  }

  onModalClose() {
    defaultLocationObject["pos"] = this.concept.attributes["default-pos"];
    defaultLocationObject["country"] = this.concept.attributes["country"];
    defaultLocationObject["delivery-charge"] = this.concept.attributes[
        "default-delivery-charge"
        ];

    let openingHours = JSON.parse(
        this.concept.attributes["default-opening-hours"]
    );
    defaultLocationObject['opening-hours'] = openingHours.map((item: any) => {
      defaultLocationObject["promised-time-delta"] = {
        delivery: this.concept.attributes[
            "default-promised-time-delta-delivery"
            ],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
    this.location = {...defaultLocationObject};

  }

  private async setDefaultData() {
    let response = await ConceptService.default();
    this.concept = response.data.data;
    let openingHours = JSON.parse(
        this.concept.attributes["default-opening-hours"]
    );

    this.location["pos"] = this.concept.attributes["default-pos"];
    this.location["country"] = this.concept.attributes["country"];
    this.location["delivery-charge"] = this.concept.attributes[
        "default-delivery-charge"
        ];
    this.location["opening-hours"] = openingHours.map((item: any) => {
      this.location["promised-time-delta"] = {
        delivery: this.concept.attributes[
            "default-promised-time-delta-delivery"
            ],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });

    this.isOpeningHoursSet = true;
    this.locationClone = {...this.location};
  }
}
