












































import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { Button, Table, TableColumn } from 'element-ui';
import moment from 'moment';
import { translations } from '@/mixins'
import Translations from '../Translations.vue'
import TabHeader from '@/views/Admin/pages/Messaging/components/TabHeader';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    TabHeader,
    Translations,
  },
  computed: {
    ...mapGetters({
      history: 'messaging/getHistory',
    })
  },
  filters: {
    datetime(value: any) {
      return moment.utc(value).locale('en-us').local().fromNow();
    }
  },
mixins: [translations],
})
export default class TabHistory extends Vue {
public translate!: Function
}
