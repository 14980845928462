




























































































































































































































































































































































import moment from "moment";
import { translations, notificationAlerts } from "@/mixins";
import Translations from "../components/Translations.vue";
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Promotion, Coupon, Customer } from "@/services/SOLO";
import { Table, TableColumn, Select, Option, Form, FormItem, Autocomplete } from 'element-ui';
import flatPicker from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Translations,
    flatPicker,
    [Autocomplete.name]: Autocomplete,
    ValidationProvider
  },
  mixins: [translations, notificationAlerts]
})
export default class CouponGroupsCouponCreate extends Vue {
  hasList: boolean = true;
  coupons: Array<any> = [];
  coupon: any = null;
  tab: number = 1;
  groupId: any = null;
  couponId: any = null;
  promotions: Array<any> = [];
  customers: Array<any> = [];
  successNotification!: Function;
  systemErrorNotification!: Function;

  modalForm: any = {
    'promotion-id': null,
    code: '',
    description: {
      'en-us': '',
      'ar-sa': '',
    },
    'valid-from': null,
    'valid-to': null,
    'customer-id': null,
  };

  limitToSpecificCustomers: boolean = false;
  limitToSpecificCustomersField = null;
  enable: boolean = false;

  dates: any = {
    from: {
      date: null,
      time: null,
    },
    to: {
      date: null,
      time: null,
    },
  };
  customerSelected: any = null;

  created() {
    const self = this;
    this.groupId = this.$route.params.id;
    this.couponId = this.$route.params.couponId;

    if (this.$route.query.digital) {
      this.modalForm.couponDescription = null;
    }

    Promotion.fetchPromotions().then(response => {
      this.promotions = response.data.data;
    });

    Coupon.getCouponsbyCouponGroupId(this.groupId).then(response => {
      console.log("getCouponsbyCouponGroupId: ", response);
      this.coupons = response.data.data;
    });

    console.log('this.$route.params: ', this.$route.params);
    if (this.couponId) {
    Customer.all().then(response => {
      this.customers = response.data.data;

      if (this.$route.query.digital) {
        return Coupon.getDigitalCoupon(this.couponId);
      } else {
        return Coupon.getCoupon(this.groupId, this.couponId);
      }
    }).then(response => {
        console.log("Coupon.getCoupon: ", response);
        this.coupon = response.data.data;
        const promotion = response.data.included[0];
        const customer = self.customers.find((cus: any) => {
          console.log('cus: ', cus.id);
          console.log('self.coupon.attributes["customer-id"]: ', self.coupon.attributes['customer-id']);
          return Number(cus.id) === self.coupon.attributes['customer-id']
        });
        const from = this.$route.query.digital ? this.formatDate(this.coupon.attributes['start-at']).split(' ') : this.coupon.attributes['valid-from'].split(' ');
        const to = this.$route.query.digital ? this.formatDate(this.coupon.attributes['ends-at']).split(' ') : this.coupon.attributes['valid-to'].split(' ');
        console.log('from: ', from);
        console.log('to: ', to);
        if (this.coupon.attributes['customer-id']) {
          this.limitToSpecificCustomers = true;
        }
        if (this.$route.query.digital) {
         this.modalForm.couponDescription = this.coupon.attributes.description;
        }
        console.log("this.coupon: ", this.coupon);
        console.log('this.customers: ', this.customers);
        console.log("customer: ", customer);
        if (customer) {
          this.customerSelected = {
            value: `${customer.attributes['first-name']} ${customer.attributes['last-name']} - ${customer.attributes['email']}`, 
            id: customer.id,
          }
        }
        
        this.modalForm.description['en-us'] = this.$route.query.digital ? this.coupon.attributes.name : this.coupon.attributes.description;
        this.modalForm['customer-id'] = customer && customer.attributes['first-name'] + ' ' + customer.attributes['last-name'] + '-' + customer.attributes['email'];
        this.modalForm.code = this.coupon.attributes.code;
        this.modalForm['promotion-id'] = promotion.id;
        this.dates.from.date = from[0];
        this.dates.from.time = from[1];
        this.dates.to.date = to[0];
        this.dates.to.time = to[1];
        console.log('this.dates: ', this.dates);
      });
    }
  }

  formatDate(date: string) {
    let split = date.split('T');
    const d: any = split[0];
    let time: any = split[1].split('.');
    time = time[0];
    return `${d} ${time}`;
  }

  onCancel() {
    if (this.$route.query.digital) {
      this.$router.push({ path: `/coupon-groups/coupon-list/digital?digital=true` })
    } else {
      this.$router.push({ path: `/coupon-groups/coupon-list/${this.$route.params.id}` });
    }
  }

  onAddCoupon() {
    
    console.log('this.dates: ', this.dates);
    if (this.dates.from.date) {
      this.modalForm['valid-from'] = moment(`${this.dates.from.date} ${this.dates.from.time ? this.dates.from.time : '00:00'}:00`).utc().format('YYYY-MM-DD H:mm:ss');
    }

    if (!this.dates.to.date || !this.dates.to.time) {
      alert('Need to select an End date and End time');
      return;
    }

    this.modalForm['valid-to'] = moment(`${this.dates.to.date} ${this.dates.to.time}:00`).utc().format('YYYY-MM-DD H:mm:ss');
    if (this.customerSelected) {
      if (!this.limitToSpecificCustomers) {
        this.customerSelected = null;
        delete this.modalForm['customer-id'];
      }
      this.modalForm['customer-id'] = Number(this.customerSelected.id);
    } else {
      delete this.modalForm['customer-id'];
    }

    console.log('this.modalForm: ', this.modalForm);
    if (this.$route.query.digital) {
      const payload = {
        "name": {
          "en-us": this.modalForm.description['en-us'],
          "ar-sa": this.modalForm.description['ar-sa']
        },
        "description": {
          "en-us": this.modalForm.couponDescription,
          "ar-sa": this.modalForm.couponDescription,
        },
        "starts-at": this.modalForm['valid-from'],
        "ends-at": this.modalForm['valid-to'],
        "customer-id": this.customerSelected ? Number(this.customerSelected.id) : null,
        "promotion-id": this.modalForm['promotion-id'] ? Number(this.modalForm['promotion-id']) : null,
      };
      if (this.couponId) {
        Coupon.updateDigitalCoupon(payload, this.couponId).then(() => {
          this.successNotification('DIGITAL COUPON UPDATED!', 'coupon successfully updated!');
          this.$router.push({ path: `/coupon-groups/coupon-list/digital?digital=true` });
        });
      } else {
        Coupon.createDigitalCoupon(payload).then(() => {
          this.successNotification('DIGITAL COUPON CREATED!', 'coupon successfully created!');
          this.$router.push({ path: `/coupon-groups/coupon-list/digital?digital=true` });
        });
      }
    } else {
      if (this.couponId) {
        Coupon.updateCoupon(this.modalForm, this.groupId, this.couponId).then(() => {
          this.successNotification('COUPON UPDATED!', 'coupon successfully updated!');
          this.$router.push({ path: `/coupon-groups/coupon-list/${this.$route.params.id}` });
        });
      } else {
        Coupon.createCoupon(this.modalForm, this.groupId).then(() => {
          this.successNotification('COUPON CREATED!', 'coupon successfully created!');
          this.$router.push({ path: `/coupon-groups/coupon-list/${this.$route.params.id}` });
        });
      }
    }
  }

  handleSelect(e: any) {
    console.log('e: ', e);
    this.customerSelected = e;
  }

  onFetch(queryString: string, cb: Function) {
    Customer.filterByEmail(queryString)
      .then((response) => {
          console.log('response loadCustomers: ', response);
          const data = [...response.data.data];
          console.log('mapped: ', data.map((d: any) => {
              return {
                  value: d.attributes.name, 
                  id: d.id,
              };
          }));
          const mapped = data.map((d: any) => {
              return {
                  value: `${d.attributes['first-name']} ${d.attributes['last-name']} - ${d.attributes['email']}`, 
                  id: d.id,
              };
          });
          cb(mapped);
      });
    }
    $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
}
