
















































































































































































































































































































































































































import moment from "moment";
import { translations } from "@/mixins";
import Translations from "../components/Translations.vue";
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Promotion, Coupon } from "@/services/SOLO";
import { Table, TableColumn } from 'element-ui';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Translations,
  },
  mixins: [translations]
})
export default class CouponGroupsEditList extends Vue {
  hasList: boolean = true;
  coupons: Array<any> = [];
  promotions: Array<any> = [];
  tab: number = 1

  created() {
    if (this.$route.query.digital) {
      Coupon.allDigitalCoupons().then(response => {
        this.coupons = response.data.data;
        this.promotions = response.data.included;
        console.log('allDigitalCoupons: ', this.coupons);
        // this.coupons[1].attributes['is-expired'] = true;
        // this.coupons[1].attributes['is-valid'] = false;
      });
    } else {
      const id = this.$route.params.id;
      Coupon.getCouponsbyCouponGroupId(id).then(response => {
        this.coupons = response.data.data;
        this.promotions = response.data.included;
        console.log('getCouponsbyCouponGroupId: ', this.coupons);
        console.log('this.promotions: ', this.promotions);
        // this.coupons[1].attributes['is-expired'] = true;
        // this.coupons[1].attributes['is-valid'] = false;
      });
    }
  }

  // onAddPromotion() {
  //   this.$router.push({
  //     name: "PromotionsCreate"
  //   });
  // }

  onEdit(coupon: any) {
    if (this.$route.query.digital) {
      this.$router.push({ path: `/digital-coupons/edit/${coupon.id}?digital=true` });
    } else {
      this.$router.push({ path: `/coupon-groups/coupon-edit/${this.$route.params.id}/coupon/${coupon.id}` });
    }
  }

  filterCouponsGroup(tabType: string) {
    return this.coupons.filter((c: any) => {
      if (tabType === 'active' && c.attributes['is-valid']) {
        return c;
      } else if (tabType === 'incoming' && c.attributes['is-used']) {
        return c;
      } else if (tabType === 'expired' && c.attributes['is-expired']) {
        return c;
      } else if (this.$route.query.digital) {
        return c;
      }
    });
  }

  formatDate(date: Date) {
    console.log(moment.locale());
    return moment
      .utc(date)
      .locale("en-us")
      .local()
      .format("lll");
  }

  getPromotion(coupon: any) {
    return this.promotions.find((promotion: any) => {
      console.log('coupon.relationships.promotion.data.id: ', coupon.relationships.promotion.data.id);
      console.log('promotion.id: ', promotion.id);
      return Number(promotion.id) === Number(coupon.relationships.promotion.data.id);
    });
  }
}
