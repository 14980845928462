









































































































import {Select, Option, Checkbox} from "element-ui";
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Applications, Integrations} from '@/services/SOLO'
import {translations} from '@/mixins'
interface PaymentDeliveryForm {
  'payment-gateway'?: string,
  'force-location'?: any,
  'allowed-order-types': any,
  'allowed-payments-pickup': any,
  'allowed-payments-delivery': any,
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    BetaBadge: () => import("@/views/Admin/global-component/BetaBadge.vue"),
  },
  filters: {
    capitalizeFirstLetter(str: any) {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mixins: [translations]
})
export default class PaymentDelivery extends Vue {
  @Prop() details!: any
  saving: boolean = false
  $notify: any
  form: PaymentDeliveryForm = {
    'payment-gateway': '',
    'force-location': false,
    'allowed-order-types': [],
    'allowed-payments-pickup': [],
    'allowed-payments-delivery': [],
  }

  translate!: Function

  payment_gateway: any = []

  allowed_order_types: any = [
    {text:'Deliver',value:'deliver'},
    {text:'Eat-in',value:'eat-in'},
    {text:'To-go',value:'to-go'},
    {text:'Curbside',value:'curbside'},
  ]

  allowed_payment_pickup: any = [
    {text:'Cash',value:'cash'},
    {text:'Card',value:'card'},
    {text:'Card on Pickup',value:'card-on-pickup'},
    {text:'Apple Pay',value:'apple-pay'},
  ]

    allowed_payment_delivery: any = [
    {text:'Cash',value:'cash'},
    {text:'Card',value:'card'},
    {text:'Card on Delivery',value:'card-on-delivery'},
    {text:'Apple Pay',value:'apple-pay'},
  ]

  @Watch('details') onDetailsChanged(val: any) {
    this.processApplicantPayment(val.attributes)
  }

  mounted() {
    this.getIntegratedPayments()
  }

  processApplicantPayment(data: any) {

    this.payment_gateway.map((v: any) => {
      if (data['payment-gateway'] == v.attributes.provider) {
        this.form['payment-gateway'] = v.attributes.provider
      }
    })

    this.form['payment-gateway'] = data['payment-gateway']
    this.form['force-location'] = !!data['force-location']
    this.form['allowed-order-types'] = data['allowed-order-types'] ? data['allowed-order-types'].split(',') : [this.allowed_order_types[0].value]
    this.form['allowed-payments-pickup'] = data['allowed-payments-pickup'] ? data['allowed-payments-pickup'].split(',') : [this.allowed_payment_pickup[0].value]
    this.form['allowed-payments-delivery'] = data['allowed-payments-deliver'] ? data['allowed-payments-deliver'].split(',') : [this.allowed_payment_delivery[0].value]
  }

  async getIntegratedPayments() {
    await Integrations.getAllIntegrations()
        .then((response: any) => {
          this.payment_gateway = response.data.data.filter((payment: any) => payment.attributes.type === 'payment')
          this.form['payment-gateway'] = this.form['payment-gateway'] ? this.form['payment-gateway'] : this.payment_gateway[0]
        }).catch((err: any) => {})
  }

  forceLocation(e: any) {
      this.form['force-location'] = e
  }

  updateAppPaymentDelivery() {
    this.saving = true
    let params: any = {}

    params['allowed_order_types'] = this.form['allowed-order-types'].filter((value: any) => value !== 'false').toString()
    params['allowed_payments_deliver'] = this.form['allowed-payments-delivery'].filter((value: any) => value !== 'false').toString()
    params['allowed_payments_pickup'] = this.form['allowed-payments-pickup'].filter((value: any) => value !== 'false').toString()
    params['payment_gateway'] = this.form['payment-gateway']
    params['force_location'] = this.form['force-location'] ? 1 : 0

    if (params['allowed_order_types'] === '') {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: 'Allowed order types cannot be empty.',
        type: "danger",
        icon: "fas fa-bomb",
      });
      this.saving = false
      return false
    }

    if (params['allowed_payments_pickup'] === '') {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: 'Allowed payments pickup cannot be empty.',
        type: "danger",
        icon: "fas fa-bomb",
      });
      this.saving = false
      return false
    }
    if (params['allowed_payments_deliver'] === '') {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: 'Allowed payments deliver cannot be empty.',
        type: "danger",
        icon: "fas fa-bomb",
      });
      this.saving = false
      return false
    }

    Applications.updateApplication(Number(this.$route.params.id), params)
        .then((response: any) => {
          this.$notify({
            title: "PAYMENT AND DELIVERY UPDATED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Record has been updated successfully",
            type: "success",
            icon: "fas fa-check"
          })
          this.saving = false
        }).catch((err:any) => {
          let errMsg: any = []
          for (let error in err.response.data.errors) {
            errMsg = err.response.data.errors[error]
          }
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: errMsg[0],
            type: "danger",
            icon: "fas fa-bomb",
          });
          this.saving = false
    })
  }

}
