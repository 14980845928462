





















































import Modal from "@/components/Modal.vue";
import { Category, Settings, SettingsFacilities } from "@/services/SOLO";
import { Select, Option, Menu } from "element-ui";
import { ValidationObserver, configure, extend, validate } from "vee-validate";
import { Facility } from "@/models";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit
} from "vue-property-decorator";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

interface dataObject {
  name: string;
  name_ar_sa: string;
  icon: string;
  icon_file: string;
  image: string;
  image_file: string;
}

const FacilitiesFormModalProps = Vue.extend({
  props: {
    model: {
      type: Object,
      default: () => {
        attributes: {
        }
      }
    }
  }
});

@Component({
  components: {
    ImageUrlOrFileUpload: () =>
      import("@/views/Admin/global-component/ImageUrlOrFileUpload.vue"),
    Translations,
  },
  mixins: [translations],
})
export default class FacilitiesFormModal extends FacilitiesFormModalProps {
  translate!: Function;
  open: boolean = false;
  parent: any;
  saving: boolean = false;
  data: dataObject = {
    name: "",
    name_ar_sa: "",
    icon: "",
    icon_file: "",
    image: "",
    image_file: ""
  };
  $notify: any
  $refs!: {
    formValidator: any;
  };

  @Watch("model", { immediate: true, deep: true })
  onModelChanged(val: Facility, oldVal: Facility) {
    this.initializeData();
  }

  created() {
    this.initializeData();
  }

  onIconFileUploadChange(data: any) {
    this.data.icon_file = data.file;
    this.data.icon = data.name;
  }

  onImageFileUploadChange(data: any) {
    this.data.image_file = data.file;
    this.data.image = data.name;
  }

  getRaSaName() {
    if (this.model.id) {
      SettingsFacilities.find(this.model.id, "ar-sa").then((response: any) => {
        this.data.name_ar_sa = response.data.data.attributes.name;
      });
    }
  }

  closeMe() {
    this.open = false;
    this.$emit("close");
  }

  async submit(e: any) {
    const isValid = await this.$refs.formValidator.validate();
    if (!isValid || this.saving) {
      return;
    }

    this.saving = true;
    try {
      if (this.model.id) {
        const response = await SettingsFacilities.update(
          this.model.id,
          this.formData()
        );
      } else {
        const response = await SettingsFacilities.create(this.formData());
      }

      this.$emit("successfulFormSubmit");
      this.$notify({
        title: "RECORDS SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Record have been saved successfully",
        type: "success",
        icon: "fas fa-check"
      });

      this.saving = false;
      this.closeMe();
    } catch (error) {
      this.saving = false;
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb"
      });
    }
  }

  formData() {
    let formData = new FormData();
    formData.append("name", this.data.name);
    formData.append("name[ar-sa]", this.data.name_ar_sa);
    formData.append("name[en-us]", this.data.name);

    formData.append(
      "icon",
      this.data.icon_file ? this.data.icon_file : this.data.icon
    );
    formData.append(
      "image",
      this.data.image_file ? this.data.image_file : this.data.image
    );
    return formData;
  }

  initializeData() {
    if (this.model.id) {
      this.data = {
        name: this.model.attributes.name,
        name_ar_sa: "",
        icon: this.model.attributes.icon,
        icon_file: "",
        image: this.model.attributes.image,
        image_file: ""
      };

      this.getRaSaName();
    } else {
      this.data = {
        name: "",
        name_ar_sa: "",
        icon: "",
        icon_file: "",
        image: "",
        image_file: ""
      };
    }
  }
}
