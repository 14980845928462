import store from '@/store'

export default function admin({to, from, next}) {


    let isLoggedIn = store.getters['account/isLoggedIn']
    let getConceptSettings = store.getters['account/getConceptSettings']
    let getUserRole = store.getters['account/getUserRole']
    let subscription = store.getters['account/getSubscription']
    let paymentId = store.getters['account/getSubscriptionPaymentId']

    if(isLoggedIn && getConceptSettings && getUserRole.includes('administrator')) {
        if(getConceptSettings.attributes['billing-type'] === 'stripe' && !getConceptSettings.attributes['is-free-package'] && subscription === null) {
            if(!paymentId) {
                return next('/customer-subscription')
            } else {
                return next(`/payment/${paymentId}`)
            }
        } else if(getConceptSettings.attributes['billing-type'] === 'stripe' && !getConceptSettings.attributes['is-free-package'] && subscription) {
            if(subscription.attributes.status === 'past_due') {
                return next('/account')
            } else if(subscription.attributes.status === 'incomplete' && !paymentId) {
                return next('/customer-subscription')
            } else if (subscription.attributes.status === 'incomplete' && paymentId) {
                return next(`/payment/${paymentId}`)
            }
        }
    }


    let pagesArr = []
    let buttonArr = []
    let user = JSON.parse(localStorage.getItem('SOLO')).account.userRole
    let allowedConceptReport = [1, 5, 10, 11, 12, 13, 15, 17, 18, 27, 86] // concept_id
    let allowedConceptSegment = [15, 12, 17, 18, 1] // concept_id
    let allowedConceptPromotion = [12, 15, 1] // concept_id
    let currentConceptId = store.getters['account/activeConcept'].id
    // upon login capture accessible page base on role and start the restriction
    // also check if the current selected concept id does not exist on the allowed concepts


    switch (user[0]) {
        case 'administrator':
            pagesArr = ['account', 'dashboard', 'qr-ordering', 'order-statuses', 'messaging', 'blacklists', 'sliding-banners', 'kiosks', 'curbside', 'apps', 'cms', 'notification', 'menus', 'loyalty', 'locations', 'devices', 'feedback', 'orders', 'customers', 'promotions', 'segments', 'employees', 'settings', 'reports', 'customer-subscription']
            buttonArr = ['new_slider','add_slide','edit_slide','remove_slide','add_menu','edit_category','delete_category','open_category','add_item','edit_item','delete_item','open_item','add_modGroup_to_item','remove_modGroup_from_item','open_modGroup','add_ingredient_to_item','remove_ingredient_from_item','add_cusField_to_item','edit_cusField_in_item','add_modItem_to_modifier','edit_modItem','remove_modItem_from_modifier','sync_pos','add_category','add_location','sync_location','edit_location','disable_location','delete_location','export_orders','order_status','order_details','order_location','order_driver','new_customer','export_customers','customer_details','new_segment','export_segments','segment_details','segment_customers','new_employee','employee_details','delete_employee','add_field','delete_field','add_group','group_details','add_ingredient','ingredient_details','feedback_attachment']

            if (!allowedConceptReport.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'reports')
            }

            if (!allowedConceptSegment.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'segments')
            }

            if (!allowedConceptPromotion.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'promotions')
            }

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            next()
            break
        case 'restaurant':
            pagesArr = ['locations','store locations', 'orders', 'order-details', 'promotions', 'segments', 'upsells']
            buttonArr = ['new_slider','add_slide','edit_slide','remove_slide','edit_location','order_status','order_details','order_location','order_driver','new_employee','employee_details','delete_employee','add_field','delete_field','add_group','group_details']

            if (!allowedConceptSegment.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'segments')
            }

            if (!allowedConceptPromotion.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'promotions')
            }
            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name)) {
                next({name: 'orders'})
            }
            next()
            break
        case 'call_center':
            pagesArr = ['locations', 'store locations', 'feedback', 'feedback-list', 'loyalty-item', 'customers', 'orders', 'order-details', 'promotions', 'upsells']
            buttonArr = ['new_slider','add_slide','edit_slide','remove_slide','order_status','order_details','order_location','order_driver','new_customer','export_customers','customer_details','new_segment','export_segments','segment_details','segment_customers','new_employee','employee_details','delete_employee','add_field','delete_field','add_group','group_details']

            if (!allowedConceptPromotion.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'promotions')
            }

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name)) {
                next({name: 'orders'})
            }
            next()
            break
        case 'supervisor':
            pagesArr = ['locations', 'store locations', 'feedback', 'feedback-list', 'loyalty-item', 'orders', 'order-details', 'customers', 'list', 'details', 'promotions', 'segments', 'upsells']
            buttonArr = ['new_slider','add_slide','edit_slide','remove_slide','edit_location','export_orders','order_status','order_details','order_location','order_driver','new_customer','export_customers','customer_details','new_segment','export_segments','segment_details','segment_customers','new_employee','employee_details','delete_employee','add_field','delete_field','add_group','group_details','feedback_attachment']
            if (!allowedConceptSegment.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'segments')
            }

            if (!allowedConceptPromotion.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'promotions')
            }

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name)) {
                next({name: 'orders'})
            }
            next()
            break
        case 'reports':
            pagesArr = ['dashboard', 'reports']
            if (!allowedConceptReport.includes(currentConceptId)) {
                pagesArr = pagesArr.filter((page) => page !== 'reports')
            }
            store.commit('page/setPage', pagesArr)
            if (!pagesArr.includes(to.name)) {
                next({name: 'dashboard'})
            }
            next()
            break
        case 'marketing':
            pagesArr = ['menus','MenuCategory','itemModifier','EditModgroupItem','itemCreate']
            buttonArr = ['new_slider','add_slide','edit_slide','remove_slide','new_segment','export_segments','segment_details','segment_customers','feedback_attachment']

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name)) {
                next({name: 'menus'})
            }
            next()
            break
        case 'menu':
            pagesArr = ['menus','MenuCategory','itemModifier','EditModgroupItem','itemCreate']
            buttonArr = ['new_slider','add_slide','edit_slide','remove_slide','new_segment','export_segments','segment_details','segment_customers','feedback_attachment']

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name)) {
                next({name: 'menus'})
            }
            next()
            break
        case 'driver':
            pagesArr = ['locations', 'store locations', 'orders', 'order-details',]
            buttonArr = ['new_slider','add_slide','edit_slide','remove_slide','new_employee','employee_details','delete_employee','add_field','delete_field','add_group','group_details']

            store.commit('page/setPage', pagesArr)
            store.commit('button/setButton', buttonArr)
            if (!pagesArr.includes(to.name)) {
                next({name: 'orders'})
            }
            next()
            break
    }


}
