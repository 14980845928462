
























import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from "@/mixins";
import CouponGroupsCreateModal from './CouponGroupsCreateModal';
@Component({
  components: {
    CouponGroupsCreateModal,
  },
  mixins: [translations]
})
export default class CouponsHeader extends Vue {
  translate!: Function;
  openCreate: boolean = false

  onNew() {
    this.openCreate = true;
  }

  onCreateCoupon() {
    if (this.$route.query.digital) {
      this.$router.push({ path: `/digital-coupons/create?digital=true` });
    } else {
      this.$router.push({ path: `/coupon-groups/coupon-create/${this.$route.params.id}` });
    }
  }

  handleClose(value: any) {
    this.openCreate = value;
  }
}
