import BaseService from '../base.service'

export default class CmsService extends BaseService{
    
    appendURI: string = '/cms/pages';

    constructor(){
        super();
    }

    /**
     *
     * @param page
     * @returns {AxiosPromise<any>}
     */
    all(params: any, page: any = 1){
        let url = super.prepareUrl(this.baseURL + this.appendURI, params);
        return super.get(this.baseURL, {
            page: page
        });
    }

    getPage(pageId: number) {
        return super.get(this.baseURL + this.appendURI + '/' + pageId);
    }
}
