



































import moment from "moment";
import { Feedback as FeedbackModel} from "@/models";
import { Table, TableColumn, Button } from "element-ui";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
mixins: [translations],
})
export default class FeedbackList extends Vue {
  public translate!: Function
  @Prop() list!: Array<FeedbackModel>;
  @Prop() loading: boolean = false;
  color: string = "white";
  currentPage: number = 1;
  feedback: FeedbackModel = {
    id: "",
    attributes: {
      name: "",
      email: "",

    },
  };

  @Watch("loading", { deep: true })
  onLoadingChanged(newVal: any) {
    this.loading = newVal;
  }
 
  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("MMM D YYYY hh:mm A");
  }
}
