import BaseService from '../base.service'
import store from '@/store'
export default class ItemService extends BaseService {

    appendURI: string = '/menus/:menuId/categories/:categoryId/items'

    constructor() {
        super();                
    }

    updateCategoryCustomField(payload: Object = {}, customFieldId: string) {        
        let url: string = this.baseURL + `custom-fields/${customFieldId}/data`

        return super.post(url, payload)
    }

    deleteCustomFields(payload: Object = {}, customFieldId: string) {
        let url: string = this.baseURL + `custom-fields/${customFieldId}/data`

        return super.remove(url, payload)
    }

    getCustomFields() {
        let url: string = this.baseURL + `custom-fields`
        return super.get(url)
    }

    all(params: any, page: any = 1) {        
        let url = super.prepareUrl(this.baseURL + this.appendURI + '?include=itemModifierGroups', params);

        return super.get(url, {
            page: page,
            include: 'allergen'
        });
    }

    find(params: any, includes: string = 'category,ingredients') {        
        let url = super.prepareUrl(this.baseURL + this.appendURI + '/:itemId?include=' + includes, params);        

        return super.get(url)
    }

    fetchModifiers(params: any) {        
        let url = super.prepareUrl(this.baseURL + this.appendURI + '/:itemId/modifier-groups?include=', params);

        return super.get(url)
    }
    
    fetchComboMealsModifiers(params: any)
    {           
        let URL = super.prepareUrl(this.baseURL + '/menus/:menuId/combo-meals/:itemId?include=components.comboItems,modifierGroups,ingredients', params)
         
        return super.get(URL)
    }
}
