






























































import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { SettingsFacilities, SettingsConcepts } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import VueTimepicker from "vue2-timepicker";
import moment from "moment";
import { eventHandler } from "@/mixins";
import { translations } from '@/mixins';
import Translations from '../Translations.vue';

interface OpeningHourObject {
  day: string;
  open: string;
  closed: string;
}

@Component({
  components: {
    ConceptsOpeningHours: () =>
      import("./components/concepts/ConceptsOpeningHours.vue"),
    ConceptsImageLogo: () =>
      import("./components/concepts/ConceptsImageLogo.vue"),
    ConceptsImageSizes: () =>
      import("./components/concepts/ConceptsImageSizes.vue"),
    ConceptsSupportedOrderTypes: () =>
      import("./components/concepts/ConceptsSupportedOrderTypes.vue"),
    ConceptsPrices: () => import("./components/concepts/ConceptsPrices.vue"),
    ConceptsTimes: () => import("./components/concepts/ConceptsTimes.vue"),
    ConceptsOthers: () => import("./components/concepts/ConceptsOthers.vue"),
    VueTimepicker,
    LoadingPanel: () => import("@/components/LoadingPanel"),
    Translations,
  },
  mixins: [translations, eventHandler],
})
export default class Concepts extends Vue {
  translate!: Function;
  itemLimit: Number = 100;
  loadingConcept: Boolean = false;
  conceptLoaded: Boolean = false;
  saving: Boolean = false;
  confirm!: Function;
  data: string = "";
  concept: Concept = {
    id: "",
    type: "",
    links: {},
    attributes: {
      "supported-order-types": "",
      "supported-payment-types": "",
      "deliver-payment-types": "",
      "pickup-payment-types": "",
      "opening-hours": "",
      "created-at": "",
      "updated-at": "",
      "languages": "",
      label: "",
      country: "",
      "dialing-code": "",
      "currency-code": "",
      "currency-symbol": "",
      "logo-uri": "",
      "logo-image": "",
      "default-opening-hours": "",
      "default-delivery-charge": "",
      "default-pos": "",
      "default-promised-time-delta-delivery": "",
      "default-promised-time-delta-pickup": "",
      "default-minimum-order-amount": "",
      "order-cancellation-time": "",
      "order-cancellation-max-status": "",
      "order-price-calculation": "",
      "vat-rate": "",
      "vat-type": "",
      "recommended-dimensions": {
        item: {
          width: "",
          height: "",
        },
        category: {
          width: "",
          height: "",
        },
        slider: {
          width: "",
          height: "",
        },
      },
      "delivery-promised-time": "",
      "pickup-promised-time": "",
      "allow-order-cancellation-before-promised-time": false,
      "is-maintenance-mode": false,
      "cart-expiry-minutes": "",
    },
  };
  openingHours: Array<Object> = [];
  $notify: any;
  $refs!: {
    imageSizes: any;
  };

  async getConcepts() {
    this.loadingConcept = true;
    try {
      const response = await SettingsConcepts.get();
      console.log(response.data.data);
      this.setConcept(response.data.data);
      this.loadingConcept = false;
      this.conceptLoaded = true;
    } catch (error) {
      this.loadingConcept = false;
      this.conceptLoaded = false;
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  setConcept(data: any) {
    console.log('setConcept: ', data)
    this.concept = data;
    this.prepareDefaultOpeningHours();
  }

  prepareDefaultOpeningHours() {
    if (
      !this.concept.attributes["default-opening-hours"] ||
      this.concept.attributes["default-opening-hours"] === "None"
    ) {
      this.concept.attributes["default-opening-hours"] = JSON.stringify([
        { day: "0", open: "", closed: "" },
        { day: "1", open: "", closed: "" },
        { day: "2", open: "", closed: "" },
        { day: "3", open: "", closed: "" },
        { day: "4", open: "", closed: "" },
        { day: "5", open: "", closed: "" },
        { day: "6", open: "", closed: "" },
      ]);
    }

    let openingHours: Array<Object> = JSON.parse(
      this.concept.attributes["default-opening-hours"]
    );

    this.openingHours = openingHours.map((item: any) => {
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
  }

  resetChanges() {
    this.confirm(
      this.$bvModal,
      "Are you sure you want to reset your changes?"
    ).then((value: boolean) => {
      if (value) {
        this.getConcepts();
      }
    });
  }

  async saveChanges() {
    this.saving = true;
    let openingHours: Array<Object> = this.openingHours.map((item: any) => {
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm A").format("HH:mm"),
        closed: moment(`${item.closed}`, "hh:mm A").format("HH:mm"),
      };
    });

    let formData = new FormData();
    let ignoreKeys = [
      "opening-hours",
      "supported-order-types",
      "supported-payment-types",
      "deliver-payment-types",
      "pickup-payment-types",
      "delivery-promised-time",
      "pickup-promised-time",
      "recommended-dimensions",
      "allow-order-cancellation-before-promised-time",
      "is-maintenance-mode",
    ];
    for (let [key, value] of Object.entries(this.concept.attributes)) {
      if (!ignoreKeys.includes(key) && value) {
        formData.append(key, value);
      }
    }

    let attributes = this.concept.attributes;
    formData.append("opening-hours", JSON.stringify(openingHours));
    formData.append(
      "supported-order-types",
      attributes["supported-order-types"].toString() ?? null
    );
    formData.append(
      "supported-payment-types",
      attributes["supported-payment-types"].toString() ?? null
    );
    formData.append(
      "pickup-payment-types",
      attributes["pickup-payment-types"].toString() ?? null
    );
    formData.append(
      "deliver-payment-types",
      attributes["deliver-payment-types"].toString() ?? null
    );
    formData.append(
      "delivery-promised-time",
      attributes["default-promised-time-delta-delivery"].toString() ?? null
    );
    formData.append(
      "pickup-promised-time",
      attributes["default-promised-time-delta-pickup"].toString() ?? null
    );
    formData.append(
      "recommended-dimensions",
      JSON.stringify(this.$refs.imageSizes.recommendedDimensions)
    );
    formData.append("delivery-charge", attributes["default-delivery-charge"]);
    formData.append(
      "allow-order-cancellation-before-promised-time",
      attributes["allow-order-cancellation-before-promised-time"] ? "1" : "0"
    );
    formData.append(
      "is-maintenance-mode",
      attributes["is-maintenance-mode"] ? "1" : "0"
    );
    formData.append(
      "logo-uri",
      attributes["logo-uri"] ? attributes["logo-uri"] : ""
    );
    formData.append(
      "cart-expiry-minutes",
      attributes["cart-expiry-minutes"] ? attributes["cart-expiry-minutes"].toString() : '0'
    );

    if (attributes["logo-image"]) {
      formData.append("logo-image", attributes["logo-image"]);
    }

    try {
      let response = await SettingsConcepts.update(this.concept.id, formData);
      this.setConcept(response.data.data);
      this.$notify({
        title: "CHANGES SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Changes successfully saved!",
        type: "success",
        icon: "fas fa-check",
      });
      this.saving = false;
    } catch (error) {
      this.saving = false;
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong updating concept, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  mounted() {
    this.getConcepts();
  }
}
