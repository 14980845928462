import BaseService from '../base.service'

export default class SettingsService extends BaseService {
  url!: string
  constructor(){
    super();
  }

  allergens() {
    this.url = this.baseURL + 'allergens?filter[enabled]=all'
    return super.get(this.url)
  }

  getItemAllergens(itemId: string) {
    this.url = this.baseURL + `items/${itemId}/allergens`
    return super.get(this.url)
  }

  addAllergen(payload: Object = {}, itemId: string) {
    this.url = this.baseURL + `items/${itemId}/allergens`
    return super.post(this.url, payload)
  }

  deleteAllergen(itemId: string, allergenId: string) {
    this.url = this.baseURL + `items/${itemId}/allergens/${allergenId}`
    return super.remove(this.url, {})
  }

  ingredients() {
    this.url = this.baseURL + 'ingredients?filter[enabled]=all'
    return super.get(this.url)
  }

  addIngredient(payload: Object ={}, menuId: string, categoryId: string, itemId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/item-ingredients`
    return super.post(this.url, payload)
  }

  updateIngredient(payload: Object ={}, menuId: string, categoryId: string, itemId: string, ingredientId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/item-ingredients/${ingredientId}`
    return super.patch(this.url, payload)
  }

  deleteIngredient(menuId: string, categoryId: string, itemId: string, ingredientId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/item-ingredients/${ingredientId}`
    return super.remove(this.url, {})
  }


  getItemIngredients(menuId: string, categoryId: string, itemId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/item-ingredients?filter[enabled]=all`
    return super.get(this.url)
  }

  itemModifiers(menuId: string, categoryId: string, itemId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups?filter[enabled]=all`
    return this.get(this.url)
  }

  addItemModifiers(payload: Object = {}, menuId: string, categoryId: string, itemId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups?filter[enabled]=all`
    return this.post(this.url, payload)
  }

  deleteItemModifier(menuId: string, categoryId: string, itemId: string, modifierGroupdId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups/${modifierGroupdId}`
    return this.remove(this.url, {})
  }

  enableItemModifier(payload: Object = {}, menuId: string, categoryId: string, itemId: string, modifierGroupdId: string) {
    this.url = this.baseURL + `menus/${menuId}/categories/${categoryId}/items/${itemId}/modifier-groups/${modifierGroupdId}`
    return this.patch(this.url, payload)
  }

  createIngredients(payload: Object = {}) {
    this.url = this.baseURL + 'ingredients'
    return super.post(this.url, payload)
  }

  createGlobalModifierGroups(payload: Object = {}) {
    let url = this.baseURL + 'modifier-groups'
    return super.post(url, payload)
  }

  updateGlobalModifierGroups(payload: Object = {}, modifierGroupdId: string = '') {
    let url = this.baseURL + `modifier-groups/${modifierGroupdId}`
    return super.patch(url, payload)
  }

  createCustomFields(payload: Object = {}) {
    this.url = this.baseURL + 'custom-fields'
    return super.post(this.url, payload)
  }

  customFields() {
    this.url = this.baseURL + 'custom-fields'
    return super.get(this.url);
  }


  attachField(customFieldId: string, payload: Object = {}) {
    this.url = this.baseURL +`custom-fields/${customFieldId}/data`
    return super.post(this.url, payload)
  }

  detachField(customFieldId: string, payload: Object = {}) {
    this.url = this.baseURL +`custom-fields/${customFieldId}/data`
    return super.remove(this.url, payload)
  }

  modifierGroups() {
    this.url = this.baseURL + `modifier-groups?filter[enabled]=all&sort-by=created_at&sort=desc`
    return super.get(this.url)
  }

  modifierGroup(modifierGroupdId: string = '', lang: string = '') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `modifier-groups/${modifierGroupdId}`
    return super.get(this.url)
  }

  modifiers(modifierGroupdId: string = '', lang: string = 'en-US') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + `modifier-groups/${modifierGroupdId}/modifiers`
    return super.get(this.url)
  }

  createModifiers(modifierGroupdId: string = '', payload: Object = {}) {
    this.url = this.baseURL + `modifier-groups/${modifierGroupdId}/modifiers`
    return super.post(this.url, payload)
  }

  updateModifier(modifierGroupdId: string = '', modifierId: string, payload: Object = {}) {
    this.url = this.baseURL + `modifier-groups/${modifierGroupdId}/modifiers/${modifierId}`
    return super.post(this.url, payload)
  }

  getAllergens() {
    this.url = this.baseURL + 'allergens'
    return super.get(this.url)
  }

  createAllergen(payload: Object = {}) {
    this.url = this.baseURL + 'allergens'
    return super.post(this.url, payload)
  }

  createDefaulAllergen(payload: Object = {}) {
    this.url = this.baseURL + 'allergens/defaults'
    return super.post(this.url, payload)
  }

  getAllergen(id: Number, lang: string = '') {
    this.headers['Accept-Language'] = lang
    this.url = this.baseURL + 'allergens/'+ id
    return super.get(this.url)
  }

  updateAllergen(id: Number, payload: Object = {}) {
    this.url = this.baseURL + 'allergens/'+ id
    return super.patch(this.url, payload)
  }

  deleteAllergenRecord(id: string) {
    this.url = this.baseURL + 'allergens/'+ id
    return super.remove(this.url, {})
  }
}
