





















import ComboMealsModal from './ComboMealsModal'
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import ComboMealItems from './ComboMealItems.vue'
import { Menu as MenuApi, Settings } from '@/services/SOLO';
import { Item as ItemModel } from '@/models'

@Component({
  components: {
    ComboMealItems,
    ComboMealsModal
  },
  mixins: [eventHandler]
})
export default class ComboMeals extends Vue {
  
  open: Boolean = false
  updateData: Object = {}
  isUpdate: Boolean = false
  comboItems: Array<Object> = []
  @Prop() item!: ItemModel  
  
  mounted() {
    this.getComboItems()
  }

  modalOpenState(open: Boolean = true) {    
    this.open = open
    this.isUpdate = false
    if(!open) {
      this.updateData = {}
    }
  }

  addComponent(component: Object = {}) {
    this.comboItems.unshift(component)
  }

  initUpdate(data: any) {
    this.isUpdate = true
    this.updateData = data
    this.open = true    
  }

  getComboItems() {
    MenuApi.getComboItems(this.$route.params.id, this.$route.params.itemId)
      .then(response => {
        this.comboItems = response.data.data        
      })
  }

}
