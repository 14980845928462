import BaseService from '../base.service'

export default class IntegrationService extends BaseService {

  url: string = ''
  constructor() {
    super();
  }

  store(provider: any, payload: Object = {}) {
    this.url = this.baseURL + `integrations/${provider}`
    return super.post(this.url, payload)
  }

  getAllIntegrations() {
    this.url = this.baseURL + `integrations`
    return super.get(this.url)
  }

  getIntegration(type: string) {
    this.url = this.baseURL + `integrations?filter[type]=${type}`
    return super.get(this.url)
  }

  getAllProviders() {
    this.url = this.baseURL + `integrations/providers`
    return super.get(this.url)
  }

  logisticsPartners(type: string) {
    this.url = this.baseURL + `integrations/types/${type}`

    return super.get(this.url)
  }

  assignLogistics(orderId: string, provider: string) {
    this.url = this.baseURL + `orders/${orderId}/deliveries/${provider}`

    return super.post(this.url, {})
  }

  sync(integrationType: string, menu: string) {
    this.url = this.baseURL + `integrations/${integrationType}/sync?menu=${menu}`

    return super.get(this.url, {})
  }

  syncLocations(integrationType: string) {
    this.url = this.baseURL + `integrations/${integrationType}/sync-locations`
    return super.get(this.url, {})
  }
}
