import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DashboardPlugin from '@/plugins/dashboard-plugin'
import vSelect from 'vue-select'
import VueTheMask from 'vue-the-mask'
import VoerroTagsInput from '@voerro/vue-tagsinput';

Vue.component('v-select', vSelect)
Vue.component('tags-input', VoerroTagsInput);
Vue.use(DashboardPlugin)
Vue.use(VueTheMask)
Vue.config.productionTip = false

new Vue({
  data() {
    return {
      isLoading: false,
      conceptIsVisible: false
    }
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
