



















































































































































































































































































import VSwatches from 'vue-swatches'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Applications} from '@/services/SOLO'
import {translations} from '@/mixins'

interface AppTheme {
  primary_color?: any
  primary_text_color?: any
  secondary_color?: any
  secondary_text_color?: any
  button1_color?: any
  button1_text_color?: any
  button2_color?: any
  button2_text_color?: any
}

@Component({
  components: {
    VSwatches
  },
  mixins: [translations]
})
export default class Theme extends Vue {
  @Prop() theme!: any
  saving: boolean = false
  $notify: any
  form: AppTheme = {}
  translate!: Function
  type: any = ''
  s_prim_color = ''
  s_sec_color = ''


  @Watch('theme') onThemeChanged(val: any) {
    this.type = val.attributes['application-type']
    this.form = val.attributes.theme ? val.attributes.theme : {}

    if (Object.keys(this.form).length === 0) {
      this.s_prim_color = this.form.primary_color
      this.s_sec_color = this.form.secondary_color
    }
    if (this.type === 'social-ordering') {
      this.s_prim_color = this.form.primary_color
      this.s_sec_color = this.form.secondary_color
    } else {
      this.s_prim_color = this.form.primary_color
      this.s_sec_color = this.form.secondary_color
    }
  }

  updateTheme() {
    this.saving = true
    this.form.primary_color = this.s_prim_color
    this.form.secondary_color = this.s_sec_color
    Applications.updateApplicationTheme(Number(this.$route.params.id), this.form)
        .then((response: any) => {
          this.$notify({
            title: "THEMES UPDATED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Record has been updated successfully",
            type: "success",
            icon: "fas fa-check"
          })
          this.saving = false
          // console.log(response.data.data.attributes.theme)
        }).catch((err: any) => {
      let errMsg: any = []
      for (let error in err.response.data.errors) {
        errMsg = err.response.data.errors[error]
      }
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: errMsg[0],
        type: "danger",
        icon: "fas fa-bomb",
      });
      this.saving = false
    })
  }

}

