






















































import { Pagination } from "element-ui";
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './Translations.vue'

@Component({
  components: {
    [Pagination.name]: Pagination,
  },
mixins: [translations],
})
export default class EmployeeCollapse extends Vue {
  @Prop() employeeMeta!: Array<any>;
  search: string = '';
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  isLoaded: boolean = false;
  public translate!: Function;

  @Watch('employeeMeta', { deep: true })
  onEmployeeMetaChanged(newVal: any) {
    this.isLoaded = true;
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }
  findEmployees() {
    this.$emit('findEmployees', this.search);
  }
  pageChange(data: any) {
    this.$emit('getEmployees', data);
  }
}
