import BaseService from '../base.service'

export default class FeedbackService extends BaseService {
  url!: string
  constructor() {
    super();
  }

  ingredients() {
    this.url = this.baseURL + 'ingredients?filter[enabled]=all'
    return super.get(this.url)
  }

  // feedbacks(page: number) {
  //   this.url = this.baseURL + 'feedback?filter[enabled]=all&page=' + page;
  //   return super.get(this.url);
  // }

  search(name: string, email: string, telephone: string) {
    this.url = this.baseURL + `feedback?filter[name]=${name}&filter[email]=${email}&filter[telephone]=${telephone}`;  
    return super.get(this.url);
  }


  all(page = 1) {
    let url = this.baseURL + 'feedback';

    return super.get(url, {
      page: page
    });
  }

}
