





















































import { CustomerAddress as Address } from "@/models"
import { Table, TableColumn, Button } from "element-ui"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import {Component, Prop, Watch, Vue} from "vue-property-decorator"
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
mixins: [translations],
})
export default class CustomerAddress extends Vue {
  @Prop() address?: Array<Address>
  public translate!: Function
  someAddress: any = []
  addressCount: number = 0

  @Watch('address', {deep: true})
  onCustomerAddressChanged(newVal: any) {
    this.address = newVal
    this.someAddress = this.address
    this.addressCount = this.someAddress.length
  }
}
