


































































































import moment from "moment";
import Translations from './components/translations'
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart'
import * as chartConfigs from '@/components/Charts/config';
import DashboardFigures from "./components/DashboardFigures.vue";
import { Dashboard as DashboardApi } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import {translations} from '@/mixins'
@Component({
  components: {
    BarChart,
    LineChart,
    DashboardFigures,
    Translations,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu
  },
  mixins: [translations],
  filters: {
    datetime(value: any) {
      return moment.utc(value).locale('en-us').local().fromNow();
    }
  }
})
export default class Dashboard extends Vue {
  public summary: Array<any> = [];
  public orders: Array<any> = [];
  private loadChart: Boolean = false
  private bigLineChart: any = {
    allData: [
      [0, 20, 10, 30, 15, 40, 20, 60, 60],
      [0, 20, 5, 25, 10, 30, 15, 40, 40]
    ],
    activeIndex: 0,
    chartData: {
      datasets: [],
      labels: []
    },
    extraOptions: {
      scales: {
        yAxes: [
          {
            id: "A",
            type: "linear",
            position: "left"
          },
          {
            id: "B",
            type: "linear",
            position: "right",
          }
        ],
        xAxes: [{
          ticks: {
            beginAtZero: true,
            autoSkip: true,
          }
        }]
      },
      legend: {
        display: true,
        labels: {
          fontColor: 'rgb(255, 99, 132)'
        }
      }
    },
  }

  private translate!: Function
  mounted() {
    this.getSales();
    this.getLatestOrders();
    this.getDailySales()
    console.log('test')
  }

  private iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`
  }

  private get todaysRevenue(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return !sale.ival;
      })
      .map((sale: any) => {
        return sale.total_sales;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get todaysRevenuePCT(): any {
    let pct = (this.todaysRevenue / this.yesterdaysRevenue - 1) * 100;
    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0
    }
    return pct
  }

  private get todaysOrder(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return !sale.ival;
      })
      .map((sale: any) => {
        return sale.number;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get todaysOrderPCT(): any {
    let pct = (this.todaysOrder / this.yesterdaysOrder - 1) * 100;

    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0;
    }

    return pct;
  }

  private get todaysAverage(): any {
    let ave: any = this.todaysRevenue / this.todaysOrder;
    if (!ave) {
      return 0;
    }
    return ave;
  }

  private get todaysAveragePCT(): any {
    let pct = (this.todaysAverage / this.yesterdaysAverage - 1) * 100;
    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0;
    }
    return pct;
  }

  private get yesterdaysRevenue(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival === 1;
      })
      .map((sale: any) => {
        return sale.total_sales;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get yesterdaysRevenuePCT(): any {
    let pct = (this.yesterdaysRevenue / this.otherDayRevenue - 1) * 100;
    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0
    }
    return pct
  }

  private get yesterdaysOrder(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival === 1;
      })
      .map((sale: any) => {
        return sale.number;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get yesterdaysOrderPCT(): any {
    let pct = (this.yesterdaysOrder / this.otherDaysOrder - 1) * 100;

    if(!pct || isNaN(pct) || pct == Infinity) {
      return 0;
    }

    return pct;
  }

  private get yesterdaysAverage(): any {
    let ave: any = this.yesterdaysRevenue / this.yesterdaysOrder;
    if (!ave) {
      return 0;
    }
    return ave;
  }

  private get yesterdaysAveragePCT(): any {
    let pct = (this.yesterdaysAverage / this.otherDayAverage - 1) * 100;
    if(!pct || pct == NaN || pct == Infinity) {
      return 0;
    }
    return pct;
  }

  private get otherDayRevenue(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival > 1;
      })
      .map((sale: any) => {
        return sale.total_sales;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get otherDaysOrder(): any {
    let revenue = this.summary
      .filter((sale: any) => {
        return sale.ival > 1;
      })
      .map((sale: any) => {
        return sale.number;
      });

    return revenue.reduce((a, b) => a + b, 0);
  }

  private get otherDayAverage(): any {
    let ave: any = this.otherDayRevenue / this.otherDaysOrder;
    if (!ave) {
      return 0;
    }
    return ave;
  }

  private getSales() {
    DashboardApi.dailySummary().then((response: any) => {
      this.summary = response.data;
    });
  }

  private getDailySales() {
    DashboardApi.dailySales().then((response: any) => {
      let dailySales: Array<any> = response.data
      this.bigLineChart.chartData.datasets = [
        {
          label: this.translate('Daily Sales Value'),
          yAxisID: 'A',
          type: 'bar',
          data: dailySales.map((order: any) => { return order.total_sales }),
        },
        {
          label: this.translate('Number of Sales'),
          yAxisID: 'B',
          type: 'line',
          data: dailySales.map((order: any) => { return order.number }),
        }
      ]
      this.bigLineChart.chartData.labels = dailySales.map((order: any) => { return order.date })
      this.loadChart = true
    })
  }

  private getLatestOrders() {
    DashboardApi.latestOrders().then((response: any) => {
      this.orders = response.data.data
    })
  }

  private calculateFigures() {}
}
