import BaseService from '../base.service'
import store from '@/store'
let customerService = class CustomerService extends BaseService {

  constructor() {
    super();
  }

  store(params: {}) {
    let url = this.baseURL + 'customers';
    console.log(url)
    return super.post(url, params)
  }

  all(page = 1) {
    let url = this.baseURL + 'customers';

    return super.get(url, {
      page: page
    });
  }
  
  search(name: string, email: string, mobile: string) {
    let url = this.baseURL + `customers?filter[customer]=${name}&filter[email]=${email}&filter[mobile]=${mobile}`;  
    return super.get(url);
  }

  filterByEmail(query: string) {
    let url = this.baseURL + `customers?filter[email]=${query}`;  
    return super.get(url);
  }

  forgotPassword(params: {}) {
    let url = this.baseURL + 'forgotPassword'
    return super.post(url, params);

  }

  find(customerId: string) {
    let url = this.baseURL + 'customers/' + customerId;

    return super.get(url)
  }

  update(customerId: string, params: any) {

    let url = this.baseURL + 'customers/' + customerId;

    return super.patch(url, params);
  }

  getDefault() {
    let url = this.baseURL + 'concepts/' + store.getters['concept/getConcept'].attributes.key;

    return super.get(url)
  }

  getOrders(customerId: string, sort = 'desc', limit = 999) {
    let url = this.baseURL + 'customers/' + customerId + '/orders?sort=' + sort + '&size=' + limit;

    return super.get(url)
  }

  getAddresses(customerId: string) {
    let url = this.baseURL + 'customers/' + customerId + '/addresses';

    return super.get(url)
  }

  createAddress(customerId: string, params: {}) {
    let url = this.baseURL + 'customers/' + customerId + '/addresses';

    return super.post(url, params);
  }

  deleteAddress(customerId: string, addressId: string) {
    let url = this.baseURL + 'customers/' + customerId + '/addresses/' + addressId;

    return super.remove(url, {});
  }

  verifyCode(customerId: string, params: {}) {
    let url = this.baseURL + 'customers/' + customerId + '/verify';

    return super.post(url, params);
  }

  authenticateOTP(params: {}) {
    const url = this.baseURL + 'login/guest';

    return super.post(url, params);
  }

  resendCode(params: {}) {
    let url = this.baseURL + 'resendsms';

    return super.post(url, params);
  }

  tapVerification(params: object = {})
  {
      let url = this.baseURL + 'payments/providers/tap/verify'

      return super.post(url, params);
  }

  getBlacklists(page: number) {
    let url = this.baseURL + 'blacklists';
    return super.get(url, {
      page: page
    });
  }

  showBlacklist(id: any) {
    let url = this.baseURL + 'blacklists/' + id;
    return super.get(url);
  }

};

export default customerService
