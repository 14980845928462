



































  
import moment from "moment"
import { mapGetters, mapMutations } from 'vuex'
import {Select, Option} from 'element-ui'    
import { translations } from '@/mixins'
import PaymentDescription from './components/PaymentDescription.vue'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { Subscription as SubscriptionApi } from '@/services/SOLO'  
import { mixins } from 'vue-class-component';

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    PaymentDescription
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY");
    }      
  },    
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getLocale: 'app/getLocale',
      getSubscription: 'account/getSubscription',
      getAppSubscription: 'app/getAppSubscription',
      getAppQueryParams: 'app/getAppQueryParams'
    })
  },
  methods: {
    ...mapMutations({
      setSubscription: 'account/setSubscription',
      logoutUser: 'account/logoutUser'     
    })
  },
  mixins: [translations],
})
export default class Subscription extends Vue {

  private getUser: any
  private getLocale: any
  private getSubscription: any
  private plan: number = 0
  private subscription: any = null
  private translateNumber!: Function
  private logoutUser!: Function
  private translate!: Function
  private getAppSubscription: any
  private getAppQueryParams: any

  private plans: Array<any> = ['Free Plan','Standard Plan', 'Premium Plan']

  get account() {
    return this.getUser.attributes
  }

  mounted() {
    this.plan = this.getAppSubscription
    this.checkSubscription()      
  }
  
  private get isCurrentPlan() {
    if(this.getSubscription) {
      return this.getAppSubscription === this.getSubscription.attributes['plan-id']
    }
    return false
  }

  private logout() {
    this.logoutUser()
    this.$router.push({name: 'login'})
  }

  private checkSubscription() {
    if(this.getSubscription) {
      this.plan = this.getSubscription.attributes['plan-id']
    } else {        
      this.getSubscriptionFn()
    }
  }

  private setSubscription!: Function

  private getPlan() {
    SubscriptionApi.getPlan(this.plan).then(response => {
      console.log(response.data.data)
    })
  }

  private formatSubscription(num: number) {
    let d = 0
    if(!num) {
      if(!this.getSubscription) {                            
        return this.translateNumber(0);
      } else {
        return this.getSubscription.attributes['included-branches']
      }
    } else if(num === 1) {
      if(!this.getSubscription) {          
        return this.translate('SAR') + ` ${this.translateNumber(d.toFixed(2))}`
      } else {
        return this.getSubscription.attributes['latest-invoice-amount-paid']
      }
    } else if(num === 2) {
      if(!this.getSubscription) {          
        return this.translate('SAR') + ` ${this.translateNumber(d.toFixed(2))}`
      } else {
        return this.getSubscription.attributes['next-invoice-amount']
      }
    } else {
      if(!this.getSubscription) {          
        return this.translate('N/A')
      } else {          
        return moment
          .utc(this.getSubscription.attributes['next-invoice-at'], "YYYY-MM-D hh:mm:ss")
          .locale("en-us")
          .local()
          .format("D/MM/YYYY")
      }
    }
  }

  private getSubscriptionFn() {
    SubscriptionApi.getSubscription().then(response => {      
      console.log('test')  
      this.setSubscription(response.data.data)
    }).catch(err => {
      console.log(err.response)
    })
  }
}
