












































import { mapGetters } from 'vuex';
import { Component, Vue } from 'vue-property-decorator';
import { Button, Table, TableColumn } from 'element-ui';
import TabHeader from '@/views/Admin/pages/Messaging/components/TabHeader';
import { translations } from '@/mixins'
import Translations from './Translations.vue'
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    TabHeader,
  },
  computed: {
    ...mapGetters({
      pending: 'messaging/getPending',
    })
  },
mixins: [translations],
})
export default class TabPending extends Vue {
public translate!: Function
}
